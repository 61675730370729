import { useEffect, useState } from "react";

import { Button, DialogWrapper, FormInput, LabelWrapper, Row, ToggleButtons } from "@bridgesplit/ui";
import { useAppDialog } from "app/utils";
import { TransactionFeeLevel, TransactionGenerationType, TransactionSettingsHeader } from "@bridgesplit/utils";
import { getTransactionSettings, updateTransactionSettings } from "@bridgesplit/react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { HeaderWithClose } from "../common";

export default function TransactionSettingsDialog() {
    const { close } = useAppDialog();

    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [txnSettings, setTxnSettings] = useState<TransactionSettingsHeader>({
        maxFee: undefined,
        exactFee: undefined,
        feeLevel: undefined,
        broadcastMode: undefined
    });

    useEffect(() => {
        const settings = getTransactionSettings();
        setTxnSettings(settings);
    }, []);

    useEffect(() => {
        const originalSettings = getTransactionSettings();
        const isUnchanged = Object.keys(txnSettings).reduce((prev, curr) => {
            const key = curr as keyof TransactionSettingsHeader;
            return txnSettings[key] === originalSettings[key] && prev;
        }, true);
        setUnsavedChanges(!isUnchanged);
    }, [txnSettings]);

    const handleUpdate = (key: string, value: string | number | undefined) => {
        setTxnSettings((prevSettings) => ({
            ...prevSettings,
            [key]: value
        }));
    };

    const saveSettings = () => {
        if (txnSettings === undefined) {
            return;
        }
        updateTransactionSettings(txnSettings);
    };

    return (
        <DialogWrapper>
            <HeaderWithClose header={"Transaction Settings"} close={close} />
            <Row>
                <LabelWrapper label="Max Fee" tooltip={"Optional"}>
                    <FormInput
                        variant="number"
                        adornment="SOL"
                        decimals={6}
                        validateNumber={(val) => (val ? val > 0 : true)}
                        fullWidth
                        sx={{ "& fieldset": { border: "none" } }}
                        focused
                        setValue={(val) => handleUpdate("maxFee", val && val * LAMPORTS_PER_SOL)}
                        placeholder="0.001"
                        value={txnSettings.maxFee && txnSettings.maxFee / LAMPORTS_PER_SOL}
                    />
                </LabelWrapper>
                <LabelWrapper label="Exact Fee" tooltip={"Optional, overrides max fee and fee level."}>
                    <FormInput
                        variant="number"
                        adornment="SOL"
                        decimals={6}
                        validateNumber={(val) => (val ? val > 0 : true)}
                        fullWidth
                        sx={{ "& fieldset": { border: "none" } }}
                        focused
                        setValue={(val) => handleUpdate("exactFee", val && val * LAMPORTS_PER_SOL)}
                        placeholder="0.001"
                        value={txnSettings.exactFee && txnSettings.exactFee / LAMPORTS_PER_SOL}
                    />
                </LabelWrapper>
            </Row>
            <LabelWrapper
                label="Fee Level"
                tooltip={`Set your fee level, the higher you set it, the more likely it is to land quickly. However, when the chain is congested you may end up paying a higher fee.`}
            >
                <ToggleButtons
                    value={txnSettings?.feeLevel}
                    setValue={(val: TransactionFeeLevel) => {
                        handleUpdate("feeLevel", val);
                    }}
                    options={Object.keys(TransactionFeeLevel).map((key) => {
                        return {
                            label: key,
                            value: key as TransactionFeeLevel
                        };
                    })}
                />
            </LabelWrapper>
            <LabelWrapper
                label="Broadcast Type"
                tooltip={`Send with priority fees, Jito, or both (defaults to Priority Fee)`}
            >
                <ToggleButtons
                    value={txnSettings?.broadcastMode}
                    setValue={(val: TransactionGenerationType) => {
                        handleUpdate("broadcastMode", val);
                    }}
                    options={Object.keys(TransactionGenerationType)
                        .filter((key) => isNaN(Number(key)))
                        .map((key) => {
                            return {
                                label: key,
                                value: TransactionGenerationType[key as keyof typeof TransactionGenerationType]
                            };
                        })}
                />
            </LabelWrapper>
            <Button
                disabled={!unsavedChanges}
                variant="contained"
                onClick={() => {
                    saveSettings();
                    close();
                }}
            >
                Save settings
            </Button>
        </DialogWrapper>
    );
}
