import { Fragment, ReactNode } from "react";

import {
    Column,
    JLP_LOGO,
    MARGIN_FI_LOGO,
    RewardImage,
    Row,
    SOLANA_HUB_LOGO,
    StatProps,
    StatWithIcon,
    Text,
    METEORA_SVG,
    Icon,
    useAppPalette,
    RewardIcon,
    SpanFlex,
    SOLAYER_LOGO,
    ADRASTEA_LOGO
} from "@bridgesplit/ui";
import {
    PointsInfo,
    LoopscalePointSource,
    ExternalPointSource,
    POINTS_SOURCE_INFO,
    POINTS_BASIS,
    useCollateralPointsSourcesUtil
} from "@bridgesplit/abf-react";
import { BoltOutlined } from "@mui/icons-material";
import { formatFixedDigits } from "@bridgesplit/utils";
import { BsMetadata } from "@bridgesplit/abf-sdk";

import { calculatePointsData } from "../util";

export interface CollateralInfo {
    pointSource: ExternalPointSource[];
    collateralPointBonus: Map<LoopscalePointSource, number>;
    name: string;
}

interface TooltipContentProps {
    children: React.ReactNode;
    width?: string;
}
export function TooltipContent({ children, width = "250px" }: TooltipContentProps): JSX.Element {
    return (
        <Column spacing={2} padding={1} sx={{ width }}>
            {children}
        </Column>
    );
}

interface CreateTotalMultiplierStatProps {
    sumStat: PointsInfo[];
    baseRate: number;
    showMultiplier?: boolean;
    totalAmountUsd: number;
    hasMultiplier: boolean;
    loopscalePointSources?: LoopscalePointSource[];
    metadata?: BsMetadata[];
    isSpecific: boolean; //Used for loan cards and portfolio cards specific principal/collateral amount
}

export function useCreateTotalMultiplierStat({
    sumStat,
    showMultiplier = true,
    totalAmountUsd: totalAmount,
    hasMultiplier,
    metadata,
    loopscalePointSources,
    isSpecific
}: CreateTotalMultiplierStatProps): StatProps[] {
    const { collateralInfo, pointsPerSecond, relativePointsPerSecond } = useCollateralPointsSourcesUtil();

    return sumStat.map((multiplierStat) => {
        const { totalPoints, multiplier } = calculatePointsData({
            loopscalePointSource: loopscalePointSources,
            metadata,
            collateralInfo,
            pointsPerSecond,
            relativePointsPerSecond,
            totalAmount
        });

        const value = showMultiplier ? `${multiplier}x` : formatFixedDigits(totalPoints);

        return {
            value: (
                <StatWithIcon
                    key={`${multiplier}-${multiplierStat.externalPointSource}`}
                    value={value}
                    captionColor="body"
                    icon={<RewardIcon hasMultiplier={hasMultiplier} />}
                />
            ),
            caption: isSpecific
                ? "Daily points"
                : showMultiplier
                ? `Total point multiplier`
                : `Daily points per $${formatFixedDigits(totalAmount / POINTS_BASIS)}`
        };
    });
}
export function useCreateMultiplierLoops(multipliers: PointsInfo[]): StatProps[] {
    return multipliers.map((multiplier) => {
        return {
            value: <StatWithIcon value={`${multiplier.multiplier}x`} />,
            caption: multiplier.caption
        };
    });
}

export function useCreateCollateralStats(multipliers: PointsInfo[] | undefined, isBoosted: boolean) {
    const { prime, secondary } = useAppPalette();
    if (!multipliers) return undefined;
    return multipliers.map((multiplier) => {
        return {
            value: (
                <StatWithIcon
                    value={`${multiplier.multiplier}x`}
                    icon={
                        isBoosted ? (
                            <Text sx={{ color: isBoosted ? prime : secondary }}>
                                <BoltOutlined />
                            </Text>
                        ) : undefined
                    }
                />
            ),
            caption: multiplier.caption
        };
    });
}

interface AdditionalStatProps {
    multiplier: PointsInfo;
}

export function RenderAdditionalStat({ multiplier }: AdditionalStatProps) {
    const value = (multiplier.multiplier ?? 0) > 1 ? `${multiplier.multiplier}x` : undefined;

    return (
        <Column spacing={1} sx={{ width: "100%" }}>
            <Row spacing={1}>
                {getIconForSource(multiplier.externalPointSource)}
                <Text>{multiplier.caption}</Text>
            </Row>
            <Row spacing={1} sx={{ width: "100%", justifyContent: "flex-start" }}>
                <Text color="caption">
                    {value && ![ExternalPointSource.MarginFi].includes(multiplier.externalPointSource)
                        ? `Deposits earn up to ${value} ${multiplier.caption}`
                        : `Deposits earn ${multiplier.caption}`}
                </Text>
            </Row>
        </Column>
    );
}

export function getIconForSource(source: ExternalPointSource): ReactNode {
    switch (source) {
        case ExternalPointSource.MarginFi:
            return <RewardImage src={MARGIN_FI_LOGO} />;
        case ExternalPointSource.Loopscale:
            return <Icon type="points" />;
        case ExternalPointSource.Jupiter:
            return <RewardImage src={JLP_LOGO} />;
        case ExternalPointSource.Meteora:
            return <RewardImage src={METEORA_SVG} />;
        case ExternalPointSource.HubSol:
            return <RewardImage src={SOLANA_HUB_LOGO} />;
        case ExternalPointSource.Solayer:
            return <RewardImage src={SOLAYER_LOGO} />;
        case ExternalPointSource.Adrastea:
            return <RewardImage src={ADRASTEA_LOGO} />;
    }
}

export function generateLeverageStat(leverage: number | undefined): PointsInfo | undefined {
    if (!leverage) return undefined;
    return {
        externalPointSource: ExternalPointSource.LoopscaleHidden,
        caption: "Max leverage",
        multiplier: leverage
    };
}

interface PointSourceMapProps {
    pointSources: ExternalPointSource[];
    background?: string;
    isLoading?: boolean;
}

export function PointSourceMap({ pointSources, background, isLoading }: PointSourceMapProps): JSX.Element {
    return (
        <Row spacing={1}>
            {pointSources.map((point) => (
                <Fragment key={point}>
                    <PointSourceIcon source={point} background={background} isLoading={isLoading} />
                </Fragment>
            ))}
        </Row>
    );
}
interface PointSourceIconProps {
    source: ExternalPointSource;
    background?: string;
    isLoading?: boolean;
}

function PointSourceIcon({ source, background }: PointSourceIconProps): JSX.Element {
    if (source === ExternalPointSource.Loopscale) {
        return (
            <Text sx={{ color: background }} variant="body1">
                {getIconForSource(POINTS_SOURCE_INFO[source].externalPointSource)}
            </Text>
        );
    }

    return <SpanFlex>{getIconForSource(POINTS_SOURCE_INFO[source].externalPointSource)}</SpanFlex>;
}
