import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { WhirlpoolOffchain } from "../types";

const WHIRLPOOL_TAG = "WhirlpoolList";
export const orcaExternalApi = createApi({
    reducerPath: "orcaExternalApi",
    tagTypes: [WHIRLPOOL_TAG],
    baseQuery: fetchBaseQuery({ baseUrl: "https://stats-api.mainnet.orca.so/" }),
    endpoints: (builder) => ({
        whirlpoolsOffchain: builder.query<{ data: WhirlpoolOffchain[] }, { token: string }>({
            providesTags: [WHIRLPOOL_TAG],
            query: ({ token }) => ({
                url: `/api/whirlpools?limit=50&sort=volume:desc&token=${token}`,
                method: "GET"
            })
        })
    })
});

export const { useWhirlpoolsOffchainQuery } = orcaExternalApi;

export function useOrcaExternalApi() {
    const dispatch = useDispatch();
    return { resetOrcaExternalApi: () => dispatch(orcaExternalApi.util.invalidateTags([WHIRLPOOL_TAG])) };
}
