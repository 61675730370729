import {
    fetchAndDeserializeLegacyTransaction,
    fetchAndDeserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransactions,
    NAPOLEON_API
} from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    CLOSE_LOCKBOX_ENDPOINT,
    DEPOSIT_LOCKBOX_ENDPOINT,
    DEPOSIT_LOCKBOX_STAKED_SOL_ENDPOINT,
    INIT_LOCKBOX_ENDPOINT,
    LockboxAssetUpdateArgs,
    LockboxCloseArgs,
    LockboxInitArgs,
    LockboxUnlockArgs,
    StakedSolLockboxArgs,
    TOPUP_LOCKBOX_ENDPOINT,
    TOPUP_STAKED_LOCKBOX_ENDPOINT,
    UNLOCK_LOCKBOX_ENDPOINT,
    WITHDRAW_LOCKBOX_ENDPOINT,
    WITHDRAW_LOCKBOX_STAKED_SOL_ENDPOINT
} from "../types";
import { createAbfInstance } from "./auth";

export async function getInitLockboxTransaction(authentication: AbfTransactionHeaders, args: LockboxInitArgs) {
    return [await fetchAndDeserializeLegacyTransaction(createAbfInstance(authentication), INIT_LOCKBOX_ENDPOINT, args)];
}

export async function getDepositLockboxTransaction(
    authentication: AbfTransactionHeaders,
    args: LockboxAssetUpdateArgs[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), DEPOSIT_LOCKBOX_ENDPOINT, arg)
        )
    );
}

export async function getTopUpLockboxTransaction(
    authentication: AbfTransactionHeaders,
    args: LockboxAssetUpdateArgs[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeVersionedTransaction(
                createAbfInstance(authentication, NAPOLEON_API),
                TOPUP_LOCKBOX_ENDPOINT,
                arg
            )
        )
    );
}

export async function getTopUpLockboxStakedSolTransaction(
    authentication: AbfTransactionHeaders,
    args: StakedSolLockboxArgs
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication, NAPOLEON_API),
        TOPUP_STAKED_LOCKBOX_ENDPOINT,
        args
    );
}

export async function getUnlockLockboxTransaction(
    authentication: AbfTransactionHeaders,
    args: LockboxUnlockArgs
) {
    return [
        await fetchAndDeserializeLegacyTransaction(createAbfInstance(authentication), UNLOCK_LOCKBOX_ENDPOINT, args)
    ];
}

export async function getWithdrawLockboxTransaction(
    authentication: AbfTransactionHeaders,
    args: LockboxAssetUpdateArgs[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), WITHDRAW_LOCKBOX_ENDPOINT, arg)
        )
    );
}

export async function getDepositLockboxStakedSolTransaction(
    authentication: AbfTransactionHeaders,
    args: StakedSolLockboxArgs
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication),
        DEPOSIT_LOCKBOX_STAKED_SOL_ENDPOINT,
        args
    );
}

export async function getWithdrawLockboxStakedSolTransaction(
    authentication: AbfTransactionHeaders,
    args: StakedSolLockboxArgs
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication),
        WITHDRAW_LOCKBOX_STAKED_SOL_ENDPOINT,
        args
    );
}

export async function getCloseLockboxesTransaction(
    authentication: AbfTransactionHeaders,
    { lockboxes }: { lockboxes: LockboxCloseArgs[] }
) {
    return await Promise.all(
        lockboxes.map((arg) =>
            fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), CLOSE_LOCKBOX_ENDPOINT, arg)
        )
    );
}
