import { useMemo, useState } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    formatTokens,
    getLoanCompletedDate,
    getLoanDefaultedDate,
    getLoanName,
    getLoanOrcaCollateral,
    getLoanSoldTime,
    getNextPaymentDate,
    getTotalLenderSaleProceeds,
    isLoanActive,
    isLoanOracleBased,
    isLoanZeroCoupon,
    LoanStatus,
    LoopscalePointSource,
    ExternalPointSource,
    summarizeLedgerAccounts,
    useActiveGroup,
    useEscrowAccounts,
    useLoanBidAccount,
    getClaimableWhirlpoolFees
} from "@bridgesplit/abf-react";
import {
    Row,
    ShadowCard,
    UnreadDot,
    Column,
    MediaQuery,
    Tooltip,
    Text,
    StatProps,
    OverlappingImages,
    ToggleChevron
} from "@bridgesplit/ui";
import { bsMath, filterTruthy, formatDate, formatPercent, formatUsd, removeDuplicates } from "@bridgesplit/utils";
import { Collapse, Divider, useMediaQuery } from "@mui/material";
import { BsMetadata } from "@bridgesplit/abf-sdk";

import {
    ClaimWhirlpoolFeesButton,
    LoanHealthTag,
    LoanHealthText,
    LoanImages,
    parseOrcaTicks,
    PositionCardStats,
    PriceRangeStatus,
    TokenImage,
    useLoanAlert
} from "../common";
import { LoanActions } from "../loan";
import { RateAndRewardsBreakdown } from "../points";
type Props = {
    loan: AbfLoanExpanded | undefined;
    query: MediaQuery;
};
export default function LoanCard(props: Props) {
    const isMobile = useMediaQuery(props.query.below);

    if (isMobile) {
        return <Mobile {...props} />;
    }
    return <Desktop {...props} />;
}

function Desktop(props: Props) {
    const [expanded, setExpanded] = useState(false);
    const { loan, query } = props;

    const toggleOnClick = loan ? () => setExpanded((prev) => !prev) : undefined;

    return (
        <ShadowCard sx={{ flexGrow: 1, overflow: "visible" }} padding={false}>
            <Row sx={{ p: 2, cursor: "pointer" }} onClick={toggleOnClick} spaceBetween>
                <LoanHeader {...props} />
                <Row spacing={2}>
                    <LoanStatusDisplay loan={loan} />
                    <ToggleChevron disabled={!loan} textVariant="h2" expanded={expanded} />
                </Row>
            </Row>

            <Collapse in={expanded}>
                <Column sx={{ p: 2, pt: 0 }} spacing={2}>
                    <Expanded isMobile={false} query={query} loan={loan} />
                </Column>
            </Collapse>
        </ShadowCard>
    );
}

function Mobile(props: Props) {
    const { loan, query } = props;
    const loanAlert = useLoanAlert(loan);

    return (
        <ShadowCard sx={{ flexGrow: 1, overflow: "visible" }} padding spacing={2}>
            <Tooltip placement="right" title={loanAlert}>
                <Row spacing={1}>
                    <LoanTitle loan={loan} />
                    {loanAlert && <UnreadDot />}
                </Row>
            </Tooltip>

            <Expanded isMobile query={query} loan={loan} />
        </ShadowCard>
    );
}

function LoanHeader(props: Props) {
    const { loan } = props;
    const loanAlert = useLoanAlert(loan);
    const isActive = isLoanActive(loan);
    const { groupIdentifier } = useActiveGroup();

    const isBorrower = groupIdentifier === loan?.borrower.groupIdentifier;

    const loopscalePointSources = isBorrower ? [LoopscalePointSource.Borrow] : [LoopscalePointSource.Lend];
    const pointSources = isBorrower
        ? [ExternalPointSource.Loopscale]
        : [ExternalPointSource.Loopscale, ExternalPointSource.MarginFi];

    const usdAmount = (loan?.principalAmount ?? 0) * (loan?.principalUsdPrice ?? 0);
    const metadata: BsMetadata[] = [loan?.principalMetadata, ...(loan?.collateral ?? []).map((m) => m.metadata)].filter(
        filterTruthy
    );

    return (
        <Tooltip placement="right" title={loanAlert}>
            <Row spacing={1}>
                {isActive ? (
                    <>
                        <LoanPrincipal principal={loan?.debt.total} loan={loan} />
                        <Divider flexItem orientation="vertical" />
                        <LoanCollateral loan={loan} />

                        <Divider flexItem orientation="vertical" />
                        <Text loading={!loan} color="caption">
                            {formatPercent(loan?.apy)} APY
                        </Text>
                        {loan && (
                            <RateAndRewardsBreakdown
                                externalPointSources={pointSources}
                                loopscalePointSources={loopscalePointSources}
                                totalAmount={usdAmount}
                                metadata={metadata}
                                apy={undefined}
                                isSpecific
                            />
                        )}
                    </>
                ) : (
                    <LoanTitle loan={loan} />
                )}

                {loanAlert && <UnreadDot />}
            </Row>
        </Tooltip>
    );
}

function LoanStatusDisplay({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const isActive = isLoanActive(loan);
    const { formatted } = useDueDetails(loan);

    if (!loan) return null;

    return (
        <Row>
            {isActive && <LoanHealthTag variant="body2" loan={loan} />}
            {formatted && <Text color="caption">{formatted}</Text>}
        </Row>
    );
}

function LoanTitle({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const { groupIdentifier } = useActiveGroup();
    const isActive = isLoanActive(loan);

    const isBorrower = groupIdentifier === loan?.borrower.groupIdentifier;

    const loopscalePointSources = isBorrower ? [LoopscalePointSource.Borrow] : [LoopscalePointSource.Lend];
    const pointSources = isBorrower
        ? [ExternalPointSource.Loopscale]
        : [ExternalPointSource.Loopscale, ExternalPointSource.MarginFi];

    const usdAmount = (loan?.principalAmount ?? 0) * (loan?.principalUsdPrice ?? 0);
    const metadata = [loan?.principalMetadata, ...(loan?.collateral ?? []).map((m) => m.metadata)].filter(filterTruthy);
    return (
        <Row spacing={1}>
            <LoanImages size="md" loanExpanded={loan} />
            <Text loadingWidth="200px" loading={!loan}>
                {getLoanName(loan)}
            </Text>
            {isActive && loan && (
                <RateAndRewardsBreakdown
                    externalPointSources={pointSources}
                    loopscalePointSources={loopscalePointSources}
                    totalAmount={usdAmount}
                    metadata={metadata}
                    apy={undefined}
                    isSpecific
                />
            )}
        </Row>
    );
}

function LoanPrincipal({ loan, principal }: { loan: AbfLoanExpanded | undefined; principal: number | undefined }) {
    return (
        <Row spacing={1}>
            <TokenImage size="md" metadata={loan?.principalMetadata} />
            <Row spacing={0.5}>
                <Text loadingWidth="200px" loading={!loan}>
                    {BsMetaUtil.formatAmount(loan?.principalMetadata, principal, { hideSymbol: true })}
                </Text>
                <Text loading={!loan} color="caption">
                    {formatUsd(bsMath.mul(principal, loan?.principalUsdPrice))}
                </Text>
            </Row>
        </Row>
    );
}

function LoanCollateral({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const images = loan?.collateral.map((c) => BsMetaUtil.getImage(c.metadata));
    const usdValue = loan?.collateral.reduce(
        (acc, curr) => (curr.usdPrice ? acc + curr.usdPrice * curr.amount : acc),
        0
    );

    const whirlpoolPosition = getLoanOrcaCollateral(loan)?.whirlpoolPosition;

    return (
        <Row spacing={1}>
            {whirlpoolPosition ? (
                <Tooltip
                    sx={{ gap: 0.5 }}
                    title={`${BsMetaUtil.getSymbolUnique(whirlpoolPosition?.whirlpoolMetadata)}`}
                >
                    <OverlappingImages size="16px" images={images ? removeDuplicates(images) : images} />
                </Tooltip>
            ) : (
                <OverlappingImages size="16px" images={images ? removeDuplicates(images) : images} />
            )}
            <Row spacing={0.5}>
                <Text loadingWidth="200px" loading={!loan}>
                    {formatTokens(loan?.collateral, { hideSymbol: true, condensed: true })}
                </Text>
                {!!usdValue && (
                    <Text loading={!loan} color="caption">
                        {formatUsd(usdValue)}
                    </Text>
                )}
            </Row>
            <OrcaStatus loan={loan} />
        </Row>
    );
}

function useDueDetails(loan: AbfLoanExpanded | undefined): { stat: StatProps; formatted?: string } {
    const { escrowPubkeys } = useEscrowAccounts();
    const pastDetails = useMemo(() => {
        switch (loan?.status) {
            case LoanStatus.Refinanced:
                return { caption: "Refinanced", date: getLoanCompletedDate(loan) };
            case LoanStatus.CompletedLoan:
                return { caption: "Repaid", date: getLoanCompletedDate(loan) };
            case LoanStatus.DefaultedLoan:
                return { caption: "Defaulted", date: getLoanDefaultedDate(loan) };
            case LoanStatus.SoldLoanSeller:
                return { caption: "Withdrawn", date: getLoanSoldTime(loan, escrowPubkeys) };
            default:
                return null;
        }
    }, [escrowPubkeys, loan]);

    if (pastDetails) {
        return {
            stat: { caption: pastDetails.caption, value: formatDate(pastDetails.date, "relative") },
            formatted: `${pastDetails.caption} ${formatDate(pastDetails.date, "relative").toLocaleLowerCase()}`
        };
    }

    if (isLoanZeroCoupon(loan)) {
        if (loan?.order.refinanceEnabled) {
            return {
                stat: {
                    caption: "Rate fixed",
                    value: formatDate(getNextPaymentDate(loan), "relative").replace("In", "For"),
                    tooltip: `Your APY will update to market rate on ${formatDate(getNextPaymentDate(loan))}`
                }
            };
        }
        return {
            stat: { caption: "Due", value: formatDate(getNextPaymentDate(loan)) },
            formatted: `Due ${formatDate(getNextPaymentDate(loan), "relative").toLocaleLowerCase()}`
        };
    }
    return {
        stat: { caption: "Next payment", value: formatDate(getNextPaymentDate(loan)) },
        formatted: `Next payment ${formatDate(getNextPaymentDate(loan), "relative").toLocaleLowerCase()}`
    };
}

function Expanded({
    loan,
    query,
    isMobile
}: {
    loan: AbfLoanExpanded | undefined;
    query: MediaQuery;
    isMobile: boolean;
}) {
    return (
        <>
            <Stats loan={loan} query={query} />
            <LoanActions linkToDetailCta="Loan details" loanExpanded={loan} isCondensed={isMobile} />
        </>
    );
}

function Stats({ loan, query }: { loan: AbfLoanExpanded | undefined; query: MediaQuery }) {
    const isActive = isLoanActive(loan);
    const { groupIdentifier } = useActiveGroup();

    const ledgerStats = summarizeLedgerAccounts(loan);
    const { stat: dueStat } = useDueDetails(loan);
    const isMobile = useMediaQuery(query.below);

    const { proceeds: totalLenderSaleProceeds } = getTotalLenderSaleProceeds(loan);
    const { data: bid } = useLoanBidAccount(loan);

    const whirlpoolPosition = getLoanOrcaCollateral(loan)?.whirlpoolPosition;

    const claimableFeesUsd = getClaimableWhirlpoolFees(whirlpoolPosition);

    const isLender = groupIdentifier === loan?.lender.groupIdentifier;

    return (
        <PositionCardStats
            loading={!loan}
            stats={[
                {
                    caption: "Total debt",
                    value: <LoanPrincipal principal={loan?.debt.total} loan={loan} />,
                    hide: !isActive || !isMobile
                },
                {
                    caption: "Borrowed",
                    value: isMobile ? (
                        <LoanPrincipal principal={loan?.principalAmount} loan={loan} />
                    ) : (
                        BsMetaUtil.formatAmount(loan?.principalMetadata, loan?.principalAmount)
                    )
                },
                {
                    caption: "Interest accrued",
                    value: BsMetaUtil.formatAmount(loan?.principalMetadata, loan?.debt.interestAccrued),
                    hide: isMobile || !isActive
                },
                { caption: "Collateral", value: <LoanCollateral loan={loan} />, hide: !isMobile },
                { caption: "APY", value: formatPercent(loan?.apy), hide: !isMobile },
                {
                    caption: "Health",
                    value: <LoanHealthText loan={loan} />,
                    hide: !isMobile || !isActive || !isLoanOracleBased(loan)
                },
                {
                    caption: "Repaid",
                    value: BsMetaUtil.formatAmount(loan?.principalMetadata, ledgerStats?.totalPaid),
                    hide: isMobile || loan?.status === LoanStatus.SoldLoanSeller
                },
                {
                    caption: "Withdrawn",
                    value: BsMetaUtil.formatAmount(loan?.principalMetadata, totalLenderSaleProceeds),
                    hide: !totalLenderSaleProceeds || loan?.status !== LoanStatus.SoldLoanSeller
                },
                {
                    caption: "Collateral returned",
                    value: formatTokens(bid?.collateralRetained),
                    hide: !bid?.collateralRetained.length
                },
                dueStat,
                {
                    caption: "Position balances",
                    value: formatTokens(whirlpoolPosition ? [whirlpoolPosition.tokenA, whirlpoolPosition.tokenB] : [], {
                        showAll: true
                    }),
                    hide: !whirlpoolPosition || isLender
                },
                {
                    caption: "Pending yield",
                    value: whirlpoolPosition ? (
                        <ClaimWhirlpoolFeesButton claimableFeesUsd={claimableFeesUsd} loanExpanded={loan} />
                    ) : undefined,
                    hide: claimableFeesUsd === 0 || isLender
                }
            ]}
        />
    );
}

function OrcaStatus({ loan }: { loan: AbfLoanExpanded | undefined }) {
    const whirlpoolPosition = getLoanOrcaCollateral(loan)?.whirlpoolPosition;
    const ticks = whirlpoolPosition ? parseOrcaTicks(whirlpoolPosition) : undefined;
    const { groupIdentifier } = useActiveGroup();

    const isLender = groupIdentifier === loan?.lender.groupIdentifier;

    return !isLender ? (
        <PriceRangeStatus
            iconOnly
            tickCurrentIndex={ticks?.currentTick}
            tickLower={ticks?.tickLower}
            tickUpper={ticks?.tickUpper}
        />
    ) : null;
}
