import { useEffect, useMemo, useState } from "react";

import { DialogWrapper } from "@bridgesplit/ui";
import { ChainId } from "@bridgesplit/abf-sdk";
import { DispatchType } from "@bridgesplit/react";
import { BsMetaUtil, useBsMetadataByMint } from "@bridgesplit/abf-react";
import { AppDialog, useAppDialog } from "app/utils";

import WithdrawWithSource from "./withdraw/WithdrawWithSource";
import { SelectChain } from "../wormhole";
import { EvmBalanceWrapperWithSwitch } from "./common";
import { DialogHeaderWithBack } from "../common";
import { WalletRequiredWrapper } from "../transactions";

export default function WithdrawDialog() {
    const { getData } = useAppDialog();

    const data = getData(AppDialog.Withdraw);
    const mints = useMemo(() => data?.mints ?? [], [data?.mints]);

    const [chainSelected, setChainSelected] = useState(false);

    const firstMint = mints?.length === 1 ? mints[0] : undefined;
    const firstMetadata = useBsMetadataByMint(firstMint);
    const canBridge = firstMetadata ? BsMetaUtil.getChainId(firstMetadata) !== ChainId.Solana : true;

    return (
        <DialogWrapper>
            <SelectChain.Wrapper>
                <EvmBalanceWrapperWithSwitch>
                    <WalletRequiredWrapper>
                        <SetInitialChain
                            chainSelected={chainSelected}
                            setChainSelected={setChainSelected}
                            chain={data?.chain}
                        />
                        <DialogHeaderWithBack
                            header="Withdraw"
                            rowEnd={canBridge && <SelectChain.PromptToSwitch />}
                            back={data?.back}
                        />

                        <WithdrawWithSource mints={mints} />
                    </WalletRequiredWrapper>
                </EvmBalanceWrapperWithSwitch>
            </SelectChain.Wrapper>
        </DialogWrapper>
    );
}

// separate component because it needs to be a child of SelectChain Provider
// necessary to use useSelectChain directly because otherwise user is not validated to multichain enabled
function SetInitialChain({
    chain,
    chainSelected,
    setChainSelected
}: {
    chain: ChainId | undefined;
    chainSelected: boolean;
    setChainSelected: DispatchType<boolean>;
}) {
    const select = SelectChain.useSelectChain();

    useEffect(() => {
        if (!chain || chainSelected) return;
        (async () => {
            await select(chain);
            setChainSelected(true);
        })();
    }, [chain, chainSelected, select, setChainSelected]);

    return null;
}
