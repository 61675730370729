import { useMemo, useState } from "react";

import { Column, Row, StatColumn, StatProps, Text, TextButton, TextVariant } from "@bridgesplit/ui";
import { filterTruthy, formatDate, formatPercent } from "@bridgesplit/utils";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { BsMetaUtil, LoopscalePointSource, useBsMetadataByMint, useOraclePrices } from "@bridgesplit/abf-react";
import { TransactionTypes } from "app/types";
import { useLoopscalePoints } from "app/components/points/stats";
import { useTransactionFeeStat } from "app/components/transactions";

import { useMarketBorrowContext } from "../MarketBorrowContext";
import { useBorrowLtv, useScheduleDetails } from "../util";
import { useMarketContext } from "../../common";
import BorrowHealthStats from "../BorrowHealthStats";

export default function Terms() {
    const [expanded, setExpanded] = useState(false);
    const { bestQuote, quoteFetching } = useMarketBorrowContext();

    const {
        form: { amount }
    } = useMarketBorrowContext();

    if (!amount || (!bestQuote && !quoteFetching)) return null;

    return (
        <>
            <Row spaceBetween>
                <Row spacing={0.5}>
                    <Text loadingWidth="30px" loading={!bestQuote} variant="body1">
                        {formatPercent(bestQuote?.apy)}
                    </Text>
                    <Text color="caption">APY</Text>
                </Row>
                <TextButton color="caption" onClick={() => setExpanded(!expanded)}>
                    {expanded ? "Show less" : "Show more"} {expanded ? <ExpandLess /> : <ExpandMore />}
                </TextButton>
            </Row>
            {expanded && <BorrowStats />}
        </>
    );
}

function BorrowStats({ variant = "body2" }: { variant?: TextVariant }) {
    const { token } = useMarketContext();
    const { ltv } = useBorrowLtv();
    const {
        form: { amount, collateralMint }
    } = useMarketBorrowContext();
    const { getOracle } = useOraclePrices([token?.assetMint]);

    const scheduleDetails = useScheduleDetails();

    const usdPrice = getOracle(token?.assetMint)?.getUsdAmount(amount, token?.decimals);
    const collateralMetadata = useBsMetadataByMint(collateralMint);

    const transactionFeeStat = useTransactionFeeStat({
        transactionType: TransactionTypes.MarketBorrow,
        variant: variant
    });
    const pointsStat = useLoopscalePoints({
        actions: [LoopscalePointSource.Borrow],
        totalAmount: usdPrice,
        metadata: [token, collateralMetadata].filter(filterTruthy) ?? []
    });

    const stats = useMemo((): StatProps[] => {
        return [
            pointsStat,
            {
                caption: "Loan-to-value",
                value: formatPercent(ltv)
            },
            {
                caption: "Interest",
                value: BsMetaUtil.formatAmount(token, scheduleDetails?.totalInterest),
                tooltip: `Total interest if repaid ${formatDate(scheduleDetails?.loanEndDate, "relative")}`
            },
            transactionFeeStat
        ];
    }, [ltv, pointsStat, scheduleDetails?.loanEndDate, scheduleDetails?.totalInterest, token, transactionFeeStat]);

    return (
        <Column spacing={0.5}>
            <StatColumn captionVariant={variant} variant={variant} stats={stats} />
            <BorrowHealthStats variant={variant} />
        </Column>
    );
}
