import {
    fetchAndDeserializeLegacyTransaction,
    fetchAndDeserializeLegacyTransactions,
    fetchAndDeserializeLegacyTransactionsWithSetup,
    fetchAndDeserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransactions
} from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    CHECK_DEFAULT_ENDPOINT,
    CheckDefaultArgs,
    REPAY_LOAN_ENDPOINT,
    LOAN_WITHDRAW_ENDPOINT,
    RepayLoanArgs,
    LoanWithdrawArgs,
    SETUP_LOAN_ENDPOINT,
    SetupLoanArgs,
    RepayAllLedgersArgs,
    REPAY_MULTIPLE_LEDGERS_ENDPOINT,
    SyncLedgersArgs,
    SYNC_LEDGERS_ENDPOINT,
    REPAY_ZC_LOAN_ENDPOINT,
    ATOMIC_LOAN_END_ENDPOINT,
    TransactionGenerationQuery,
    RepayZcLoanArgs
} from "../types";
import { createAbfInstance } from "./auth";

export async function getSetupLoanTransactions(authentication: AbfTransactionHeaders, args: SetupLoanArgs) {
    return await fetchAndDeserializeLegacyTransactionsWithSetup(
        createAbfInstance(authentication),
        SETUP_LOAN_ENDPOINT,
        args
    );
}

export async function getRepayLoanTransaction(authentication: AbfTransactionHeaders, args: RepayLoanArgs) {
    return await fetchAndDeserializeVersionedTransactions(createAbfInstance(authentication), REPAY_LOAN_ENDPOINT, args);
}

export async function getRepayZcLoanTransactions(
    authentication: AbfTransactionHeaders,
    args: RepayZcLoanArgs,
    query: TransactionGenerationQuery | undefined
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication),
        REPAY_ZC_LOAN_ENDPOINT,
        args,
        query
    );
}

export async function getSyncLedgersTransactions(authentication: AbfTransactionHeaders, args: SyncLedgersArgs) {
    return await fetchAndDeserializeLegacyTransactions(
        createAbfInstance(authentication),
        `${SYNC_LEDGERS_ENDPOINT}/${args.orderAddress}`,
        {}
    );
}

export async function getRepayAllLedgersTransaction(
    authentication: AbfTransactionHeaders,
    args: RepayAllLedgersArgs
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication),
        REPAY_MULTIPLE_LEDGERS_ENDPOINT,
        args
    );
}

export async function getLoanWithdrawVersionedTransaction(
    authentication: AbfTransactionHeaders,
    args: LoanWithdrawArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), LOAN_WITHDRAW_ENDPOINT, args)
    ];
}

export async function getLoanEndVersionedTransaction(
    authentication: AbfTransactionHeaders,
    args: LoanWithdrawArgs
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication),
        ATOMIC_LOAN_END_ENDPOINT,
        args
    );
}

export async function getCheckDefaultTransaction(authentication: AbfTransactionHeaders, args: CheckDefaultArgs) {
    return [
        await fetchAndDeserializeLegacyTransaction(createAbfInstance(authentication), CHECK_DEFAULT_ENDPOINT, args)
    ];
}
