import { fetchAndDeserializeVersionedTransaction, fetchAndDeserializeVersionedTransactions } from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    CreateOrderArgs,
    INIT_SYNDICATED_ORDER_ENDPOINT,
    FUND_SYNDICATED_ORDER_ENDPOINT,
    REDEEM_SYNDICATED_ORDER_ENDPOINT,
    SyndicatedOrderFundArgs,
    SyndicatedOrderRedeemArgs,
    UPDATE_SYNDICATED_ORDER_ENDPOINT,
    SyndicatedOrderUpdateArgs,
    SyndicatedOrderCancelArgs,
    CANCEL_SYNDICATED_ORDER_ENDPOINT,
    SyndicatedOrderPlaceArgs,
    PLACE_SYNDICATED_ORDER_ENDPOINT
} from "../types";
import { createSyndicatedInstance } from "./auth";

export async function getInitSyndicatedOrderTransaction(
    authentication: AbfTransactionHeaders,
    args: CreateOrderArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createSyndicatedInstance(authentication),
            INIT_SYNDICATED_ORDER_ENDPOINT,
            args
        )
    ];
}

export async function getFundSyndicatedOrderTransaction(
    authentication: AbfTransactionHeaders,
    args: SyndicatedOrderFundArgs
) {
    return await fetchAndDeserializeVersionedTransactions(
        createSyndicatedInstance(authentication),
        FUND_SYNDICATED_ORDER_ENDPOINT,
        args
    );
}

export async function getUpdateSyndicatedOrderTransaction(
    authentication: AbfTransactionHeaders,
    args: SyndicatedOrderUpdateArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createSyndicatedInstance(authentication),
            UPDATE_SYNDICATED_ORDER_ENDPOINT,
            args
        )
    ];
}

export async function getRedeemSyndicatedOrderTransactions(
    authentication: AbfTransactionHeaders,
    args: SyndicatedOrderRedeemArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createSyndicatedInstance(authentication),
            REDEEM_SYNDICATED_ORDER_ENDPOINT,
            args
        )
    ];
}

export async function getCancelSyndicatedOrderTransactions(
    authentication: AbfTransactionHeaders,
    args: SyndicatedOrderCancelArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createSyndicatedInstance(authentication),
            CANCEL_SYNDICATED_ORDER_ENDPOINT,
            args
        )
    ];
}

export async function getPlaceSyndicatedOrderTransactions(
    authentication: AbfTransactionHeaders,
    args: SyndicatedOrderPlaceArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createSyndicatedInstance(authentication),
            PLACE_SYNDICATED_ORDER_ENDPOINT,
            args
        )
    ];
}
