import { useMemo } from "react";

import { LoopPositionExpanded, LoopscalePointSource } from "@bridgesplit/abf-react";
import { filterTruthy } from "@bridgesplit/utils";
import { getLoopEligibleRewardSources } from "app/components/common";
import { RateAndRewardsBreakdown } from "app/components/points";

export function LoopPositionApy({ loopPosition }: { loopPosition: LoopPositionExpanded | undefined }) {
    const metadata = [loopPosition?.loopExpanded.principalToken, loopPosition?.loopExpanded.collateralToken].filter(
        filterTruthy
    );
    const pointSources = getLoopEligibleRewardSources(metadata);

    const usdValue = useMemo(() => {
        if (!loopPosition) return 0;
        const { loanExpanded } = loopPosition;
        const principalUsd = loanExpanded.principalAmount * loanExpanded.principalUsdPrice;

        return principalUsd;
    }, [loopPosition]);

    if (!loopPosition?.userLoopInfo.netApy) return null;

    return (
        <RateAndRewardsBreakdown
            externalPointSources={pointSources}
            loopscalePointSources={[LoopscalePointSource.Borrow]}
            totalAmount={usdValue}
            metadata={metadata}
            apy={loopPosition.userLoopInfo.netApy}
            isSpecific
            rateDisplayProps={{ showApyLabel: true }}
        />
    );
}
