import { LoopscalePointSource, CollateralInfo, POINTS_BASIS } from "@bridgesplit/abf-react";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { TIME } from "@bridgesplit/utils";

export function calculatePointsData({
    loopscalePointSource,
    metadata = [],
    collateralInfo,
    pointsPerSecond,
    relativePointsPerSecond,
    totalAmount,
    estimateSeconds = TIME.DAY
}: {
    loopscalePointSource?: LoopscalePointSource[];
    metadata?: BsMetadata[];
    collateralInfo: Record<string, CollateralInfo> | undefined;
    pointsPerSecond: Record<LoopscalePointSource, number> | undefined;
    relativePointsPerSecond: Record<LoopscalePointSource, number> | undefined;
    totalAmount: number;
    estimateSeconds?: number;
}) {
    if (
        !Array.isArray(metadata) ||
        !metadata.length ||
        !Array.isArray(loopscalePointSource) ||
        !loopscalePointSource.length ||
        !collateralInfo ||
        !pointsPerSecond ||
        !relativePointsPerSecond
    ) {
        return {
            totalPoints: 0,
            multiplier: 1,
            baseRate: 0,
            pointsPerDay: 0
        };
    }

    // Calculate multipliers for each point source
    const multipliers = loopscalePointSource.map((p) => {
        const totalCollateralMultiplier = metadata
            .filter((m) => {
                const hasAssetMint = !!m?.assetMint;
                const hasCollateralData = !!collateralInfo[m?.assetMint ?? ""]?.pointSourceToNormalizedPoints;
                return hasAssetMint && hasCollateralData;
            })
            .map((m) => {
                const points =
                    collateralInfo[m.assetMint]?.pointSourceToNormalizedPoints[p] / relativePointsPerSecond[p];
                return points;
            })
            .filter((points): points is number => typeof points === "number" && !isNaN(points));

        const collateralMultiplierProduct =
            totalCollateralMultiplier.length > 0 ? totalCollateralMultiplier.reduce((acc, curr) => acc * curr, 1) : 0;

        const finalMultiplier = collateralMultiplierProduct || 1;
        return finalMultiplier;
    });

    // Sum up all multipliers

    const multiplier = multipliers.reduce((sum, current) => sum + current, 0);
    const relativeMultiplier = loopscalePointSource.reduce((sum, p: LoopscalePointSource) => {
        return sum + (relativePointsPerSecond[p] || 0); // Use a default value of 0 if `relativePointsPerSecond[p]` is undefined
    }, 0);
    // Get base rate using IdleCap as reference
    const baseRate = pointsPerSecond[LoopscalePointSource.IdleCap] ?? 0;

    // Calculate points per day
    const pointsPerDay = multiplier * relativeMultiplier * baseRate * (totalAmount / POINTS_BASIS) * TIME.DAY;

    // Calculate total points if estimateSeconds is provided
    const totalPoints = estimateSeconds
        ? multiplier * relativeMultiplier * baseRate * (totalAmount / POINTS_BASIS) * estimateSeconds
        : 0;

    return {
        totalPoints,
        multiplier,
        baseRate,
        pointsPerDay
    };
}
