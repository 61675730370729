import { useState } from "react";

import {
    AbfPermission,
    TokenBalanceExpanded,
    useActiveWallet,
    useWalletTransfer,
    useWalletAvailableAssets,
    isStakedSol,
    useActiveEscrow,
    useActiveGroup
} from "@bridgesplit/abf-react";
import { MISSING_PARAM_ERROR, Result, filterNullableRecord, uiAmountToLamports } from "@bridgesplit/utils";
import { useTransactionSender } from "app/utils";

import { AppButton, SelectAssets, getSelectAssetsErrorMessage } from "../../common";
import { useSelectAddressController } from "../common";

export default function WithdrawFromWallet({ mints }: { mints?: string[] }) {
    const selectedMints = new Set(mints);
    const [selected, setSelected] = useState<Map<string, number | undefined>>(new Map());

    const send = useTransactionSender();
    const transfer = useWalletTransfer();

    const { activeWallet } = useActiveWallet();
    const { groupIdentifier } = useActiveGroup();
    const { escrowNonce } = useActiveEscrow();

    const assets = useWalletAvailableAssets(activeWallet?.wallet, {
        includeStakedSol: true,
        fullSolBalance: true,
        includeLpPositions: true
    })?.filter((m) => (mints?.length ? selectedMints.has(m.key) : true));

    const {
        AddressComponent,
        address,
        errorMessage: addressError
    } = useSelectAddressController({ alwaysCustom: true });

    const selectedAssets = assets
        ?.map((d): TokenBalanceExpanded & { selectedAmount: number } => ({
            ...d,
            selectedAmount: selected.get(d.key) || 0
        }))
        .filter((d) => !!d.selectedAmount);

    async function onClickWithResult() {
        if (!selectedAssets || !address || !activeWallet || !groupIdentifier || !escrowNonce)
            return Result.errFromMessage(MISSING_PARAM_ERROR);
        return send(transfer, {
            stake: selectedAssets
                .map((s) => ({
                    parentStakePubkey: s.stakeAccount?.stakeAccount,
                    amount: s.amount,
                    recipient: address
                }))
                .filter(filterNullableRecord),
            spl: selectedAssets
                .filter((s) => !isStakedSol(s.mint))
                .map(({ selectedAmount, mint, metadata }) => ({
                    sender: activeWallet.wallet,
                    payer: activeWallet.wallet,
                    receiver: address,
                    mint,
                    amount: uiAmountToLamports(selectedAmount, metadata.decimals)
                }))
        });
    }

    const errorMessage = getSelectAssetsErrorMessage({ assets, selected }) || addressError;

    return (
        <>
            <AddressComponent />

            <SelectAssets
                allowTokenInput={mints?.length === 1}
                selected={selected}
                setSelected={setSelected}
                assets={assets}
            />

            <AppButton
                isTransaction
                authentication={{ permission: AbfPermission.WithdrawTokens }}
                fullWidth
                disabled={!!errorMessage}
                asyncCta={{ onClickWithResult }}
                variant="contained"
            >
                {errorMessage ?? "Withdraw"}
            </AppButton>
        </>
    );
}
