import { fetchAndDeserializeLegacyTransaction, fetchAndDeserializeVersionedTransaction } from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    CANCEL_ORDER_ENDPOINT,
    CREATE_ORDER_ENDPOINT,
    CancelOrderArgs,
    CreateOrderArgs,
    FILL_ORDER_ENDPOINT,
    FillOrderArgs
} from "../types";
import { createAbfInstance } from "./auth";

export async function getCancelOrderTransaction(authentication: AbfTransactionHeaders, args: CancelOrderArgs) {
    return [await fetchAndDeserializeLegacyTransaction(createAbfInstance(authentication), CANCEL_ORDER_ENDPOINT, args)];
}

export async function getCreateOrderTransaction(authentication: AbfTransactionHeaders, args: CreateOrderArgs) {
    return [
        await fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), CREATE_ORDER_ENDPOINT, args)
    ];
}

export async function getFillOrderEndpoint(authentication: AbfTransactionHeaders, args: FillOrderArgs) {
    return [
        await fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), FILL_ORDER_ENDPOINT, args)
    ];
}
