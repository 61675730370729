import { useMemo, useState } from "react";

import { InvisibleSelect, RewardIcon, Row, SelectOption, StatProps, Text, TextVariant } from "@bridgesplit/ui";
import { formatNum, TIME } from "@bridgesplit/utils";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { LoopscalePointSource, useCollateralPointsSourcesUtil } from "@bridgesplit/abf-react";

import { calculatePointsData } from "./util";

export interface UsePointsStat {
    variant?: TextVariant;
    actions: LoopscalePointSource[];
    showSelect?: boolean;
    totalAmount: number | undefined;
    metadata: BsMetadata[] | undefined;
}

const TIME_OPTIONS: SelectOption<number>[] = [
    { label: "per day", value: TIME.DAY },
    { label: "per week", value: TIME.WEEK },
    { label: "per month", value: TIME.MONTH },
    { label: "per year", value: TIME.YEAR }
];

interface UseCalculatePointsProps {
    loopscalePointSource: LoopscalePointSource[];
    estimateSeconds: number;
    totalAmount?: number;
    metadata?: BsMetadata[];
}

export function useCalculatePoints({
    loopscalePointSource,
    estimateSeconds,
    totalAmount = 0,
    metadata = []
}: UseCalculatePointsProps): number {
    const { collateralInfo, pointsPerSecond, relativePointsPerSecond } = useCollateralPointsSourcesUtil();

    return useMemo(() => {
        const { totalPoints } = calculatePointsData({
            loopscalePointSource,
            metadata,
            collateralInfo,
            pointsPerSecond,
            relativePointsPerSecond,
            totalAmount,
            estimateSeconds
        });

        return totalPoints;
    }, [
        metadata,
        loopscalePointSource,
        collateralInfo,
        pointsPerSecond,
        relativePointsPerSecond,
        totalAmount,
        estimateSeconds
    ]);
}

export function useLoopscalePoints({
    variant = "body2",
    actions,
    showSelect = true,
    totalAmount,
    metadata
}: UsePointsStat) {
    const { getCheckMultiplierGreaterThanBase } = useCollateralPointsSourcesUtil();
    const [estimateSeconds, setEstimateSeconds] = useState(TIME_OPTIONS[0].value);

    const calculatedPoints = useCalculatePoints({
        loopscalePointSource: actions,
        estimateSeconds,
        totalAmount,
        metadata
    });

    const caption = (
        <Row>
            <Text sx={{ mr: showSelect ? -0.5 : 0 }} variant={variant} color="caption">
                {showSelect ? "Rewards" : "Rewards per day"}
            </Text>
            {showSelect && (
                <InvisibleSelect
                    variant={variant}
                    color="caption"
                    value={estimateSeconds}
                    setValue={setEstimateSeconds}
                    options={TIME_OPTIONS}
                />
            )}
        </Row>
    );

    const value = (
        <Row spacing={1}>
            <RewardIcon hasMultiplier={getCheckMultiplierGreaterThanBase(actions, metadata)} />
            <Text variant={variant} loading={!calculatedPoints}>
                {formatNum(calculatedPoints)}
            </Text>
        </Row>
    );

    return {
        value,
        caption
    };
}
export function useLoopPoints({
    borrowed,
    variant,
    metadata
}: {
    borrowed: number | undefined;
    variant: TextVariant;
    metadata: BsMetadata[] | undefined;
}): StatProps[] {
    const pointsStat = useLoopscalePoints({
        actions: [LoopscalePointSource.Borrow],
        totalAmount: borrowed,
        variant,
        metadata
    });

    return [pointsStat];
}
