import { LoopscalePointSource, PointsInfoDetails, ExternalPointSource } from "../types";

export const LOOPSCALE_POINTS_SOURCE_INFO: Record<LoopscalePointSource, PointsInfoDetails> = {
    [LoopscalePointSource.Borrow]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Borrow boost"
    },
    [LoopscalePointSource.Lend]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Lend boost"
    },
    [LoopscalePointSource.IdleCap]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Idle cap boost"
    }
};

export const POINTS_SOURCE_INFO: Record<ExternalPointSource, PointsInfoDetails> = {
    [ExternalPointSource.MarginFi]: {
        externalPointSource: ExternalPointSource.MarginFi,
        caption: "mrgn rewards"
    },
    [ExternalPointSource.LoopscaleHidden]: {
        externalPointSource: ExternalPointSource.LoopscaleHidden,
        caption: "Total multiplier"
    },
    [ExternalPointSource.Loopscale]: {
        externalPointSource: ExternalPointSource.Loopscale,
        caption: "Total multiplier"
    },
    [ExternalPointSource.Jupiter]: {
        externalPointSource: ExternalPointSource.Jupiter,
        caption: "Jupiter rewards"
    },
    [ExternalPointSource.Meteora]: {
        externalPointSource: ExternalPointSource.Meteora,
        caption: "Meteora rewards"
    },
    [ExternalPointSource.HubSol]: {
        externalPointSource: ExternalPointSource.HubSol,
        caption: "Loyalty league rewards"
    },
    [ExternalPointSource.Solayer]: {
        externalPointSource: ExternalPointSource.Solayer,
        caption: "Solayer rewards"
    },
    [ExternalPointSource.Adrastea]: {
        externalPointSource: ExternalPointSource.Adrastea,
        caption: "Adrastea Points"
    }
};

// The USD per unit amount. Currently points per day is the rate per usd per $1
export const POINTS_BASIS = 1;
export const BASE_PPS = 0.05;
export const BASE_REFFERAL_PPS = 0;

interface LoopscalePointCaptionInfo {
    full: string;
    short: string;
}

export const LOOPSCALE_POINT_CAPTIONS: Record<LoopscalePointSource, LoopscalePointCaptionInfo> = {
    [LoopscalePointSource.Borrow]: {
        full: "Borrow multiplier",
        short: "Borrow"
    },
    [LoopscalePointSource.Lend]: {
        full: "Lend multiplier",
        short: "Lend"
    },
    [LoopscalePointSource.IdleCap]: {
        full: "Idle capital multiplier",
        short: "Idle capital"
    }
};
