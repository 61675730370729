export const HEALTH_ROUTE = "/health";

export const USER_CUSTODIAN_PERMISSONS = "/auth/users/custodian_permissions";
export const CHECK_GROUP_ON_CHAIN_ROLES_ROUTE = "/auth/groups/roles/status";
export const VERIFY_USER_WALLET_SETUP_ROUTE = "/auth/users/wallet_info";
export const GROUP_ROLES_IS_SETUP = "/auth/groups/roles/is_setup";
export const GROUP_ESCROWS_ROUTE = "/auth/groups/escrows";
export const GET_MULTICHAIN_WALLETS_ROUTE = "/auth/users/wallet/multichain";
export const USER_GROUP_VIEW_ROUTE = "/auth/groups/view";

export const SELF_USER_INFO_ROUTE = "/auth/users/me";
export const ADD_WALLET_TO_USER_ROUTE = "/auth/users/wallet/me";
export const RESEND_VERIFY_EMAIL_ROUTE = "/user/me/verify_email";
export const UPDATE_USER_ROUTE = "/users/me/update";
export const UPLOAD_USER_AVATAR_ROUTE = "/auth/users/avatar/upload";
export const LINK_EMAIL_ROUTE = "/auth/link_email";
export const CHECK_USER_MFI_ACCOUNT_ROUTE = "/user_mfi/exists";

export const PUT_USER_INVITES_ROUTE = "/auth/invite/group/users";
export const PUT_CUSTODIAN_INVITES_ROUTE = "/auth/invite/custodian/groups";

export const GET_CUSTODIAN_MEMBERS_ROUTE = "/auth/members/custodian/me";

export const PUT_CUSTODIAN_GROUP_ROLES_ROUTE = "/auth/custodian/groups/edit";
export const PUT_USER_GROUP_ROLES_ROUTE = "/auth/users/group/update";

export const USER_GROUP_PERMISSIONS_ROUTE = "/auth/groups/users";
export const CREATE_GROUP_ROUTE = "/auth/groups/create";
export const GET_GROUPS_ROUTE = "/auth/groups/identifiers";
export const GROUP_METADATA_BY_IDENTIFIERS_ROUTE = "/auth/groups/metadata/identifiers";
export const EDIT_GROUP_ROUTE = "/auth/groups/edit";

export const PUT_GROUP_METADATA_ROUTE = "/auth/groups/metadata";
export const FETCH_GROUP_DOCUMENTS_ROUTE = "/group/documents/fetch";
export const EDIT_GROUP_DOCUMENTS_ROUTE = "/group/documents/edit";
export const GET_GROUPS_BY_EMAILS_ROUTE = "/auth/groups/emails";
export const GET_USER_METADATA_BY_EMAILS_ROUTE = "/auth/users/emails";
export const EMAIL_EXISTS_ROUTE = "/auth/users/email_exists";

export const GET_GROUPS_METADATA_BY_ESCROWS_ROUTE = "/escrow_accounts/groups";

export const GET_ORDERS_ROUTE = "/orders";
export const GET_ORDER_INFO_ROUTE = "/orders/info";

export const GET_ESCROWED_ASSETS_ROUTE = "/escrows";
export const GET_ESCROW_EDIT_IX_ROUTE = "/ix/escrow_edit";
export const ESCROW_ASSET_HISTORY_ROUTE = "/escrows/history";

export const GET_LEDGER_ACCOUNTS_ROUTE = "/ledger_accounts";
export const LEDGER_ACCOUNTS_SYNC_ROUTE = "/ledger_accounts/sync";

// fee routes
export const GET_FEES_ROUTE = "/fees";
export const CREATE_NEW_FEE_SCHEDULE_FOR_ORDER = "/fees/create/order";
export const GET_FEE_INFOS_FOR_LOANS = "/fees/loans/info";
export const GET_POTENTIAL_FEES_ROUTE = "/potential_fees";
export const ROUTE_GET_FEE_SCHEDULES_FOR_ORDERS = "/fees/orders";

export const GET_ESCROWED_ASSETS_WITH_INFO_ROUTE = "/escrows/info";
export const GET_TOKENS_ROUTE = "/tokens_with_metadata";
export const GET_STAKED_SOL_ROUTE = "/staked_sol_with_metadata";
export const GET_STAKE_ACCOUNTS_BY_OWNERS_ROUTE = "/stake_accounts/owners";
export const GET_VALIDATOR_INFO_ROUTE = "/validator_info";
export const GET_UNSTAKE_SOL_QUOTE_ROUTE = "/txn/instant_unstake/route";

export const GET_LOANS_ROUTE = "/loans";
export const GET_LOANS_WITH_INFO_ROUTE = "/loans/info";
export const LOAN_SALES_INFO_ROUTE = "/loans/sales/info";
export const VERIFY_LOANS_SETUP_ROUTE = "/loans/verify/setup";
export const GET_MARKET_LOAN_STATS_ROUTE = "/loans/info/stats/market";
export const PAYMENT_EVENTS_ROUTE = "/events/payments";

export const GET_USER_INVITES_ROUTE = "/user/invites";

export const GET_LOCKBOXES_ROUTE = "/lockboxes";
export const COLLATERAL_STATS_ROUTE = "/stats/collateral";

export const GET_SYNDICATED_ORDERS_ROUTE = "/syndicates";
export const SYNDICATED_ORDERS_INVITE_ROUTE = "/syndicates/invite";
export const CREATE_SYNDICATED_ORDERS_ACCEPT_INVITE_ROUTE = "/syndicates/accept/invite";

export const GET_CUSTODIANS_ROUTE = "/custodians";
export const GET_CUSTODIANS_WITH_STATS_ROUTE = "/custodians/stats";
export const GET_CUSTODIAN_WITH_STATS_BY_SLUG_ROUTE = "/custodian/stats/slug";
export const GET_CUSTODIANS_BY_SLUG_ROUTE = "/custodian/slug";

export const LOAN_REQUESTS_ROUTE = "loan_requests";

export const ROUTE_GET_LENDING_STRATEGIES = "/lending_strategies";
export const ROUTE_GET_LENDING_STRATEGY_INFOS = "/lending_strategies/infos";
export const ROUTE_UPDATE_LENDING_STRATEGY = "/lending_strategy";
export const ROUTE_DELETE_LENDING_STRATEGY = "/lending_strategy";
export const ROUTE_DELETE_LENDING_OFFER = "/lending_strategy_offer";
export const ROUTE_UPDATE_LENDING_OFFER = "/lending_strategy_offer";
export const ROUTE_UPDATE_ASSET_TERMS = "/lending_strategy_asset_terms";
export const ROUTE_DELETE_ASSET_TERMS = "/lending_strategy_asset_terms";
export const ROUTE_UPDATE_STRATEGY_TERMS = "/lending_strategy_terms";
export const ROUTE_DELETE_STRATEGY_TERMS = "/lending_strategy_terms";
export const ROUTE_UPDATE_FILTER = "/lending_strategy_filter";
export const ROUTE_DELETE_FILTER = "/lending_strategy_filter";
export const ROUTE_GET_OFFER_INFO = "/lending_strategy_offer/infos";
export const ROUTE_ADD_LENDING_OFFER_WITH_FILTERS_AND_TERMS = "lending_strategy_offer/filters_and_terms";
export const ROUTE_GET_MATCHING_LENDING_OFFERS_AND_COLLATERAL_ROUTE = "/matching_offers_and_collateral";
export const FETCH_CUSTODIAN_SIGNUP_CODE_ROUTE = "/custodians/signup_code/fetch";
export const FETCH_ADMIN_CUSTODIAN_SIGNUP_CODE_ROUTE = "/custodians/signup_code";

export const CREATE_CUSTODIAN_SIGNUP_CODE_ROUTE = "/custodians/signup_code";
export const APPLY_CUSTODIAN_SIGNUP_CODE_ROUTE = "/custodians/signup_code/apply";
export const PORTFOLIO_STATS_ROUTE = "/portfolio_stats";

//napoleon routes
export const GET_MARKET_STRATEGY_STATS_ROUTE = "/markets/stats";
export const GET_MARKET_PRESETS_ROUTE = "/markets/presets";
export const GET_PRESETS_ROUTE = "/presets";
export const GET_MAX_QUOTE_ROUTE = "/quote/max";
export const GET_BEST_QUOTE_ROUTE = "/quote/best";
export const GET_BEST_LOOP_QUOTE_ROUTE = "/quote/loop";
export const GET_LOAN_SALE_PRICE_AND_OFFER_ROUTE = "/loan/sale_price_and_offer";

export const GET_PRESET_MIN_PRINCIPAL_ROUTE = "/presets/strategy/min_principal";
export const GET_MARKET_BORROW_CAPS_ROUTE = "/markets/borrow_caps";
export const GET_MARKET_PRINCIPAL_STATS_ROUTE = "/markets/principal/stats";
export const GET_ALL_MARKET_PRINCIPAL_STATS_ROUTE = "markets/principal/stats/all";
export const GET_CUSTODIAN_MARKET_STATS_ROUTE = "/markets/custodians/stats";
export const GET_RATE_HISTORY_ROUTE = "/rates/history";
export const GET_LOOP_VAULT_BORROW_RATE_HISTORY_ROUTE = "/rates/loop/history";

export const ROUTE_GET_COLLATERAL_YIELDS_IN_RANGE = "/collateral/yield/range";

export const MARKET_QUOTE_ROUTE = "/quote";
export const CUSTODIAN_QUOTE_ROUTE = "/quote/custodian";
export const CREATE_STRATEGY_FROM_PRESETS_ROUTE = "/presets/strategy/create";
export const ROUTE_PUT_OFFER_FROM_PRESETS = "/presets/offer/update";
export const ROUTE_GET_PRESET_TEMPLATES = "/presets/templates/fetch";
export const ROUTE_GET_REFINANCE_INFO = "/quote/refinance";
export const ROUTE_REFRESH_MARKET_PRESETS = "/markets/presets/refresh";
export const MARKET_QUOTE_FOR_APY_ROUTE = "/quote/apy";
export const ROUTE_INCREASE_CREDIT_QUOTE = "/quote/increase_credit";

export const ROUTE_GET_LOOP_VAULTS_INFO = "/loop/info";
export const ROUTE_GET_USER_LOOP_INFO = "/loop/positions";
export const ROUTE_GET_IS_LOOP_VAULT_SETUP = "/loop/vault/setup";
export const ROUTE_GET_LOOP_POSITION_STATS = "/loop/positions/stats";

// pricing routes
export const ROUTE_FETCH_PRICING = "/external_prices/fetch";
export const ROUTE_FETCH_QUOTE_ASSETS = "/quote_assets/fetch";
export const ROUTE_FETCH_LATEST_PRICING = "/external_prices/fetch/latest";

// external yield routes
export const ROUTE_GET_EXTERNAL_YIELD_INTEREST = "/external_yield/interest";
export const ROUTE_GET_EXTERNAL_YIELD_INFO = "/external_yield/info";

export const FETCH_ORACLE_PRICES_ROUTE = "/prices";
export const TURN_ORACLE_CRANK_ROUTE = "/crank_oracle";
export const BID_ACCOUNT_ROUTE = "/bid_account";

// messaging routes
export const UPDATE_NEWSLETTER_ROUTE = "/newsletter/user";

// identity routes
export const USER_IDENTITY_ROUTE = "/user/identity";
export const CREATE_USER_VERIFICATION_SESSION = "/user/verification_session";

// bs metadata routes
export const GET_BS_METADATA_ROUTE = "/metadata";
export const GET_ASSET_CLASS_INFO_ROUTE = "/asset_class_info";
export const GET_TOKEN_LIST_ROUTE = "/token_list_metadata";
export const GET_ORCA_POSITION_INFO_ROUTE = "/orca_positions";

// zephyr (sphere) routes
export const CREATE_SPHERE_INDIVIDUAL_CUSTOMER = "/sphere/customer/create/individual";
export const CREATE_SPHERE_BUSINESS_CUSTOMER = "/sphere/customer/create/business";
export const FETCH_SPHERE_USER_INFO = "/sphere/user_info/fetch";
export const CREATE_SPHERE_WALLET = "/sphere/wallet/create";
export const FETCH_SPHERE_WALLET = "/sphere/wallet/fetch";
export const CREATE_SPHERE_BANK_ACCOUNT = "/sphere/bank_account/create";
export const FETCH_SPHERE_BANK_ACCOUNT = "/sphere/bank_account/fetch";
export const CREATE_SPHERE_ONRAMP = "/sphere/payout/onramp";
export const CREATE_SPHERE_OFFRAMP = "/sphere/payout/offramp";
export const FETCH_SPHERE_PAYOUT = "/sphere/payout/fetch";
export const PROMPT_SPHERE_TOS = "/sphere/tos";
export const UPLOAD_UBO_DOCUMENTATION = "/sphere/upload/ubo_doc";
export const UPLOAD_INCORPORATION_DOCUMENTATION = "/sphere/upload/incorporation";
export const BUSINESS_KYB_STATUS = "/sphere/kyb/status";

export const MPC_USER_REGISTRATION_ROUTE = "/register";
export const MPC_USER_LOGIN_ROUTE = "/login";
export const MPC_USER_CREDENTIALS_ROUTE = "/credentials";
export const PASSKEY_HISTORY_ROUTE = "/passkey/history";

export const MPC_START_CREDENTIAL_REGISTER_CREDENTIAL_ROUTE = "/credentials/new";
export const MPC_COMPLETE_CREDENTIAL_REGISTER_CREDENTIAL_ROUTE = "/credentials/new/complete";

export const MPC_CREATE_CHALLENGE_ROUTE = "/challenge";
export const MPC_CREATE_PAT_ROUTE = "/pat";
export const ADD_NEW_UBO = "/sphere/upload/ubo";
export const GET_UBO_USER_STATUS = "/sphere/ubo/status";
export const CREATE_USER_MPC_WALLET_ROUTE = "/auth/mpc/wallet/create";
export const CREATE_MULTICHAIN_WALLET_ROUTE = "/multichain/create";
export const EVM_NFTS_BY_OWNER_ROUTE = "/nfts_by_owner";
export const EVM_METADATA_ROUTE = "/metadata";
export const EVM_BALANCE_ROUTE = "/balance";
export const EVM_GAS_ESTIMATION_ROUTE = "/estimate_gas";

//rubicon routes
export const INITIATE_TRANSFER_ROUTE = "/transfers/initiate";
export const COMPLETE_TRANSFER_ROUTE = "/transfers/complete";
export const GET_BRIDGE_TRANSFERS_ROUTE = "/bridge/transfer";
export const GET_EVM_TRANSFERS_ROUTE = "/evm/transfer";
export const TRANSFER_EVM_ROUTE = "/evm/transfer_asset";

//prime routes
export const APPLY_FOR_PRIME_ROUTE = "/auth/users/prime/apply";
export const USER_PRIME_STATUS_ROUTE = "/auth/users/prime";

// jito routes
export const GET_JITO_HEALTH_ROUTE = "/jito/health";
export const GET_PRIO_FEE_HEALTH_ROUTE = "/priority/health";

// ruby routes
export const ROUTE_GET_USER_REFERRAL_INFO = "/user/referral_info";
export const ROUTE_REFER_USER = "/user/refer";
export const ROUTE_CREATE_INVITES = "/invite/create";
export const ROUTE_FETCH_USER_INVITES = "/invite/fetch";
export const ROUTE_APPLY_INVITE = "/invite/apply";
export const ROUTE_GET_REWARDS = "/points/rewards";
export const ROUTE_GET_USER_WL_INFO = "/points/user/wl";
export const ROUTE_GET_USER_POINTS_CALC = "/points/user/calc";
export const ROUTE_GET_USER_REFERRAL_POINTS_CALC = "/points/user/referrals";
export const ROUTE_GET_POINT_MULTIPLIERS = "/points/multipliers";
export const ROUTE_VERIFY_TWITTER_FOLLOW = "/twitter/verify_follow";
export const ROUTE_FETCH_TWITTER_REQUEST_TOKEN = "/twitter/request_token";
export const ROUTE_FETCH_TWITTER_OAUTH_TOKEN = "/twitter/oauth_token";
export const ROUTE_FETCH_TWITTER_INFO = "/twitter/info";
export const ROUTE_VERIFY_DISCORD = "/discord/verify";
export const ROUTE_FETCH_DISCORD = "/discord";
export const ROUTE_ONBOARDING = "/onboarding";

// community reward routes
export const ROUTE_GET_COMMUNITY_REWARDS = "/points/community";
export const ROUTE_GET_USER_TOTAL_BY_TYPE = "/points/user_total_by_type";
export const ROUTE_APPLY_COMMUNITY_REWARDS = "/points/community/apply-rewards";
export const ROUTE_GET_COMMUNITY_MULTIPLIERS = "/community_multipliers/user_wallet";

export const TRANSACTIONS_STATUS_ROUTE = "/txns/status";

// meteora routes
export const METEORA_BASE = "/meteora";
export const ROUTE_METEORA_DEPOSIT_QUOTE = `${METEORA_BASE}/deposit_quote`;
export const ROUTE_METEORA_WITHDRAW_QUOTE = `${METEORA_BASE}/withdraw_quote`;
