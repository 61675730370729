import {
    useMeteoraDepositTransaction,
    useActiveWallet,
    useMeteoraWithdrawTransaction,
    LoopExpandedMeteora
} from "@bridgesplit/abf-react";
import { Meteora } from "@bridgesplit/bs-protos";
import { Result, LOADING_ERROR } from "@bridgesplit/utils";
import { useTransactionSender } from "app/utils";

export function useMeteoraLpDeposit() {
    const meteoraDeposit = useMeteoraDepositTransaction();
    const send = useTransactionSender();

    const { activeWallet } = useActiveWallet();

    return async (loopExpanded: LoopExpandedMeteora, externalQuote: Meteora.DynamicPoolDepositQuote | undefined) => {
        if (!loopExpanded) return Result.errFromMessage(LOADING_ERROR);
        if (!externalQuote) return Result.errFromMessage("Invalid quote");
        if (!activeWallet) return Result.errFromMessage("No active wallet");

        const { poolAddress } = loopExpanded.meteoraPool;

        return await send(meteoraDeposit, {
            pool: poolAddress,
            owner: activeWallet.wallet,
            tokenAInAmount: externalQuote.tokenAInAmount,
            tokenBInAmount: externalQuote.tokenBInAmount,
            poolTokenAmount: externalQuote.minPoolTokenAmountOut
        });
    };
}

export function useWithdrawMeteoraLp() {
    const meteoraWithdraw = useMeteoraWithdrawTransaction();
    const send = useTransactionSender();
    const { activeWallet } = useActiveWallet();

    return async (loopExpanded: LoopExpandedMeteora, externalQuote: Meteora.DynamicPoolWithdrawQuote | undefined) => {
        if (!loopExpanded) return Result.errFromMessage(LOADING_ERROR);

        if (!externalQuote) return Result.errFromMessage("Invalid quote");
        if (!activeWallet) return Result.errFromMessage("No active wallet");
        const { poolAddress } = loopExpanded.meteoraPool;

        return await send(meteoraWithdraw, {
            pool: poolAddress,
            owner: activeWallet.wallet,
            tokenAOutAmount: externalQuote.tokenAOutAmount,
            tokenBOutAmount: externalQuote.tokenBOutAmount,
            lpTokenAmount: externalQuote.poolTokenAmountIn
        });
    };
}
