import {
    ABF_API,
    MPC_API,
    Result,
    SYNDICATED_API,
    deserializeLegacyTransaction,
    fetchAndDeserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransactions
} from "@bridgesplit/utils";
import axios from "axios";
import { Transaction } from "@solana/web3.js";

import { AbfTransactionHeaders, RoleSetupArgs } from "../types/arguments";
import {
    CREATE_USER_MFI_ACCOUNT_ROUTE,
    GENERATE_SIGN_IN_TXN_ENDPOINT,
    ROLE_UPDATE_ENDPOINT,
    TXN_SETTINGS_HEADER,
    USER_WALLET_HEADER
} from "../types";

export function createAbfInstance(auth: AbfTransactionHeaders, api?: string) {
    return createInstanceWithAuth(api ?? ABF_API, auth);
}

export function createSyndicatedInstance(auth: AbfTransactionHeaders) {
    return createInstanceWithAuth(SYNDICATED_API, auth);
}

export function createMpcInstance(auth: AbfTransactionHeaders) {
    return createInstanceWithAuth(MPC_API, auth);
}

function createInstanceWithAuth(
    baseURL: string,
    { bearerToken, txnSettings, groupIdentifier, userWallet }: AbfTransactionHeaders
) {
    return axios.create({
        baseURL,
        headers: {
            Authorization: bearerToken ? `Bearer ${bearerToken}` : undefined,
            Organization: groupIdentifier,
            [USER_WALLET_HEADER]: userWallet,
            [TXN_SETTINGS_HEADER]: JSON.stringify(txnSettings)
        }
    });
}

export async function getUpdateRolesTransactions(authentication: AbfTransactionHeaders, args: RoleSetupArgs) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication),
        ROLE_UPDATE_ENDPOINT,
        args
    );
}

export async function getSignInTransaction(
    authentication: AbfTransactionHeaders,
    wallet: string
): Promise<Result<Transaction>> {
    try {
        const res = await createAbfInstance(authentication).get<string>(GENERATE_SIGN_IN_TXN_ENDPOINT, {
            headers: { [USER_WALLET_HEADER]: wallet }
        });
        const txn = deserializeLegacyTransaction(res.data);
        return Result.ok(txn);
    } catch (error) {
        return Result.err(error);
    }
}

export async function getMarginFiAccountSetupTransaction(authentication: AbfTransactionHeaders) {
    return [
        await fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), CREATE_USER_MFI_ACCOUNT_ROUTE)
    ];
}
