import { useState } from "react";

import { Column, StatColumn, TokenInput } from "@bridgesplit/ui";
import {
    AbfPermissionForCustodian,
    BsMetaUtil,
    getLoanEarlyFees,
    getLoanEndTime,
    getLoanLateFees,
    summarizeFeeAccounts,
    useIsLoanEscrowBased,
    usePayFeesTransaction,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { MayNumber, Result, formatDate } from "@bridgesplit/utils";

import { AppButton, useBalanceChecker } from "../../../common";
import { useTransactionSender } from "app/utils";
import { ActionProps } from "../types";

const DEFAULT_AMOUNT = -1;

export default function PayFees({ loanExpanded }: ActionProps) {
    const { feesOutstanding } = summarizeFeeAccounts(loanExpanded?.feeAccounts);

    const [inputAmountState, setAmount] = useState<MayNumber>(DEFAULT_AMOUNT);
    const amount = inputAmountState === DEFAULT_AMOUNT ? feesOutstanding : inputAmountState;

    const symbol = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);

    const earlyPaymentFees = getLoanEarlyFees(loanExpanded);
    const latePaymentFees = getLoanLateFees(loanExpanded);
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const send = useTransactionSender();
    const { BalanceDisplay, insufficientBalance } = useBalanceChecker({
        amount,
        mint: loanExpanded?.principalMetadata.assetMint,
        escrowNeeded,
        setMax: setAmount
    });

    const repayFeesTxn = usePayFeesTransaction();

    async function submit() {
        if (!amount) return Result.errFromMessage("No fees to pay");

        let remainingAmount = amount;
        const payFeeParams = loanExpanded?.feeAccounts
            .map((feeAccount) => {
                const totalDue = feeAccount.amountDue - feeAccount.amountPaid;

                // pay exact amount spread across ledgers in order
                const amountToPay = Math.min(totalDue, remainingAmount);
                remainingAmount -= amountToPay;

                const amountFromWallet = escrowNeeded ? 0 : amountToPay;
                const amountFromEscrow = escrowNeeded ? amountToPay : 0;

                return {
                    ledgerId: feeAccount.ledgerId,
                    feeType: feeAccount.feeType,
                    amountFromEscrow,
                    amountFromWallet,
                    orderAddress: loanExpanded.order.address,
                    loanVaultAddress: loanExpanded.loan.address,
                    feeMint: loanExpanded.order.principalMint
                };
            })
            .filter((params) => params.amountFromEscrow > 0 || params.amountFromWallet > 0);
        return await send(repayFeesTxn, payFeeParams, {
            sendOptions: { allowBatchFailure: false, commitmentLevel: "processed" }
        });
    }

    return (
        <>
            <TokenInput
                symbol={symbol}
                label="Amount"
                value={amount}
                setValue={setAmount}
                maxText="Total due: "
                maxAmount={feesOutstanding}
                maxButtonCta="Pay all"
            />

            <StatColumn
                stats={[
                    { value: latePaymentFees, caption: "Late fees", symbol, hide: !latePaymentFees },
                    { value: earlyPaymentFees, caption: "Prepayment fees", symbol, hide: !earlyPaymentFees },
                    {
                        value: feesOutstanding,
                        caption: "Total fees",
                        symbol,
                        hide: !latePaymentFees || !earlyPaymentFees
                    },
                    { caption: "Due", value: formatDate(getLoanEndTime(loanExpanded, true)) }
                ]}
            />
            <Column spacing={1}>
                <AppButton
                    isTransaction
                    asyncCta={{ onClickWithResult: submit }}
                    authentication={{
                        permission: AbfPermissionForCustodian.RepayLoan,
                        custodians: getLoanCustodianIdentifiers(loanExpanded)
                    }}
                    disabled={!amount || insufficientBalance}
                    variant="contained"
                    fullWidth
                >
                    Pay fees
                </AppButton>

                <BalanceDisplay />
            </Column>
        </>
    );
}
