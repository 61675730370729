import { useState } from "react";

import {
    Column,
    DIALOG_PADDING,
    DialogWrapper,
    Row,
    SearchInput,
    SkeletonRounded,
    Text,
    VerticalScroll,
    repeatElement,
    useAppPalette,
    useTabbableList
} from "@bridgesplit/ui";
import {
    BsMetaUtil,
    LabelVisibility,
    useCollateralPointsSourcesUtil,
    useMaxQuotesForCollateral
} from "@bridgesplit/abf-react";
import { formatPercent, formatTokenAmount, textContains } from "@bridgesplit/utils";
import { AppDialog } from "app/utils";

import { useUserCollateralForMarket } from "../borrow";
import MarketDialogHeader from "./common";
import { MarketProps } from "../types";
import { TokenImage } from "../../common";
import { useMarketDialog } from "../util";
import { CollateralLabel } from "../../points";

const ROW_HEIGHT = 50;
const MAX_HEIGHT = 500;
export default function BorrowSelectCollateralDialog() {
    return (
        <DialogWrapper>
            <BorrowSelectCollateralInternal />
        </DialogWrapper>
    );
}

export function BorrowSelectCollateralInternal() {
    const { close, getData } = useMarketDialog();

    const data = getData(AppDialog.BorrowSelectCollateral);
    const { hoverBackground } = useAppPalette();
    const [search, setSearch] = useState<string>("");

    const { data: collateral } = useUserCollateralForMarket(data?.marketProps as MarketProps, {
        includeLpPositions: true
    });

    const { data: collateralWithMaxQuotes, isFetching } = useMaxQuotesForCollateral({
        collateral,
        duration: data?.strategyDuration.duration,
        durationType: data?.strategyDuration.durationType,
        principalMint: data?.marketProps?.principalMint
    });

    const { getCollateralLoopscalePointSourceNonZero } = useCollateralPointsSourcesUtil();

    const tokens = collateralWithMaxQuotes?.filter(({ metadata }) =>
        search
            ? metadata.assetMint === search ||
              textContains([BsMetaUtil.getName(metadata), BsMetaUtil.getSymbol(metadata)], search)
            : true
    );

    const { activeIndex, itemRefs, containerRef, setActiveIndex } = useTabbableList(() => {
        const token = tokens?.[activeIndex];
        if (!token) return;
        data?.setToken?.(token);
        close();
    }, tokens?.length ?? 0);

    return (
        <Column spacing={2} sx={{ mx: -DIALOG_PADDING }}>
            <MarketDialogHeader padding header="Select collateral" />

            <SearchInput
                forceFocus
                sx={{ px: DIALOG_PADDING }}
                placeholder="Search by symbol or address"
                onChange={(e) => {
                    setSearch(e.target.value);
                    setActiveIndex(0);
                }}
                value={search}
            />

            <VerticalScroll ref={containerRef} maxHeight={MAX_HEIGHT}>
                {isFetching || !tokens
                    ? repeatElement(<SkeletonRounded sx={{ mx: DIALOG_PADDING, my: 0.5 }} height={ROW_HEIGHT} />, 5)
                    : tokens?.map((token, i) => {
                          const { metadata, maxQuote } = token;

                          const eligibleMultipliers = getCollateralLoopscalePointSourceNonZero(metadata);
                          const showRewards = eligibleMultipliers.length > 0;

                          return (
                              <Row
                                  ref={(el) => (el ? (itemRefs.current[i] = el) : null)}
                                  key={token.metadata.assetMint}
                                  sx={{
                                      px: DIALOG_PADDING,
                                      py: 1,
                                      cursor: "pointer",
                                      background: i === activeIndex ? hoverBackground : undefined,
                                      "&:hover": { background: hoverBackground }
                                  }}
                                  onClick={() => {
                                      data?.setToken?.(token);
                                      close();
                                  }}
                                  spaceBetween
                              >
                                  <Row spacing={1.5}>
                                      <TokenImage metadata={metadata} size="md" />
                                      <Column spacing={0.5}>
                                          <Row spacing={1}>
                                              <Text>{BsMetaUtil.getSymbolUnique(metadata)}</Text>
                                              <CollateralLabel
                                                  metadata={metadata}
                                                  loopscalePointSources={eligibleMultipliers}
                                                  visibility={
                                                      showRewards ? LabelVisibility.ShowFull : LabelVisibility.Hidden
                                                  }
                                                  showSymbolLabel={false}
                                              />
                                          </Row>

                                          {maxQuote ? (
                                              <Text color="caption" variant="body2">
                                                  {formatPercent(maxQuote?.ltv)} LTV
                                              </Text>
                                          ) : null}
                                      </Column>
                                  </Row>
                                  {(() => {
                                      if (!token.usdValue) {
                                          return null;
                                      }
                                      return (
                                          <Column spacing={0.5} alignItems="flex-end" textAlign="right">
                                              <Text>{token.amount}</Text>
                                              {maxQuote ? (
                                                  <Text color="caption" variant="body2">
                                                      Max{" "}
                                                      {formatTokenAmount(maxQuote?.maxBorrow ?? 0, {
                                                          symbol: BsMetaUtil.getSymbol(data?.marketProps.token),
                                                          decimals: 0
                                                      })}
                                                  </Text>
                                              ) : (
                                                  <Text color="disabled" variant="body2">
                                                      No offers
                                                  </Text>
                                              )}
                                          </Column>
                                      );
                                  })()}
                              </Row>
                          );
                      })}
            </VerticalScroll>
        </Column>
    );
}
