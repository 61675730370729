import { useCallback } from "react";

import { Column, ErrorMessage, TextButton } from "@bridgesplit/ui";
import { LOADING_ERROR, Result, percentUiToDecimals } from "@bridgesplit/utils";
import { useAbfFetches } from "@bridgesplit/abf-react";

import { PutTermsParams, StrategyTermsProps } from "./type";
import { AppButton } from "../../common";
import { usePutTerms } from "./util";
import { useSignedTransactionSender } from "app/utils";

export default function StrategyTermsCta({ strategy, terms, termToApy, setTermToApy }: StrategyTermsProps) {
    const changes = terms?.map(
        ({ key, minApy, strategyAssetTerms, presetOffers }): PutTermsParams => ({
            newApy: termToApy?.get(key) !== undefined ? percentUiToDecimals(termToApy?.get(key)) : undefined,
            originalApy: minApy,
            strategyAssetTerms,
            presetOffers
        })
    );

    const changesMade = !!changes?.find(({ newApy, originalApy }) => newApy !== originalApy);
    const putTerms = usePutTerms();
    const send = useSignedTransactionSender();
    const { resetNapoleonPublicApi } = useAbfFetches();

    const reset = useCallback(() => {
        setTermToApy(undefined);
        resetNapoleonPublicApi();
    }, [setTermToApy, resetNapoleonPublicApi]);

    const submit = useCallback(async () => {
        if (!changes || !strategy) return Result.errFromMessage(LOADING_ERROR);
        return await send(
            (signedTransaction) =>
                putTerms(
                    signedTransaction,
                    strategy.strategy.identifier,
                    changes,
                    strategy.collateral.map((c) => c.metadata)
                ),
            {
                description: "Updating terms"
            }
        );
    }, [changes, putTerms, send, strategy]);

    const termsExist = !!changes?.some((t) => !!t.newApy);

    return (
        <Column spacing={1}>
            {!!terms && !termsExist && <ErrorMessage errorMessage="You must set at least one term" />}
            <AppButton
                isTransaction={false}
                asyncCta={{
                    onClickWithResult: submit,
                    options: {
                        onSuccess: reset
                    }
                }}
                disabled={!changesMade || !termsExist}
                variant="contained"
            >
                Save changes
            </AppButton>
            {!!changesMade && (
                <TextButton onClick={reset} color="caption" variant="body2">
                    Reset
                </TextButton>
            )}
        </Column>
    );
}
