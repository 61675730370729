import { useMemo } from "react";

import {
    EvmLiteNft,
    useAbfFetches,
    useOptimisticEvmBsMetadataTransaction,
    useInitiateMultichainTransferMutation,
    useUserMultichainWallets,
    useActiveWallet,
    BsMetaUtil,
    useWalletAvailableAssets,
    useEscrowWithdrawTransaction,
    useActiveEscrow,
    WithdrawParams,
    useCompleteTransactionActionsMutation,
    CompleteTransactionActionArgs,
    BridgeTransactionInfo,
    useCreateEvmMpcWalletMutation,
    useCreateSolanaMpcWalletMutation,
    useTransferEvmAssetMutation,
    BridgeTransactionType,
    bridgeTransactionApi,
    evmAddressEqual,
    useUserCustodianPermissions,
    getWormholeContract,
    BridgeTransactionExpanded,
    useUserProfile,
    useActiveGroup
} from "@bridgesplit/abf-react";
import {
    findMaxElement,
    getReadableErrorMessage,
    LOADING_ERROR,
    Result,
    asyncForEach,
    TransactionStatus,
    sleep
} from "@bridgesplit/utils";
import { Bridge, EvmUtil } from "@bridgesplit/bs-protos";
import { BsMetadata, ChainId } from "@bridgesplit/abf-sdk";
import { TransactionResult } from "@bridgesplit/react";
import { allTransactionsSucceeded, useSilentTransactionSender } from "app/utils";
import { COPY } from "app/constants";

import { useMpcRegisterOrSignWithAlerts, useMpcSignIfPatExpired } from "../auth";
import { getChainMeta } from "../common";

export function useSetupMetaAndBridge() {
    const bridge = useBridgeFromEvm();
    const setupMeta = useSetupBsMetaForEvm();
    const signIfExpired = useMpcSignIfPatExpired();

    return async (chainId: ChainId, nfts: EvmLiteNft[]) => {
        const signRes = await signIfExpired();
        if (!signRes.isOk()) return signRes;

        const newBsMeta = await setupMeta(chainId, nfts);

        if (!newBsMeta.isOk()) return Result.err(newBsMeta);
        const bridgeParams: BridgeParams[] = newBsMeta.unwrap().map((metadata) => ({
            amount: 1, // all wormhole bridges are NFTs
            metadata,
            chainId
        }));

        const rawResults = await asyncForEach(bridgeParams, bridge);
        return Result.combine(rawResults);
    };
}

export function useBridgeFromSolToEvm() {
    const bridge = useBridgeFromSol();
    const withdraw = useWithdrawFromEscrowToWallet();
    const signIfExpired = useMpcSignIfPatExpired();

    return async (
        { chainId, assets }: { chainId: ChainId; assets: BsMetadata[] },
        options?: { onEscrowSuccess?: () => void }
    ) => {
        const signRes = await signIfExpired();
        if (!signRes.isOk()) return signRes;

        const withdrawToEscrow = await withdraw(assets.map(({ assetMint }) => ({ uiAmount: 1, mint: assetMint })));
        if (!allTransactionsSucceeded(withdrawToEscrow)) {
            return Result.errFromMessage(getReadableErrorMessage("withdraw from Loopscale escrow"));
        }
        options?.onEscrowSuccess?.();
        return await bridge(assets.map((metadata) => ({ amount: 1, metadata, chainId })));
    };
}

export function useBridgeRetries() {
    const completeBridgeClaim = useCompleteBridgeClaim();
    const bridgeFromEvm = useBridgeFromEvm();
    const bridgeFromSol = useBridgeFromSol();

    const { fetchBsMetadataByMints } = useAbfFetches();
    const signIfExpired = useMpcSignIfPatExpired();

    async function retryBridge({ bridgeTransactionInfo, bsMetadata, evmMetadata }: BridgeTransactionExpanded) {
        const signRes = await signIfExpired();
        if (!signRes.isOk()) return signRes;

        let metadata = bsMetadata ?? undefined;
        if (!metadata && evmMetadata) {
            const metadataRes = await fetchBsMetadataByMints([evmMetadata.solanaMint], true);
            metadata = metadataRes.data?.find((d) => d.assetMint === evmMetadata.solanaMint);
        }

        if (!metadata) return Result.errFromMessage("Unable to find details about this asset");
        const bridgeParams: BridgeParams = {
            amount: bridgeTransactionInfo.assetAmount,
            metadata,
            chainId: bridgeTransactionInfo.toChainId
        };

        if (bridgeTransactionInfo.fromChainId === ChainId.Solana) {
            return await bridgeFromSol([bridgeParams]);
        }
        return await bridgeFromEvm(bridgeParams);
    }

    async function retryClaim({ bridgeTransactionInfo }: BridgeTransactionInfo) {
        const signRes = await signIfExpired();
        if (!signRes.isOk()) return signRes;

        return await completeBridgeClaim({
            signature: bridgeTransactionInfo.transactionSignature,
            status: TransactionStatus.Confirmed,
            sourceChain: bridgeTransactionInfo.fromChainId
        });
    }

    return { retryClaim, retryBridge };
}

function useSetupBsMetaForEvm() {
    const { fetchBsMetadataByMints } = useAbfFetches();
    const send = useSilentTransactionSender();
    const setupBsMeta = useOptimisticEvmBsMetadataTransaction();

    const custodians = useUserCustodianPermissions();

    const contractToCustodian = useMemo(() => {
        return new Map((custodians ?? []).map((c) => [c.custodian.sourceContract, c.custodian.groupIdentifier]));
    }, [custodians]);

    return async (chainId: ChainId, nfts: EvmLiteNft[]): Promise<Result<BsMetadata[]>> => {
        const res = await fetchBsMetadataByMints(
            nfts.map((n) => n.solanaMint),
            true
        );

        if ("error" in res || !res.data) {
            return Result.errWithDebug(getReadableErrorMessage("find assets on Loopscale"), res);
        }
        const existingMetadata = new Set(res.data.map((r) => r.assetMint));
        const metadataToUpload = nfts.filter((m) => !existingMetadata.has(m.solanaMint));

        // all meta already created
        if (!metadataToUpload.length && res.data.length) return Result.ok(res.data);

        const nftMissingCustodian = metadataToUpload.find((c) => !contractToCustodian.get(c.contract));

        if (nftMissingCustodian) {
            return Result.errFromMessage(
                `${nftMissingCustodian.name} does not have a valid ${COPY.CUSTODIAN_TERM.toLowerCase()}`
            );
        }

        const txnResults = await send(
            setupBsMeta,
            metadataToUpload.map((nft) => ({
                sourceChain: chainId,
                assetOriginator: contractToCustodian.get(nft.contract) ?? "", // checked above
                evmAssetInfo: { tokenId: nft.tokenId, contract: nft.contract }
            })),
            { description: "Configuring assets for Loopscale", preventDispatch: true }
        );

        if (!allTransactionsSucceeded(txnResults))
            return Result.errFromMessage(getReadableErrorMessage("set up asset metadata"));

        // reduce tries due to webhook delays
        let tries = 0;
        while (tries < 5) {
            const metadataRes = await fetchBsMetadataByMints(
                nfts.map((n) => n.solanaMint),
                false
            );
            const meta = metadataRes.data ?? [];
            if (meta.length === nfts.length) {
                return Result.ok(meta);
            }
            await sleep(5_000);
            tries++;
        }

        return Result.errWithDebug(getReadableErrorMessage("find assets to bridge"), res);
    };
}

type BridgeParams = { amount: number; metadata: BsMetadata; chainId: ChainId };

function useBridgeFromEvm() {
    const [initiateTransfer] = useInitiateMultichainTransferMutation();
    const { data: wallets } = useUserMultichainWallets();
    const { solanaMpcWallet } = useActiveWallet();

    const { groupIdentifier } = useActiveGroup();

    return async function ({ amount, metadata, chainId }: BridgeParams): Promise<Result<void>> {
        const sourceChainInfo = BsMetaUtil.getChain(metadata);
        const meta = getChainMeta(chainId);
        const evmWallet = wallets?.find((w) => w.chainId === chainId);
        const wormholeNftBridgeContract = getWormholeContract(chainId)?.nftContract;
        if (!evmWallet || !solanaMpcWallet || !wormholeNftBridgeContract)
            return Result.errFromMessage(`Your account was not properly configured for ${meta.name} deposits`);

        if (!sourceChainInfo?.contract || !sourceChainInfo?.token_id || !amount || !groupIdentifier) {
            return Result.errFromMessage(LOADING_ERROR);
        }

        const args: Bridge.StartBridgeAssetMessage = {
            messageIdentifier: metadata.assetMint,
            sourceChain: chainId,
            recipientChain: ChainId.Solana,
            amount,
            bridgerWallet: evmWallet.walletPubkey,
            bridgeId: Bridge.BridgeId.WORMHOLE,
            bridgerRecipientWallet: solanaMpcWallet.wallet,
            userOrganization: groupIdentifier,
            assetInfo: {
                evmAssetInfo: {
                    contract: sourceChainInfo.contract,
                    tokenId: sourceChainInfo.token_id
                }
            },
            nativeTransactionHash: undefined // hash hasn't been sent until bridged
        };

        // should always send a single nft at a time
        const res = await initiateTransfer({ messages: [args] });
        if ("error" in res) return Result.errWithDebug(`Failed to bridge ${BsMetaUtil.getName(metadata)}`, res);
        return Result.ok();
    };
}

// Since user can withdraw escrowed assets to wallet before bridging
function useWithdrawFromEscrowToWallet() {
    const { solanaMpcWallet } = useActiveWallet();
    const walletAssets = useWalletAvailableAssets(solanaMpcWallet?.wallet, {
        includeStakedSol: false
    });

    const escrowWithdraw = useEscrowWithdrawTransaction();
    const { activeEscrow, escrowNonce } = useActiveEscrow();
    const { groupIdentifier } = useActiveGroup();

    const send = useSilentTransactionSender();

    return async (assets: { mint: string; uiAmount: number }[]): Promise<Result<TransactionResult[]>> => {
        // should never occur since assets uses the same reducer as user escrows
        if (!walletAssets || !solanaMpcWallet || !activeEscrow || !escrowNonce || !groupIdentifier)
            return Result.errFromMessage(LOADING_ERROR);

        const walletBalances = new Map(walletAssets.map((e) => [e.mint, e.amount]));

        const assetsToWithdraw: WithdrawParams = [];
        for (const { mint, uiAmount } of assets) {
            const walletBalance = walletBalances.get(mint) ?? 0;
            const amountToWithdraw = Math.max(uiAmount - walletBalance, 0);
            if (amountToWithdraw) {
                assetsToWithdraw.push({
                    receiver: solanaMpcWallet.wallet,
                    escrowAccount: activeEscrow,
                    escrowNonce,
                    mint,
                    organizationIdentifier: groupIdentifier,
                    amount: amountToWithdraw
                });
            }
        }

        // skip if already all withdrawn
        if (!assetsToWithdraw.length) return Result.ok([]);

        return await send(escrowWithdraw, assetsToWithdraw);
    };
}

function useCompleteBridgeClaim() {
    const [completeTransactionActions] = useCompleteTransactionActionsMutation();

    return async function (args: CompleteTransactionActionArgs) {
        const res = await completeTransactionActions(args);
        if ("error" in res) return Result.errWithDebug(`Failed to retry transaction`, res);
        return Result.ok();
    };
}

export function useCreateEvmWallet() {
    const registerOrSign = useMpcRegisterOrSignWithAlerts();
    const [createEvm] = useCreateEvmMpcWalletMutation();
    const { user } = useUserProfile();
    const { resetMeApi } = useAbfFetches();

    return async (chainId: ChainId) => {
        const metadata = getChainMeta(chainId);
        const signRes = await registerOrSign();
        if (!signRes.isOk()) return signRes;

        const res = await createEvm({
            userIdentifier: user.identifier,
            email: user.email,
            chainId: getChainMeta(chainId).chainId
        });
        if ("error" in res) return Result.errWithDebug(getReadableErrorMessage(`setup ${metadata.name} deposits`), res);
        resetMeApi();

        return Result.ok();
    };
}

export function useCreateSolanaWallet() {
    const registerOrSign = useMpcRegisterOrSignWithAlerts();
    const [createWallet] = useCreateSolanaMpcWalletMutation();
    const { fetchUserMe, resetMeApi } = useAbfFetches();
    const { groupIdentifier } = useActiveGroup();

    return async () => {
        const signRes = await registerOrSign();
        if (!signRes.isOk()) return signRes;

        const me = await fetchUserMe({}).unwrap();
        const previousMpc = me?.wallets.find((w) => w.groupIdentifier === groupIdentifier && !!w.mpcIdentifier);
        if (previousMpc) {
            return Result.ok();
        }
        const res = await createWallet({ isPrimary: false });

        resetMeApi();

        if ("error" in res)
            return Result.errWithDebug(getReadableErrorMessage("register for cross-chain deposits"), res);
        return Result.ok();
    };
}

function useBridgeFromSol() {
    const [initiateTransfer] = useInitiateMultichainTransferMutation();
    const { data: wallets } = useUserMultichainWallets();
    const { solanaMpcWallet } = useActiveWallet();
    const findOriginalAssetBridgeHash = useFindOriginalAssetBridgeHash();

    const { groupIdentifier } = useActiveGroup();

    async function getParams({
        amount,
        metadata,
        chainId
    }: BridgeParams): Promise<Result<Bridge.StartBridgeAssetMessage>> {
        const sourceChainInfo = BsMetaUtil.getChain(metadata);
        const assetSourceChain = sourceChainInfo?.chain_id;

        if (chainId !== assetSourceChain)
            return Result.errFromMessage(`${BsMetaUtil.getName(metadata)} is not from ${getChainMeta(chainId).name}`);

        const evmWallet = wallets?.find((w) => w.chainId === assetSourceChain);
        const wormholeNftBridgeContract = getWormholeContract(ChainId.Solana)?.nftContract;
        if (!evmWallet || !solanaMpcWallet || !wormholeNftBridgeContract)
            return Result.errFromMessage(`Your account was not properly configured for Solana transfers`);

        if (!assetSourceChain) {
            return Result.errFromMessage(`${BsMetaUtil.getName(metadata)} is not a valid cross-chain asset`);
        }

        if (!sourceChainInfo?.contract || !sourceChainInfo?.token_id || !amount || !groupIdentifier) {
            return Result.errFromMessage(LOADING_ERROR);
        }

        const nativeTransactionHash = await findOriginalAssetBridgeHash(
            sourceChainInfo.contract,
            sourceChainInfo.token_id
        );

        const args: Bridge.StartBridgeAssetMessage = {
            messageIdentifier: metadata.assetMint,
            nativeTransactionHash,
            sourceChain: ChainId.Solana,
            recipientChain: assetSourceChain,
            amount,
            bridgerWallet: solanaMpcWallet.wallet,
            bridgeId: Bridge.BridgeId.WORMHOLE,
            bridgerRecipientWallet: evmWallet.walletPubkey,
            userOrganization: groupIdentifier,
            assetInfo: {
                solAssetInfo: {
                    mint: metadata.assetMint
                }
            }
        };
        return Result.ok(args);
    }

    return async function (inputs: BridgeParams[]): Promise<Result<void>> {
        const inputGeneration = await Promise.all(inputs.map((input) => getParams(input)));
        const messagesRes = Result.combine(inputGeneration);
        if (!messagesRes.isOk()) return Result.err(messagesRes);

        const res = await initiateTransfer({ messages: messagesRes.unwrap() });
        const assetsDescription =
            inputs.length === 1 ? BsMetaUtil.getName(inputs[0].metadata) : `${inputs.length} assets`;
        if ("error" in res) return Result.errWithDebug(`Failed to bridge ${assetsDescription}`, res);
        return Result.ok();
    };
}

export function useTransferFromMpcToEvm() {
    const transfer = useTransferEvmAsset();

    return async ({
        recipientAddress,
        chainId,
        nfts
    }: {
        chainId: ChainId;
        nfts: EvmLiteNft[];
        recipientAddress: string;
    }) => {
        if (!recipientAddress) return Result.errFromMessage("Invalid address");
        const results = await asyncForEach(nfts, (nft) =>
            transfer({ uiAmount: 1, contract: nft.contract, tokenId: nft.tokenId, chainId, recipientAddress })
        );
        return Result.combine(results);
    };
}

type EvmTransferParams = {
    contract: string;
    tokenId?: string; // optional for erc20 transfers
    chainId: ChainId;
    uiAmount: number; // denominated in ethers (ex 0.1 = 0.1 MATIC)
    recipientAddress: string;
};
export function useTransferEvmAsset() {
    const [initiateTransfer] = useTransferEvmAssetMutation();
    const { data: wallets } = useUserMultichainWallets();

    const { groupIdentifier } = useActiveGroup();

    return async function ({
        uiAmount,
        contract,
        tokenId,
        chainId,
        recipientAddress
    }: EvmTransferParams): Promise<Result<void>> {
        const meta = getChainMeta(chainId);
        const evmWallet = wallets?.find((w) => w.chainId === chainId);
        const wormholeNftBridgeContract = getWormholeContract(ChainId.Solana)?.nftContract;
        if (!evmWallet || !wormholeNftBridgeContract)
            return Result.errFromMessage(`Your account was not properly configured for ${meta.name} transfers`);

        if (!contract || !uiAmount || !groupIdentifier) {
            return Result.errFromMessage(LOADING_ERROR);
        }

        const args: EvmUtil.TransferAssetMessage = {
            tokenContract: contract,
            tokenId,
            recipientAddress,
            dfnsSecrets: undefined,
            chain: chainId,
            amount: uiAmount.toString()
        };

        const res = await initiateTransfer(args);
        if ("error" in res) return Result.errWithDebug("Failed to transfer", res);
        return Result.ok();
    };
}

function useFindOriginalAssetBridgeHash() {
    const [fetchBridgeTransactions] = bridgeTransactionApi.endpoints.bridgeTransactions.useLazyQuery();

    // find the original bridge transaction so that tygris to search Wormhole using original bridge hash
    return async (contract: string, tokenId: string) => {
        const res = await fetchBridgeTransactions({ contracts: [contract], tokenIds: [tokenId] }, true);
        if (res.error) return undefined;
        const matchingTransfers = res.data?.filter(
            (bridge) =>
                evmAddressEqual(bridge.bridgeTransactionInfo.contract, contract) &&
                evmAddressEqual(bridge.bridgeTransactionInfo.tokenId, tokenId) &&
                bridge.bridgeTransactionInfo.transactionType === BridgeTransactionType.Bridge
        );
        if (!matchingTransfers?.length) return undefined;
        const latestBridge = findMaxElement(matchingTransfers, (t) => t.transactionInfo.lastUpdateTime);
        return latestBridge?.transactionInfo.transactionSignature;
    };
}
