import {
    BsMetaUtil,
    AbfPermissionForCustodian,
    useActiveEscrow,
    useRepayLoanTransaction,
    AbfLoanExpanded,
    useIsLoanEscrowBased,
    LoanRepaymentParam,
    findUnfilledEmptyLedgers,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { Text } from "@bridgesplit/ui";
import { MISSING_PARAM_ERROR, Result } from "@bridgesplit/utils";

import { AppButton } from "../../../common";
import { MakeRepaymentProps } from "./types";
import { useTransactionSender } from "app/utils";
import { getRepayPrincipalAndInterestParamsForLedger } from "./util";

export default function FillEmptyLedgers({ loanExpanded }: MakeRepaymentProps) {
    const symbol = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);
    const repay = useEmptyLedgersRepay();

    return (
        <>
            <Text color="error">
                You have payments that were received but not confirmed. Please try again. You will not be charged any{" "}
                {symbol}
            </Text>
            <AppButton
                isTransaction
                authentication={{
                    permission: AbfPermissionForCustodian.RepayLoan,
                    custodians: getLoanCustodianIdentifiers(loanExpanded)
                }}
                asyncCta={{ onClickWithResult: () => repay(loanExpanded) }}
                variant="contained"
                fullWidth
            >
                Confirm payments
            </AppButton>
        </>
    );
}

export function useEmptyLedgersRepay() {
    const sender = useTransactionSender();
    const { activeEscrow } = useActiveEscrow();
    const isLoanEscrowBased = useIsLoanEscrowBased();

    const repay = useRepayLoanTransaction();

    return async function singleRepay(loanExpanded: AbfLoanExpanded | undefined) {
        const unfilledLedgers = findUnfilledEmptyLedgers(loanExpanded);
        if (!unfilledLedgers || !loanExpanded || !activeEscrow) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const escrowNeeded = isLoanEscrowBased(loanExpanded);
        const payments: LoanRepaymentParam[] = unfilledLedgers
            .map((l) =>
                getRepayPrincipalAndInterestParamsForLedger({
                    ledgerAccount: l
                }).flat()
            ) // only repay principal
            .flat();

        return await sender(repay, {
            payments,
            loan: loanExpanded.loan,
            order: loanExpanded.order,
            repayerEscrowAccount: activeEscrow,
            escrowNeeded,
            fullRepay: false
        });
    };
}
