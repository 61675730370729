import { useState } from "react";

import { BsMetaUtil, LoopscalePointSource, useBsMetadataByMint, useOraclePrices } from "@bridgesplit/abf-react";
import { Column, Row, StatColumn, StatProps, Text, TextButton, TextVariant } from "@bridgesplit/ui";
import { PERCENT_DECIMALS, filterTruthy, formatDate, formatPercent } from "@bridgesplit/utils";
import { SwapHorizOutlined } from "@mui/icons-material";
import { TransactionTypes } from "app/types";
import { useLoopscalePoints } from "app/components/points/stats";
import { useTransactionFeeStat } from "app/components/transactions";

import { useMarketBorrowContext } from "./MarketBorrowContext";
import { useMarketContext } from "../common";
import { useScheduleDetails } from "./util";
import BorrowHealthStats from "./BorrowHealthStats";

const VARIANT: TextVariant = "body2";
export default function BorrowStats() {
    const scheduleDetails = useScheduleDetails();

    const { token } = useMarketContext();
    const {
        bestQuote,
        form: { collateralAmount, amount, collateralMint }
    } = useMarketBorrowContext();
    const { getOracle } = useOraclePrices([token?.assetMint]);

    const [showInterest, setShowInterest] = useState(false);
    const transactionFeeStat = useTransactionFeeStat({
        transactionType: TransactionTypes.MarketBorrow,
        variant: VARIANT
    });

    const usdPrice = getOracle(token?.assetMint)?.getUsdAmount(amount, token?.decimals);
    const collateralMetadata = useBsMetadataByMint(collateralMint);

    const pointsStat = useLoopscalePoints({
        actions: [LoopscalePointSource.Borrow],
        totalAmount: usdPrice,
        metadata: [token, collateralMetadata].filter(filterTruthy) ?? []
    });

    if (!scheduleDetails || !token || !amount || !collateralAmount) return null;

    const { loanEndDate, totalInterest } = scheduleDetails;

    const stats: StatProps[] = [
        pointsStat,
        {
            value: (
                <Row spacing={1}>
                    <Text variant={VARIANT}>
                        {showInterest
                            ? BsMetaUtil.formatAmount(token, totalInterest)
                            : formatPercent(bestQuote?.apy, { customDecimals: PERCENT_DECIMALS })}
                    </Text>
                    <TextButton variant={VARIANT} color="disabled" onClick={() => setShowInterest(!showInterest)}>
                        <SwapHorizOutlined />
                    </TextButton>
                </Row>
            ),
            caption: showInterest ? "Interest" : "APY",
            tooltip: showInterest
                ? `Total interest if repaid ${formatDate(loanEndDate, "relative")}`
                : "Your fixed borrowing rate for the entire loan duration"
        },
        {
            value: formatDate(loanEndDate),
            caption: "Rate fixed until"
        },
        transactionFeeStat
    ];

    return (
        <Column spacing={0.5}>
            <StatColumn captionVariant={VARIANT} variant={VARIANT} stats={stats} />
            <BorrowHealthStats variant={VARIANT} />
        </Column>
    );
}
