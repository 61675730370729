import { memo, useMemo } from "react";

import { Column, TagTextAlert } from "@bridgesplit/ui";
import { getLoanStrategyIdentifier, useWhirlpoolUpdatePositionTransaction } from "@bridgesplit/abf-react";
import { LOADING_ERROR, Result } from "@bridgesplit/utils";
import { GetUpdatePositionTxnArgs } from "@bridgesplit/abf-sdk";

import { AppButton } from "../../../../common";
import { useWhirlpoolOpenPositionBalances, useOpenPositionLtv } from "./util";
import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useTransactionSender } from "app/utils";

export default memo(function OpenPositionCta() {
    const { openPositionForm, poolData, openPositionQuote } = useWhirlpoolContext();
    const { insufficientBalanceMessage } = useWhirlpoolOpenPositionBalances();
    const updateWhirlpool = useUpdateWhirlpool();
    const { insufficientCollateral } = useOpenPositionLtv();

    const alertError = useMemo(() => {
        // don't show alert with no user input
        if (openPositionForm.status === undefined || !poolData) return undefined;
        if (insufficientBalanceMessage) return insufficientBalanceMessage;
        if (insufficientCollateral) return "Insufficient collateral for your loan debt";

        return undefined;
    }, [openPositionForm.status, poolData, insufficientBalanceMessage, insufficientCollateral]);

    const ctaError = useMemo(() => {
        if (!openPositionForm.tokenAAmount && !openPositionForm.tokenBAmount) return "Enter an amount";
        if (openPositionForm.tickLower === undefined || openPositionForm.tickUpper === undefined)
            return "Enter liquidity range";
        if (openPositionForm.status === undefined) return "No changes made";
        if (openPositionForm.status === "error") return "No quote found";
        if (!poolData || !openPositionQuote) return "Loading";
        return undefined;
    }, [
        openPositionForm.tokenAAmount,
        openPositionForm.tokenBAmount,
        openPositionForm.tickLower,
        openPositionForm.tickUpper,
        openPositionForm.status,
        poolData,
        openPositionQuote
    ]);

    return (
        <Column spacing={1}>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            <AppButton
                asyncCta={{ onClickWithResult: updateWhirlpool }}
                isTransaction
                color="secondary"
                disabled={!!ctaError || !!alertError}
            >
                {ctaError ?? "Modify position"}
            </AppButton>
        </Column>
    );
});

function useUpdateWhirlpool() {
    const { openPositionQuote, loanExpanded, reset } = useWhirlpoolContext();
    const send = useTransactionSender();
    const updatePosition = useWhirlpoolUpdatePositionTransaction();
    return async () => {
        if (!openPositionQuote) return Result.errFromMessage(LOADING_ERROR);

        const strategyIdentifier = getLoanStrategyIdentifier(loanExpanded);
        if (!strategyIdentifier) return Result.errFromMessage("Updates are not available for this loan");

        const params: GetUpdatePositionTxnArgs = {
            loan: loanExpanded.loan.address,
            strategyIdentifier,
            liquidityAmount: openPositionQuote.liquidity.toNumber(),
            tokenMaxA: openPositionQuote.maxTokenA.toNumber(),
            tokenMaxB: openPositionQuote.maxTokenB.toNumber(),
            tickLowerIndex: openPositionQuote.tickLowerIndex,
            tickUpperIndex: openPositionQuote.tickUpperIndex
        };

        return await send(updatePosition, params, { sendOptions: { onSuccess: reset } });
    };
}
