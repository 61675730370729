import {
    deserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransaction,
    NAPOLEON_API,
    TransactionsWithDataGenerateReturn,
    VersionedTransactionResponse
} from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    LoopWindTransactionInput,
    LoopUnwindTransactionInput,
    ROUTE_LOOP_UNWIND_TXN,
    ROUTE_LOOP_VAULT_SETUP_TXN,
    ROUTE_LOOP_WIND_TXN
} from "../types";
import { createAbfInstance } from "./auth";

export async function getLoopWindTransaction(
    authentication: AbfTransactionHeaders,
    arg: LoopWindTransactionInput
): Promise<TransactionsWithDataGenerateReturn<{ lockboxAddress: string }>> {
    const api = createAbfInstance(authentication, NAPOLEON_API);
    const response = await api.post<{ transaction: VersionedTransactionResponse; lockboxAddress: string }>(
        ROUTE_LOOP_WIND_TXN,
        arg
    );
    const data = response.data;

    const transaction = deserializeVersionedTransaction(data.transaction);
    return {
        transactions: [transaction],
        lockboxAddress: data.lockboxAddress
    };
}

export async function getLoopUnwindTransaction(
    authentication: AbfTransactionHeaders,
    arg: LoopUnwindTransactionInput
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            ROUTE_LOOP_UNWIND_TXN,
            arg
        )
    ];
}

export async function getLoopVaultSetupTransaction(
    authentication: AbfTransactionHeaders,
    arg: { loopVaultAddress: string }
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            `${ROUTE_LOOP_VAULT_SETUP_TXN}/${arg.loopVaultAddress}`,
            {}
        )
    ];
}
