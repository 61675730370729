import { useMemo, useEffect, useState, useCallback } from "react";

import { getUnixTs } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import {
    useCommunityPointMultipliersQuery,
    useCommunityRewardsQuery,
    usePointMultipliersQuery,
    usePointsTotalForRewardQuery,
    useRewardsQuery,
    useUserPointsQuery,
    useUserReferralInfoQuery,
    useUserReferralPointsQuery,
    useUserWhitelistInfoQuery
} from "../reducers";
import {
    CollateralInfo,
    ExternalPointSource,
    LoopscalePointSource,
    PointsInfo,
    PointsRewardType,
    UseRewardsCalculationProps,
    UserPointsCalculationInfo
} from "../types";
import { useSkipUnauthenticated, useUserMe } from "./auth";
import {
    buildCollateralPointsInfo,
    convertPointsRewardsResponseToPointMap,
    convertPointsRewardsToBasePointMap
} from "../utils/points";
import { BASE_PPS, BASE_REFFERAL_PPS, LOOPSCALE_POINTS_SOURCE_INFO, POINTS_SOURCE_INFO } from "../constants";

export function useSkipPoints() {
    return useSkipUnauthenticated("bearer");
}

export function useUserId() {
    const { data: user } = useUserMe();
    return user?.user.identifier ?? skipToken;
}

export function useRewards() {
    const { data, isLoading } = useRewardsQuery({ feVisible: true, activeOnly: true });

    const rewardToInfo = useMemoizedKeyMap(data, (t) => t.reward);

    function getRewardInfo(type: PointsRewardType) {
        return rewardToInfo?.get(type);
    }

    return { data, getRewardInfo, isLoading };
}

export function useUserBetaAccess() {
    const { data, ...query } = useUserMe();
    const isLoading = useMemo(
        () => !data || query.isLoading || query.isFetching,
        [data, query.isFetching, query.isLoading]
    );
    const isBorrower = !!data?.beta_borrow_access;
    const isLender = !!data?.beta_lend_access;
    const hasPermission = isBorrower || isLender;

    return { hasPermission, isBorrower, isLender, isLoading };
}

export function useUserReferral(options?: { skip?: boolean }) {
    const userId = useUserId();
    const skip = useSkipPoints();
    return useUserReferralInfoQuery(userId, {
        skip: skip || !!options?.skip
    });
}

export function useUserWhitelistRank() {
    const userId = useUserId();
    const skipIfUnauthenticated = useSkipPoints();
    const { data: rawData, ...reset } = useUserWhitelistInfoQuery(userId, { skip: skipIfUnauthenticated });

    const data = useMemo(() => {
        if (!rawData) return undefined;
        return { ...rawData, userRank: rawData.userRank || rawData.totalUsers };
    }, [rawData]);

    return { ...reset, data };
}

export function usePointMultipliers() {
    const userId = useUserId();
    const skipIfUnauthenticated = useSkipPoints();
    const { data, isLoading } = useCommunityPointMultipliersQuery(userId, { skip: skipIfUnauthenticated });

    const totalMultiplier = useMemo(() => {
        if (!data) return 1;
        return data.reduce((acc, curr) => acc * curr.multiplier, 1);
    }, [data]);

    return { data, totalMultiplier, isLoading };
}

export type UserPointsFunction = ReturnType<typeof useUserPoints>["getPointsAtTime"];
export function useUserPoints(options?: { skip?: number }) {
    // await for referral info or invite code endpoints before calling points to catch point creation endpoints
    const { hasPermission } = useUserBetaAccess();
    const { data: referrals } = useUserReferral({ skip: hasPermission });
    const accountCreated = hasPermission || !!referrals?.userReferralCode;
    const userId = useUserId();
    const skipIfUnauthenticated = useSkipPoints();

    const {
        data: basePoints,
        isLoading,
        isUninitialized
    } = useUserPointsQuery(userId, { skip: !accountCreated || skipIfUnauthenticated || !!options?.skip });

    const {
        data: referralPoints,
        isLoading: referralsLoading,
        isUninitialized: referralsUninitialized
    } = useUserReferralPointsQuery(userId, { skip: !accountCreated || skipIfUnauthenticated || !!options?.skip });

    const pointsData: UserPointsCalculationInfo | undefined = basePoints &&
        referralPoints && {
            basePoints,
            referralPoints
        };

    return {
        getPointsAtTime: (timestamp: number) => getPointsAtTime(pointsData, timestamp),
        isLoading: isUninitialized || isLoading || referralsLoading || referralsUninitialized
    };
}

function getPointsAtTime(userInfo: UserPointsCalculationInfo | undefined, now: number) {
    if (!userInfo?.basePoints || !userInfo?.referralPoints) {
        return undefined;
    }

    const { basePoints: rawBase, referralPoints: rawReferral } = userInfo;

    // Add null checks with default 0
    const basePPS = rawBase.pps ?? BASE_PPS;
    const referralPPS = rawReferral.pps ?? BASE_REFFERAL_PPS;

    const baseSecondsElapsed = Math.max(now - rawBase.timestamp, 0);
    const basePoints = rawBase.points + basePPS * baseSecondsElapsed;

    const referralSecondsElapsed = Math.max(now - rawReferral.timestamp, 0);
    const referralPoints = rawReferral.points + referralPPS * referralSecondsElapsed;

    const totalPoints = basePoints + referralPoints;

    return { basePoints, referralPoints, totalPoints };
}

export function useCurrentTime(pollingInterval = 1000) {
    const [now, setNow] = useState<number>(getUnixTs());

    useEffect(() => {
        if (!pollingInterval) return;
        const interval = setInterval(() => {
            setNow(getUnixTs());
        }, pollingInterval);

        return () => clearInterval(interval);
    }, [pollingInterval]);

    return { now };
}

export function useCommunityRewards() {
    const userId = useUserId();
    return useCommunityRewardsQuery(userId ?? skipToken);
}

export function usePointsSumForReward(pointsReward: PointsRewardType) {
    const { getRewardInfo, isLoading: rewardLoading } = useRewards();
    const userId = useUserId();
    const { data: totalMultiplier, isLoading: multiplierLoading } = usePointsTotalForRewardQuery(
        typeof userId === "string"
            ? {
                  pointsReward,
                  userId
              }
            : skipToken
    );

    const totalPoints = useMemo(() => {
        const rewardInfo = getRewardInfo(pointsReward);
        if (!rewardInfo || !totalMultiplier) return 0;
        return rewardInfo.pointsPerSecond * totalMultiplier;
    }, [getRewardInfo, pointsReward, totalMultiplier]);

    return { totalPoints, isLoading: rewardLoading || multiplierLoading };
}

function useCollateralPointSources() {
    const {
        data: multiplierData,
        isLoading: isMultiplierLoading,
        error: isMultiplierError
    } = usePointMultipliersQuery();

    const { data: rewards, isLoading: isRewardsLoading } = useRewards();

    const isLoading = isMultiplierLoading || isRewardsLoading;
    const error = isMultiplierError;

    const processedData = useMemo(() => {
        // Important: Remove isLoading from this check as it might cause unnecessary blocks
        if (!multiplierData || !rewards) {
            return {
                collateralInfo: undefined,
                pointsPerSecond: undefined,
                relativePointsPerSecond: undefined
            };
        }

        const result = {
            collateralInfo: buildCollateralPointsInfo(multiplierData, rewards),
            pointsPerSecond: convertPointsRewardsResponseToPointMap(rewards),
            relativePointsPerSecond: convertPointsRewardsToBasePointMap(rewards)
        };

        return result;
    }, [multiplierData, rewards]);

    return useMemo(
        () => ({
            ...processedData,
            isLoading,
            error
        }),
        [processedData, isLoading, error]
    );
}

export function useCollateralPointsSourcesUtil() {
    const { collateralInfo, pointsPerSecond, relativePointsPerSecond, isLoading, error } = useCollateralPointSources();

    const getPointsCallback = useCallback(
        (metadata: BsMetadata[] | undefined, pointSource: LoopscalePointSource) =>
            getNormalizedPointsByCollateralMint(collateralInfo, metadata, pointSource),
        [collateralInfo]
    );

    const getPointsPerSecondCallback = useCallback(
        (pointSource: LoopscalePointSource) => getPointsPerSecondByPointSource(pointSource, pointsPerSecond),
        [pointsPerSecond]
    );

    const getCheckMultiplierGreaterThanBase = useCallback(
        (pointSource: LoopscalePointSource[] | undefined, metadata: BsMetadata[] | undefined) =>
            hasAnyPointSourceMultiplier(collateralInfo, metadata, pointSource),
        [collateralInfo]
    );

    const getCollateralLoopscalePointSourceNonZero = useCallback(
        (metadata: BsMetadata): LoopscalePointSource[] => {
            const pointSourceMap = collateralInfo?.[metadata.assetMint]?.pointSourceToNormalizedPoints;

            if (!pointSourceMap) {
                return [];
            }

            return Object.entries(pointSourceMap)
                .filter(([_, value]) => value !== 0)
                .map(([key]) => Number(key) as LoopscalePointSource)
                .filter((value): value is LoopscalePointSource => Object.values(LoopscalePointSource).includes(value));
        },
        [collateralInfo]
    );

    return {
        pointsPerSecond,
        relativePointsPerSecond,
        collateralInfo,
        getPoints: getPointsCallback,
        getPointsPerSecondByPointSource: getPointsPerSecondCallback,
        getCheckMultiplierGreaterThanBase,
        getCollateralLoopscalePointSourceNonZero,
        isLoading,
        error
    };
}

export function getPointsPerSecondByPointSource(
    pointSource: LoopscalePointSource,
    pointsPerSecondMap: Record<LoopscalePointSource, number> | undefined
): number | undefined {
    if (!pointSource || !pointsPerSecondMap) return undefined;
    return pointsPerSecondMap[pointSource];
}

// Checks if a collateral mint has a specific point source type
export function getNormalizedPointsByCollateralMint(
    sources: Record<string, CollateralInfo> | undefined,
    metadata: BsMetadata[] | undefined,
    pointSource: LoopscalePointSource
): number[] {
    if (!sources || !metadata?.length) {
        return [];
    }

    return metadata.map((meta) => {
        if (!meta?.assetMint || !sources[meta.assetMint]) {
            return 0;
        }
        return sources[meta.assetMint].pointSourceToNormalizedPoints[pointSource] ?? 0;
    });
}

export function hasAnyPointSourceMultiplier(
    sources: Record<string, CollateralInfo> | undefined,
    metadata: BsMetadata[] | undefined,
    pointSources: LoopscalePointSource[] | undefined
): boolean {
    if (!sources || !metadata || !pointSources) {
        return false;
    }

    return metadata.some((m) =>
        pointSources.some((pointSource) => sources[m.assetMint]?.pointSourceToNormalizedPoints[pointSource] > 0)
    );
}

/*
  To follow rewards, this is the current intended behaviour of the described situation:

  Situation:
  - There is a USDC borrow multiplier
  - There is a SOL borrow multiplier
  - There is a SOL lend multiplier
  - Borrower is borrowing USDC against SOL

  Outcome:
  - The borrower will receive both USDC and SOL borrow rewards.
  - The lender will receive SOL lend rewards.

  We are trying to limit the collateral bonus / principal market combination cases to ensure reward economy is not over saturated.
*/

export function useRewardsCalculation({
    externalPointSources,
    loopscalePointSources,
    metadata,
    loopLeverage
}: UseRewardsCalculationProps): PointsInfo[] {
    const { collateralInfo, relativePointsPerSecond } = useCollateralPointsSourcesUtil();

    // Create all external reward PointInfos
    const externalRewards = useMemo(() => {
        if (!Array.isArray(externalPointSources)) return [];

        return externalPointSources
            .filter((point) => point !== ExternalPointSource.Loopscale && point !== ExternalPointSource.LoopscaleHidden)
            .map((point) => ({
                ...POINTS_SOURCE_INFO[point],
                multiplier: loopLeverage ?? 0
            }));
    }, [externalPointSources, loopLeverage]);

    // Create all loopscale action points maps
    const loopscaleRewards = useMemo(() => {
        if (
            !Array.isArray(externalPointSources) ||
            !Array.isArray(loopscalePointSources) ||
            !Array.isArray(metadata) ||
            !relativePointsPerSecond ||
            !collateralInfo ||
            !externalPointSources.some(
                (point) => point === ExternalPointSource.Loopscale || point === ExternalPointSource.LoopscaleHidden
            )
        ) {
            return [];
        }

        return loopscalePointSources
            .filter((p) => Object.values(LoopscalePointSource).includes(p))
            .map((p) => {
                const totalCollateralMultiplier = metadata
                    .filter((m) => m?.assetMint && collateralInfo[m.assetMint]?.pointSourceToNormalizedPoints)
                    .map(
                        (m) => collateralInfo[m.assetMint].pointSourceToNormalizedPoints[p] / relativePointsPerSecond[p]
                    )
                    .filter((points) => typeof points === "number" && !isNaN(points));

                const collateralMultiplierProduct =
                    totalCollateralMultiplier.length > 0
                        ? totalCollateralMultiplier.reduce((acc, curr) => acc * curr, 1)
                        : 0;

                return {
                    ...LOOPSCALE_POINTS_SOURCE_INFO[p],
                    multiplier: collateralMultiplierProduct
                        ? collateralMultiplierProduct * relativePointsPerSecond[p]
                        : relativePointsPerSecond[p]
                };
            });
    }, [externalPointSources, relativePointsPerSecond, collateralInfo, loopscalePointSources, metadata]);

    return [...externalRewards, ...loopscaleRewards];
}

export function useCollateralMultipliers(
    metadata?: BsMetadata[],
    loopscalePointSources?: LoopscalePointSource[],
    hideZeroMultipliers = false
): PointsInfo[] {
    const { collateralInfo, relativePointsPerSecond } = useCollateralPointsSourcesUtil();

    return useMemo(() => {
        if (
            !Array.isArray(loopscalePointSources) ||
            !loopscalePointSources.length ||
            !Array.isArray(metadata) ||
            !metadata.length ||
            !collateralInfo ||
            !relativePointsPerSecond
        ) {
            return [];
        }

        // Process each metadata item
        return metadata.flatMap((meta) => {
            if (!meta?.assetMint) return [];

            return loopscalePointSources
                .map((pointSource) => {
                    if (!pointSource) return undefined;

                    const normalizedPoints = collateralInfo[meta.assetMint]?.pointSourceToNormalizedPoints[pointSource];

                    // Skip if either multiplier is 0 or undefined
                    if (!normalizedPoints) {
                        return undefined;
                    }

                    const finalMultiplier = normalizedPoints ? normalizedPoints : relativePointsPerSecond[pointSource];

                    // Skip zero multipliers if requested
                    if (hideZeroMultipliers && finalMultiplier === 0) {
                        return undefined;
                    }

                    return {
                        externalPointSource: ExternalPointSource.Loopscale,
                        multiplier: finalMultiplier,
                        caption: `${LOOPSCALE_POINTS_SOURCE_INFO[pointSource].caption}`
                    } as PointsInfo;
                })
                .filter((multiplier): multiplier is PointsInfo => multiplier !== undefined);
        });
    }, [metadata, loopscalePointSources, collateralInfo, relativePointsPerSecond, hideZeroMultipliers]);
}
