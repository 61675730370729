import { DialogWrapper, Text } from "@bridgesplit/ui";
import { ChainId } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";

import { EvmBalance, SelectChain } from "../wormhole";
import EvmWithdrawToWallet from "./withdraw/EvmWithdrawToWallet";
import { WalletRequiredWrapper } from "../transactions";

export default function EvmWithdrawToWalletDialog() {
    const { getData } = useAppDialog();

    const data = getData(AppDialog.EvmWithdrawToWallet);

    const chain = data?.chain ?? ChainId.Solana;

    return (
        <DialogWrapper>
            <SelectChain.Wrapper>
                <EvmBalance.Wrapper chain={chain}>
                    <WalletRequiredWrapper>
                        <Text variant="h3">Withdraw</Text>

                        <EvmWithdrawToWallet selected={new Set(data?.mints ?? [])} chain={chain} />
                    </WalletRequiredWrapper>
                </EvmBalance.Wrapper>
            </SelectChain.Wrapper>
        </DialogWrapper>
    );
}
