import {
    useEscrowWithdrawTransaction,
    useActiveEscrow,
    useActiveWallet,
    useEvmNftsByOwnerQuery,
    useUserCustodianPermissions,
    cavemanApi,
    useUserChainWallet,
    useActiveGroup
} from "@bridgesplit/abf-react";
import { BsMetadata, ChainId } from "@bridgesplit/abf-sdk";
import { Result, LOADING_ERROR, NullableRecord } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TrackTransactionEvent } from "app/types";

import { useTransactionSender } from "app/utils";

export interface WithdrawalParams {
    receiver: string;
    mint: string;
    amount: number;
    escrowAccount: string;
    escrowNonce: string;
    organizationIdentifier: string;
    stakeAccount: string | undefined;
}
export function useEscrowWithdraw() {
    const { activeEscrow, escrowNonce } = useActiveEscrow();
    const { activeWallet } = useActiveWallet();
    const send = useTransactionSender();
    const withdraw = useEscrowWithdrawTransaction();
    const { groupIdentifier } = useActiveGroup();

    return async function ({
        wallet,
        withdraws
    }: NullableRecord<{
        wallet: string;
        withdraws: { uiAmount: number; metadata: BsMetadata; stakeAccount: string | undefined }[];
    }>) {
        if (!activeEscrow || !activeWallet || !withdraws || !wallet || !escrowNonce || !groupIdentifier)
            return Result.errFromMessage(LOADING_ERROR);

        const params: WithdrawalParams[] = withdraws?.map((d) => ({
            receiver: wallet,
            mint: d.metadata.assetMint,
            amount: d.uiAmount,
            escrowAccount: activeEscrow,
            escrowNonce,
            organizationIdentifier: groupIdentifier,
            stakeAccount: d.stakeAccount
        }));

        return await send(withdraw, params, {
            mixpanelEvent: { key: TrackTransactionEvent.EscrowWithdraw, params: { params } }
        });
    };
}

export function useUserEvmNfts(chainId: ChainId, options?: { pollingInterval?: number; skip?: boolean }) {
    const custodians = useUserCustodianPermissions();
    const { wallet } = useUserChainWallet(chainId);
    const params = wallet ? { owner: wallet, sourceChain: chainId, includeSpam: true } : undefined;
    const {
        data: nfts,
        isFetching,
        isLoading: nftsLoading
    } = useEvmNftsByOwnerQuery(params ?? skipToken, {
        skip: !wallet || options?.skip,
        pollingInterval: options?.pollingInterval
    });
    const [trigger] = cavemanApi.endpoints.evmNftsByOwner.useLazyQuery();

    const isLoading = nftsLoading || !custodians;

    const validContracts = new Set(custodians?.map((c) => c.custodian.sourceContract) ?? []);

    const data = isLoading ? undefined : nfts?.filter((n) => validContracts.has(n.contract));

    return { data, isFetching, isLoading, reset: async () => params && trigger(params) };
}
