import {
    DEFAULT_PUBLIC_KEY,
    WRAPPED_SOL_MINT,
    fetchAndDeserializeLegacyTransaction,
    fetchAndDeserializeLegacyTransactions,
    fetchAndDeserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransactions
} from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    DEPOSIT_ENDPOINT,
    DEPOSIT_STAKED_SOL_ENDPOINT,
    DepositAssetArgs,
    ESCROW_EDIT_ENDPOINT,
    ESCROW_TRANSFER_ENDPOINT,
    EditEscrowArgs,
    EscrowTransferAssetArgs,
    INSTANT_UNSTAKE_TXN_ROUTE,
    StakedSolAssetArgs,
    SPL_TRANSFER_ENDPOINT,
    TransferAssetArgs,
    UnstakeRequestWithRoute,
    WITHDRAW_ENDPOINT,
    WITHDRAW_STAKED_SOL_ENDPOINT,
    WithdrawAssetArgs,
    TRANSFER_SA_TXN_ENDPOINT,
    SplitStakeIxParams
} from "../types";
import { createAbfInstance } from "./auth";

export async function getDepositAssetTransaction(
    authentication: AbfTransactionHeaders,
    args: DepositAssetArgs[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeLegacyTransaction(createAbfInstance(authentication), DEPOSIT_ENDPOINT, arg)
        )
    );
}

export async function getEditEscrowTransaction(authentication: AbfTransactionHeaders, args: EditEscrowArgs[]) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), ESCROW_EDIT_ENDPOINT, arg)
        )
    );
}

export async function getWithdrawAssetTransaction(authentication: AbfTransactionHeaders, args: WithdrawAssetArgs[]) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), WITHDRAW_ENDPOINT, arg)
        )
    );
}

export async function getDepositStakedSolAssetTransactions(
    authentication: AbfTransactionHeaders,
    args: StakedSolAssetArgs
) {
    return await fetchAndDeserializeLegacyTransactions(
        createAbfInstance(authentication),
        DEPOSIT_STAKED_SOL_ENDPOINT,
        args
    );
}

export async function getWithdrawStakedSolAssetTransactions(
    authentication: AbfTransactionHeaders,
    args: StakedSolAssetArgs
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication),
        WITHDRAW_STAKED_SOL_ENDPOINT,
        args
    );
}
export async function getWalletTransferStakedSolTransactions(
    authentication: AbfTransactionHeaders,
    args: SplitStakeIxParams[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeLegacyTransaction(createAbfInstance(authentication), TRANSFER_SA_TXN_ENDPOINT, arg)
        )
    );
}

export async function getInstantUnstakeTransaction(
    authentication: AbfTransactionHeaders,
    args: UnstakeRequestWithRoute[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), INSTANT_UNSTAKE_TXN_ROUTE, arg)
        )
    );
}

export async function getEscrowTransferAssetTransaction(
    authentication: AbfTransactionHeaders,
    args: EscrowTransferAssetArgs[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeVersionedTransaction(createAbfInstance(authentication), ESCROW_TRANSFER_ENDPOINT, arg)
        )
    );
}

export async function getSplTransferTransaction(
    authentication: AbfTransactionHeaders,
    args: TransferAssetArgs[]
) {
    return await Promise.all(
        args.map((arg) =>
            fetchAndDeserializeLegacyTransaction(createAbfInstance(authentication), SPL_TRANSFER_ENDPOINT, {
                ...arg,
                mint: arg.mint === WRAPPED_SOL_MINT ? DEFAULT_PUBLIC_KEY : arg.mint
            })
        )
    );
}
