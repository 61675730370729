import { BsMetadata } from "@bridgesplit/abf-sdk";
import { IS_SANDBOX } from "@bridgesplit/utils";

export interface RewardsFilter {
    feVisible: boolean;
    activeOnly: boolean;
}

export interface UserPoints {
    points: number;
    pps: number;
    timestamp: number;
}

export interface UserPointsCalculationInfo {
    basePoints: UserPoints;
    referralPoints: UserPoints;
}

export interface UserWaitlistInfo {
    userRank: number | null; // null if user not on WL
    totalUsers: number;
}

export interface PointMultiplier {
    reward: LoopscalePointSource;
    multiplier: number;
    mint: string;
    name: string;
}

export enum PointsRewardType {
    SignUp = 0,
    BetaSignup = 1,
    FollowTwitter = 2,
    JoinDiscord = 3,
    CommunityEngagement = 4,
    AcceptReferral = 7
}

export interface PointsReward {
    reward: RewardValue;
    rewardName: string;
    pointsPerSecond: number;
    startTime: number;
    endTime: number | null;
    feRewardLabel: string;
    feVisible: boolean;
    feDisabled: boolean;
    feUrl: string;
    feContinuous: boolean;
}

export interface UserPointsReward {
    id: number;
    userIdentifier: string;
    reward: PointsRewardType;
    startTime: number;
    endTime: number | null;
    multiplier: number;
}

export interface CommunityRewardInfo {
    reward: PointsReward;
    communityPointsReward: CommunityPointsReward;
    userPointsReward: UserPointsReward | null;
}

export enum CommunityMultiplierType {
    Ovols = 0
}

export interface CommunityMultiplier {
    communityMultiplier: CommunityMultiplierType;
    multiplier: number;
}

export interface CommunityPointsReward {
    reward: PointsRewardType;
    mint: string;
    communityRewardType: CommunityRewardsType;
    threshold: number;
    rewardImage: string;
    bonusThreshold: number;
    bonusAmount: number;
}

export interface CommunityRewardClaim {
    reward: number;
    userIdentifier: string;
    attemptedClaim: boolean;
}

export enum CommunityRewardsType {
    Token = 0,
    Nft = 1,
    OrcaLp = 2
}

export enum ExternalPointSource {
    MarginFi = 0,
    Loopscale = 1,
    Jupiter = 2,
    Meteora = 3,
    HubSol = 4,
    Solayer = 5,
    Adrastea = 6,
    LoopscaleHidden = 7
}

export type RewardValue = PointsRewardType | LoopscalePointSource;

export enum LoopscalePointSource {
    Borrow = IS_SANDBOX ? 17 : 35,
    Lend = IS_SANDBOX ? 18 : 36,
    IdleCap = IS_SANDBOX ? 19 : 37
}

export interface CollateralPointsData {
    eligibleMultipliers: LoopscalePointSource[];
    showRewards: boolean;
    getAdditionalPoints: (pointSources: LoopscalePointSource[]) => number;
}

export interface CollateralInfo {
    pointSourceToNormalizedPoints: Record<LoopscalePointSource, number>; // PointSource (Lend : 4x)
    name: string;
}

export interface UseRewardsCalculationProps {
    externalPointSources: ExternalPointSource[];
    loopscalePointSources?: LoopscalePointSource[];
    metadata?: BsMetadata[];
    loopLeverage?: number;
}

export interface TokenSourceData {
    eligibleMultipliers: LoopscalePointSource[];
    showRewards: boolean;
}

export interface PointsInfo {
    externalPointSource: ExternalPointSource;
    multiplier: number | undefined;
    caption: string;
}
export type PointsInfoDetails = Omit<PointsInfo, "multiplier">;

export enum LabelVisibility {
    Hidden = "hidden", // Hides entire component
    ShowBasic = "basic", // Shows just text
    ShowFull = "full" // Shows text + rewards icon
}
