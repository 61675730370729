import { useCallback, useEffect, useMemo, useState } from "react";

import {
    BORDER_RADIUS_SM,
    Button,
    Column,
    FormInput,
    IconButton,
    Row,
    StatColumn,
    TagTextAlert,
    Text,
    ToggleButtons,
    TooltipText,
    useAppPalette
} from "@bridgesplit/ui";
import { formatPercent, percentUiToDecimals } from "@bridgesplit/utils";
import { BsMetaUtil, MAX_TICK_INDEX, MIN_TICK_INDEX } from "@bridgesplit/abf-react";
import debounce from "lodash.debounce";
import { PriceRangeStatus, YieldToggle } from "app/components/common";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";

import { useWhirlpoolContext } from "../WhirlpoolContext";
import {
    calculateDepositRatios,
    flipPriceIfNeeded,
    getPriceFromTick,
    getTickFromPrice,
    useFormatWhirlpoolPercentChangeFromCurrent,
    useWhirlpoolFormatPrice
} from "../util";
import { useOpenPositionDepositRatio, useOpenPositionYieldData } from "./util";
import { PRICE_RANGE_DECIMALS } from "../common";
import { OpenPositionForm } from "../types";

enum Mode {
    Custom = 0,
    Full = 1
}

export default function OpenPositionPriceRange() {
    const { openPositionForm, setOpenPositionForm, positionData } = useWhirlpoolContext();
    const updateAmountsIfNeeded = useUpdateAmountsIfNeeded();

    const mode =
        openPositionForm.tickLower === MIN_TICK_INDEX && openPositionForm.tickUpper === MAX_TICK_INDEX
            ? Mode.Full
            : Mode.Custom;

    return (
        <>
            <ToggleButtons
                value={mode}
                options={[
                    { value: Mode.Full, label: "Full" },
                    { value: Mode.Custom, label: "Custom" }
                ]}
                setValue={(mode) => {
                    const wasOriginallyFullRange =
                        positionData?.tickLowerIndex === MIN_TICK_INDEX &&
                        positionData?.tickUpperIndex === MAX_TICK_INDEX;

                    const { tickLower, tickUpper } = (() => {
                        if (mode === Mode.Full) return { tickLower: MIN_TICK_INDEX, tickUpper: MAX_TICK_INDEX };
                        if (mode === Mode.Custom && wasOriginallyFullRange)
                            return { tickLower: undefined, tickUpper: undefined };
                        return { tickLower: positionData?.tickLowerIndex, tickUpper: positionData?.tickUpperIndex };
                    })();

                    setOpenPositionForm((prev) =>
                        updateAmountsIfNeeded({
                            ...prev,
                            tickLower,
                            tickUpper,
                            status: "refetch-needed"
                        })
                    );
                }}
            />
            {mode === Mode.Custom && (
                <Column spacing={1}>
                    <Edit />
                    <Buttons />
                </Column>
            )}
            <Error />

            <Stats />
        </>
    );
}

function Edit() {
    const { openPositionForm, setOpenPositionForm, isFlippedPrice, whirlpoolPosition, poolData } =
        useWhirlpoolContext();
    const [localPrice, setLocalPrice] = useState<number | undefined>(undefined);
    const [focused, setFocused] = useState<"tickLower" | "tickUpper" | null>(null);
    const updateAmountsIfNeeded = useUpdateAmountsIfNeeded();

    const getFormPrice = useCallback(
        (property: "tickLower" | "tickUpper") => {
            const formValue = openPositionForm[property];
            if (formValue === undefined) return undefined;
            return flipPriceIfNeeded(getPriceFromTick(formValue, whirlpoolPosition), isFlippedPrice);
        },
        [openPositionForm, whirlpoolPosition, isFlippedPrice]
    );

    const setTick = useCallback(
        (price: number | undefined, property: "tickLower" | "tickUpper") => {
            const flippedPrice = flipPriceIfNeeded(price, isFlippedPrice);
            const tick = flippedPrice ? getTickFromPrice(flippedPrice, whirlpoolPosition) : undefined;
            setOpenPositionForm((prev) =>
                updateAmountsIfNeeded({
                    ...prev,
                    [property]: tick,
                    status: "refetch-needed"
                })
            );
        },
        [isFlippedPrice, whirlpoolPosition, setOpenPositionForm, updateAmountsIfNeeded]
    );

    const formatPercentChange = useFormatWhirlpoolPercentChangeFromCurrent();

    // inputs are flipped if price is flipped
    const inputs: [string, "tickLower" | "tickUpper"][] = isFlippedPrice
        ? [
              ["Min price", "tickUpper"],
              ["Max price", "tickLower"]
          ]
        : [
              ["Min price", "tickLower"],
              ["Max price", "tickUpper"]
          ];

    const setTickDebounced = useMemo(() => debounce(setTick, 400), [setTick]);

    const updateTick = useCallback(
        (change: number, property: "tickLower" | "tickUpper") => {
            if (!poolData) return;
            setOpenPositionForm((prev) => {
                const prevTick = prev[property];
                if (prevTick === undefined) return prev;
                const changeTick = change * (poolData.tickSpacing ?? 0);
                return updateAmountsIfNeeded({
                    ...prev,
                    [property]: prevTick + changeTick,
                    status: "refetch-needed"
                });
            });
        },
        [poolData, setOpenPositionForm, updateAmountsIfNeeded]
    );

    useEffect(() => {
        return () => {
            setTickDebounced.cancel();
        };
    }, [setTickDebounced]);

    const arrows: [JSX.Element, number][] = isFlippedPrice
        ? [
              [<AddOutlined />, -1],
              [<RemoveOutlined />, 1]
          ]
        : [
              [<AddOutlined />, 1],
              [<RemoveOutlined />, -1]
          ];

    return (
        <Row spacing={1}>
            {inputs.map(([label, property]) => {
                const isFocused = focused === property;
                const value = isFocused ? localPrice : getFormPrice(property);

                return (
                    <Column spacing={0.5} key={label + property} color="body">
                        <Row spaceBetween>
                            <Text color="caption" variant="body2">
                                {label}
                            </Text>
                            {poolData && (
                                <TooltipText helpText="Percent change from current" color="caption" variant="body2">
                                    {formatPercentChange(openPositionForm[property], poolData?.tickCurrentIndex)}
                                </TooltipText>
                            )}
                        </Row>
                        <FormInput
                            sx={{ ".MuiOutlinedInput-root": { pr: 1 } }}
                            inputHeight={50}
                            InputProps={{
                                endAdornment: (
                                    <Column spacing={0.5}>
                                        {arrows.map(([icon, change]) => (
                                            <IconButton
                                                sx={{ p: 0.25, borderRadius: BORDER_RADIUS_SM }}
                                                textVariant="caption"
                                                jsxIcon={icon}
                                                onClick={() => updateTick(change, property)}
                                            />
                                        ))}
                                    </Column>
                                )
                            }}
                            onFocus={() => {
                                setFocused(property);
                                setLocalPrice(getFormPrice(property));
                            }}
                            onBlur={() => setFocused(null)}
                            decimals={PRICE_RANGE_DECIMALS}
                            placeholder="0"
                            value={value}
                            setValue={(newValue) => {
                                // prevent unnecessary re-renders
                                if (!isFocused) return;
                                setLocalPrice(newValue);
                                setTickDebounced(newValue, property);
                            }}
                            variant="number"
                        />
                    </Column>
                );
            })}
        </Row>
    );
}

const PERCENT_DIFFS = [1, 5, 10];
function Buttons() {
    const { openPositionForm, setOpenPositionForm, positionData, poolData, whirlpoolPosition } = useWhirlpoolContext();
    const { textSecondary, hoverBackground } = useAppPalette();
    const updateAmountsIfNeeded = useUpdateAmountsIfNeeded();
    const tickLower = positionData?.tickLowerIndex ?? MIN_TICK_INDEX;
    const tickUpper = positionData?.tickUpperIndex ?? MAX_TICK_INDEX;

    const options: [string, number, number][] = [["Reset", tickLower, tickUpper]];

    PERCENT_DIFFS.forEach((percentUi) => {
        const percentDecimals = percentUiToDecimals(percentUi);

        const currentPrice = getPriceFromTick(poolData?.tickCurrentIndex ?? 0, whirlpoolPosition);

        const lowerPrice = currentPrice * (1 - percentDecimals);
        const upperPrice = currentPrice * (1 + percentDecimals);

        const subtract = getTickFromPrice(lowerPrice, whirlpoolPosition);
        const add = getTickFromPrice(upperPrice, whirlpoolPosition);

        const tickLower = Math.min(add, subtract);
        const tickUpper = Math.max(add, subtract);

        options.push([`±  ${formatPercent(percentUi, { skipMultiplication: true })}`, tickLower, tickUpper]);
    });

    return (
        <Row spacing={1}>
            {options.map(([label, tickLower, tickUpper]) => {
                const isSelected =
                    openPositionForm.tickLower === tickLower &&
                    openPositionForm.tickUpper === tickUpper &&
                    label !== "Reset";
                return (
                    <Button
                        key={label}
                        variant="outlined"
                        textProps={{ variant: "body2", sx: { color: isSelected ? undefined : textSecondary } }}
                        sx={{ background: isSelected ? hoverBackground : undefined }}
                        height={30}
                        fullWidth
                        onClick={() =>
                            setOpenPositionForm((prev) =>
                                updateAmountsIfNeeded({
                                    ...prev,
                                    tickLower,
                                    tickUpper,
                                    status: "refetch-needed"
                                })
                            )
                        }
                    >
                        {label}
                    </Button>
                );
            })}
        </Row>
    );
}

const variant = "body2";
function Stats() {
    const { poolData, whirlpoolPosition, openPositionForm } = useWhirlpoolContext();
    const depositRatio = useOpenPositionDepositRatio();
    const yieldData = useOpenPositionYieldData();
    const formatPrice = useWhirlpoolFormatPrice();

    if (
        openPositionForm.tickLower === undefined ||
        openPositionForm.tickUpper === undefined ||
        !depositRatio ||
        openPositionForm.status === "error"
    )
        return null;

    return (
        <StatColumn
            loading={!yieldData || openPositionForm.status === "refetching"}
            variant={variant}
            stats={[
                {
                    caption: "Estimated yield",
                    value: yieldData ? <YieldToggle variant={variant} yieldData={yieldData} /> : undefined
                },

                {
                    caption: "Deposit ratio",
                    value: `${formatPercent(depositRatio?.ratioA)} ${BsMetaUtil.getSymbol(
                        whirlpoolPosition.tokenA.metadata
                    )} / ${formatPercent(depositRatio?.ratioB)} ${BsMetaUtil.getSymbol(
                        whirlpoolPosition.tokenB.metadata
                    )}`
                },
                {
                    caption: "Current price",
                    value: poolData ? (
                        <Row spacing={0.5}>
                            <PriceRangeStatus
                                tickCurrentIndex={poolData?.tickCurrentIndex}
                                tickLower={openPositionForm.tickLower}
                                tickUpper={openPositionForm.tickUpper}
                                variant={variant}
                            />
                            <Text variant={variant}>{formatPrice(poolData?.tickCurrentIndex)}</Text>
                        </Row>
                    ) : undefined
                }
            ]}
        />
    );
}

function Error() {
    const { openPositionForm } = useWhirlpoolContext();
    if (openPositionForm.status !== "error") return null;
    return (
        <TagTextAlert icon="warning" color="error">
            Invalid price range
        </TagTextAlert>
    );
}

export function useInitialWhirlpoolRatio() {
    const { whirlpoolPosition, poolData, positionData } = useWhirlpoolContext();
    return useMemo(() => {
        if (!poolData || !positionData) return undefined;
        return calculateDepositRatios(
            poolData?.sqrtPrice,
            whirlpoolPosition,
            positionData.tickLowerIndex,
            positionData.tickUpperIndex
        );
    }, [poolData, whirlpoolPosition, positionData]);
}

function useUpdateAmountsIfNeeded() {
    const { whirlpoolPosition, poolData } = useWhirlpoolContext();
    return useCallback(
        (prev: OpenPositionForm) => {
            // if amount has been adjusted, don't override
            if (prev.side !== undefined || !poolData || prev.tickLower === undefined || prev.tickUpper === undefined)
                return prev;

            const ratio = calculateDepositRatios(
                poolData?.sqrtPrice,
                whirlpoolPosition,
                prev.tickLower,
                prev.tickUpper
            );

            if (!ratio) return prev;

            // otherwise, user updated price range without amounts and expects USD amount to remain the same
            const originalUsdValue = whirlpoolPosition.totalPrice;

            const tokenAAmountUsd = originalUsdValue * ratio.ratioA;
            const tokenBAmountUsd = originalUsdValue * ratio.ratioB;

            return {
                ...prev,
                tokenAAmount: tokenAAmountUsd / whirlpoolPosition.tokenA.usdPrice,
                tokenBAmount: tokenBAmountUsd / whirlpoolPosition.tokenB.usdPrice
            };
        },
        [whirlpoolPosition, poolData]
    );
}
