import { ChangeEvent, useState } from "react";

import {
    useGroup,
    AbfCreateUserGroupParams,
    useUpdateGroupMutation,
    editUser,
    AbfGroupType,
    updateAvatarFromFile,
    useAbfFetches,
    getAvatarFromName,
    useUserProfile,
    useUserIsPrime
} from "@bridgesplit/abf-react";
import {
    Column,
    FormDebouncedStringInput,
    FormInput,
    IconWithBackground,
    Image,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import { Result, doNothing } from "@bridgesplit/utils";
import { mutationIntoResult, useAsyncResultHandler } from "@bridgesplit/react";
import { Badge, Stack } from "@mui/material";
import { Edit, LockOutlined } from "@mui/icons-material";

import { useSignedTransactionSender } from "app/utils";

export default function AccountEditGroup({ isOrganizationAdmin }: { isOrganizationAdmin: boolean }) {
    const { user } = useUserProfile();
    const { isPrime } = useUserIsPrime();

    return (
        <Column spacing={3}>
            {isPrime && <Avatar isOrganizationAdmin={isOrganizationAdmin} />}
            {isPrime && <NameInput isOrganizationAdmin={isOrganizationAdmin} />}

            <FormInput
                adornment={
                    <Text color="disabled">
                        <LockOutlined />
                    </Text>
                }
                label="Email"
                variant="string"
                disabled
                value={user.email || "Not registered"}
                setValue={doNothing}
            />
        </Column>
    );
}

function NameInput({ isOrganizationAdmin }: { isOrganizationAdmin: boolean }) {
    const { groups, activeGroup } = useGroup();
    const { user } = useUserProfile();

    const [name, setName] = useState<string>("");
    const [updateGroupQuery] = useUpdateGroupMutation();
    const { handler, isLoading } = useAsyncResultHandler();
    const { fetchUserMe } = useAbfFetches();
    const send = useSignedTransactionSender();

    async function updateGroup(params: AbfCreateUserGroupParams) {
        return await mutationIntoResult(updateGroupQuery, params, "editing account");
    }

    const personalAccount = groups?.find((g) => g.group?.groupType === AbfGroupType.Individual)?.group;

    async function submit(signedTransaction: string | undefined, name: string | undefined) {
        if (!name) return Result.errFromMessage("Invalid name");
        const promises = [];

        const groupToEdit = isOrganizationAdmin ? activeGroup : personalAccount;

        if (!isOrganizationAdmin) {
            promises.push(editUser({ name, signedTransaction }));
        }

        if (groupToEdit && isOrganizationAdmin) {
            const params: AbfCreateUserGroupParams = {
                group_name: name,
                group_description: "",
                group_image: groupToEdit.groupImage ?? getAvatarFromName(name) ?? "",
                group_type: groupToEdit.groupType,
                admin_wallet: undefined
            };

            promises.push(updateGroup(params));
        }

        const results = await Promise.all(promises);
        const combinedResults = Result.combine(results);
        if (!combinedResults.isOk()) return combinedResults;

        await fetchUserMe({});
        setName("");

        return Result.ok();
    }

    function onChange(name: string | undefined) {
        setName(name ?? "");
        handler(() => send((signedTransaction) => submit(signedTransaction, name), { description: "Updating name" }), {
            alertOnError: true
        });
    }
    return (
        <FormDebouncedStringInput
            disabled={isLoading}
            label="Name"
            placeholder={isOrganizationAdmin ? activeGroup?.groupName : user.name}
            variant="string"
            value={name}
            setValue={onChange}
        />
    );
}

function Avatar({ isOrganizationAdmin }: { isOrganizationAdmin: boolean }) {
    const { user } = useUserProfile();
    const { handler, isLoading } = useAsyncResultHandler();
    const { fetchUserMe } = useAbfFetches();
    const { border, background } = useAppPalette();
    const { activeGroup } = useGroup();
    const send = useSignedTransactionSender();

    async function uploadAvatar(signedTransaction: string | undefined, file: File) {
        const res = await updateAvatarFromFile({
            selectedFile: file,
            isAdmin: isOrganizationAdmin,
            signedTransaction
        });
        if (!res.isOk()) return Result.err(res);

        const me = await fetchUserMe({});

        const newAvatar = me.data?.user.avatar;
        if (newAvatar) {
            // hard refetch image src since url doesn't change
            await fetch(newAvatar, { cache: "reload", mode: "no-cors" });
        }

        return res;
    }

    async function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0];
        if (!selectedFile) return;

        return await handler(
            () =>
                send((signedTransaction) => uploadAvatar(signedTransaction, selectedFile), {
                    description: "Updating avatar"
                }),
            { alertOnError: true }
        );
    }

    return (
        <Stack sx={{ width: "fit-content" }} key={user.avatar} component="label">
            <Badge
                sx={{ width: "70px" }}
                overlap="circular"
                badgeContent={
                    <IconWithBackground
                        size={20}
                        variant="caption"
                        sx={{ backgroundColor: background, border, cursor: "pointer" }}
                    >
                        <Edit />
                    </IconWithBackground>
                }
            >
                <Image
                    sx={{ border, cursor: "pointer" }}
                    loading={isLoading}
                    variant="circle"
                    size="70px"
                    src={isOrganizationAdmin ? activeGroup?.groupImage : user.avatar}
                />
            </Badge>
            <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleFileChange}
                style={{ display: "none" }}
            />
        </Stack>
    );
}
