import { useCallback } from "react";

import {
    AbfGeneratorResult,
    AbfMultiPartTransactionSenderParams,
    AbfTransactionDetails,
    useActiveWallet,
    useSendTransactions,
    TransactionSenderOptions,
    TransactionMessageOverrides,
    BaseAbfMultiPartTransactionSender,
    TransactionWalletAuth,
    useMpcUserCredentialsQuery,
    useTransactionWalletAuth,
    useUpdateRolesTransaction,
    useVerifyWalletSetup,
    TRANSACTION_DEFAULT_BATCH_COMMITMENT,
    useSignInTransactionAuth,
    AlerterOptions,
    getSignInTransactionFromAuth,
    serializeTransactionForAuth,
    useSignTransactions
} from "@bridgesplit/abf-react";
import {
    DEBUG_WALLET,
    OrderedTransactions,
    ParallelTransactionsBatch,
    simulateTransaction,
    TransactionResult,
    useAlert,
    useTransactionsState
} from "@bridgesplit/react";
import {
    Result,
    TransactionStatus,
    getReadableErrorMessage,
    generateNonce,
    parseErrorFromOptions,
    TX_FAILED_TO_CONFIRM,
    doNothing,
    RPC_URL
} from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { TrackEvent, TrackTransactionData, TrackTransactionEvent } from "app/types";
import { useTrack } from "app/hooks";
import { Connection } from "@solana/web3.js";
import { AppDialog, useAppDialog } from "app/utils";

import { ResolveAnyWalletParams } from "../components/transactions/type";

export interface TransactionDialogSenderOptions {
    description?: string;
    sendOptions?: TransactionSenderOptions;
    messageOverrides?: TransactionMessageOverrides;
    isMultiStep?: boolean;
    preventDispatch?: boolean;
    alerter?: AlerterOptions;
    mixpanelEventKey?: TrackTransactionEvent;
    customAccountSetup?: ReturnType<typeof useSetupAccountIfNeeded>;
    mixpanelEvent?: { key: TrackTransactionEvent; params: TrackTransactionData<TrackTransactionEvent> };
}

/**
 * Send transactions async
 * Handle wallet errors by forcing user to open dialog
 */
export function useTransactionSender() {
    const generateAndSend = useGenerateAndSendTransactions();
    const { setTransactionsLoading } = useTransactionsState();
    const preflightChecks = useTransactionPreflightChecks();
    const trackMixpanelEvent = useTrackFromTransactions();

    return async function send<ParamsType extends object>(
        transactionDetails: AbfTransactionDetails<ParamsType>,
        params: ParamsType | undefined,
        options?: TransactionDialogSenderOptions
    ): Promise<Result<TransactionResult[]>> {
        const senderParams: AbfMultiPartTransactionSenderParams = {
            ...combineOptionsAndSender(transactionDetails, options),
            getTransactions: async (): AbfGeneratorResult =>
                transactionDetails.getTransactionsWithParams(params || ({} as ParamsType))
        };

        const preflightRes = await preflightChecks({ description: senderParams.description }, options);
        if (!preflightRes.isOk()) return Result.err(preflightRes);

        setTransactionsLoading(true);
        const res = await generateAndSend(preflightRes.unwrap(), senderParams);

        // Skip close of loader if there are more txns to send
        if (!options?.isMultiStep) {
            setTransactionsLoading(false);
        }

        if (options?.mixpanelEvent) {
            trackMixpanelEvent(res, options.mixpanelEvent);
        }

        return res;
    };
}

export function useTrackFromTransactions() {
    const { trackTransaction } = useTrack();

    return function trackMixpanelEvent(
        result: Result<TransactionResult[]>,
        { key, params }: NonNullable<TransactionDialogSenderOptions["mixpanelEvent"]>
    ) {
        if (!result.isOk()) {
            trackTransaction(key, {
                err: result.unwrapErrMessage()
            });
        } else if (!allTransactionsSucceeded(result)) {
            trackTransaction(key, {
                err: TX_FAILED_TO_CONFIRM,
                res: result.unwrap()
            });
        } else {
            trackTransaction(key, {
                params: params ?? undefined,
                res: result.unwrap()
            });
        }
    };
}

// verify wallet is setup correctly and account is set up
export function useTransactionPreflightChecks() {
    const resolveAnyWalletErrors = useResolveAnyWalletErrors();
    usePreloadMpc();
    const setupAccountIfNeeded = useSetupAccountIfNeeded();

    return async (
        params: ResolveAnyWalletParams,
        options?: TransactionDialogSenderOptions
    ): Promise<Result<TransactionWalletAuth>> => {
        const verifiedAuthRes = await resolveAnyWalletErrors(params);

        // Any wallet errors will intentionally silently fail
        if (!verifiedAuthRes.isOk()) return Result.err(verifiedAuthRes);
        const verifiedAuth = verifiedAuthRes.unwrap();

        // allow override of account setup
        const accountSetup = options?.customAccountSetup ? options.customAccountSetup : setupAccountIfNeeded;
        const setupAccountRes = await accountSetup(verifiedAuth);
        if (!setupAccountRes.isOk()) return Result.err(setupAccountRes);

        return verifiedAuthRes;
    };
}

type SignedTransactionSenderAction<T> = (signedMessage: string | undefined) => Promise<Result<T>>;
type SignedTransactionSenderOptions = {
    customSnackbarId?: string;
    description: string;
    mixpanelEventKey?: TrackEvent;
};
export function useSignedTransactionSender() {
    const signIn = useSignInTransactionAuth();

    const resolveAnyWalletErrors = useResolveAnyWalletErrors();
    const { alert, dismiss } = useAlert();
    const { track } = useTrack();
    const { close: closeAppDialog } = useAppDialog();

    async function send<T>(
        customSnackbarId: string,
        action: SignedTransactionSenderAction<T>,
        { description, mixpanelEventKey }: SignedTransactionSenderOptions
    ): Promise<Result<T>> {
        const verifiedAuthRes = await resolveAnyWalletErrors({ description });
        // Any wallet errors will intentionally silently fail
        if (!verifiedAuthRes.isOk()) return Result.err(verifiedAuthRes);

        const auth = verifiedAuthRes.unwrap();

        let signedMessage: string | undefined;
        // mpc doesn't need to sign
        if ("wallet" in auth) {
            alert(COPY.WALLET_SIGN_PROMPT, "spinner", {
                description,
                customSnackbarId
            });
            const signInRes = await signIn(auth);
            if (!signInRes.isOk()) return Result.err(signInRes);
            signedMessage = signInRes.unwrap();
        }

        alert("Finalizing changes", "spinner", {
            description,
            customSnackbarId
        });
        const res = await action(signedMessage);
        if (!res.isOk()) return Result.err(res);

        alert("All changes confirmed", "success", {
            customSnackbarId,
            description
        });

        if (mixpanelEventKey) {
            track(mixpanelEventKey);
        }
        closeAppDialog();

        return res;
    }

    return async <T>(action: SignedTransactionSenderAction<T>, options: SignedTransactionSenderOptions) => {
        const customSnackbarId = options.customSnackbarId ?? generateNonce();
        const res = await send(customSnackbarId, action, options);

        // early dismiss the transaction spinner snackbar
        if (!res.isOk()) {
            dismiss(customSnackbarId);
        }
        return res;
    };
}

export function useGetSignedTransactionAuth() {
    const signIn = useSignInTransactionAuth();

    const resolveAnyWalletErrors = useResolveAnyWalletErrors();
    const { alert, dismiss } = useAlert();

    return async (options?: { disableAlerts?: boolean; description?: string }): Promise<Result<string | undefined>> => {
        const verifiedAuthRes = await resolveAnyWalletErrors({
            description: options?.description ?? "Authenticate with your wallet"
        });
        // Any wallet errors will intentionally silently fail
        if (!verifiedAuthRes.isOk()) return Result.err(verifiedAuthRes);

        const auth = verifiedAuthRes.unwrap();

        // mpc doesn't need to sign
        if ("mpcIdentifier" in auth) {
            return Result.ok(undefined);
        }
        if (options?.disableAlerts) {
            return await signIn(auth);
        }

        const customSnackbarId = generateNonce();
        alert(COPY.WALLET_SIGN_PROMPT, "spinner", {
            customSnackbarId,
            description: options?.description
        });
        const res = await signIn(auth);
        dismiss(customSnackbarId);
        return res;
    };
}

/**
 * Must be used with WalletValidatorWrapper exclusively
 * Send transactions without closing dialog or prompting snackbar
 */
export function useSilentTransactionSender() {
    const send = useSendTransactions();
    const getAuth = useTransactionWalletAuth();

    return async function <ParamsType extends object>(
        transactionDetails: AbfTransactionDetails<ParamsType>,
        params: ParamsType | undefined,
        options?: TransactionDialogSenderOptions
    ): Promise<Result<TransactionResult[]>> {
        const senderParams: AbfMultiPartTransactionSenderParams = {
            ...combineOptionsAndSender(transactionDetails, options),
            getTransactions: async (): AbfGeneratorResult =>
                transactionDetails.getTransactionsWithParams(params || ({} as ParamsType))
        };

        const { getTransactions, sendOptions } = senderParams;

        const verifiedAuth = getAuth();
        // Any wallet errors will intentionally silently fail
        if (!verifiedAuth.isOk()) return Result.err(verifiedAuth);

        const transactionsRes = await getTransactions();
        if (!transactionsRes.isOk()) {
            sendOptions.onFail?.();
            return Result.err(transactionsRes);
        }

        const transactionResults = await send(verifiedAuth.unwrap(), transactionsRes.unwrap(), sendOptions);
        return transactionResults;
    };
}

export function useGenerateAndSendTransactions() {
    const { alert, dismiss } = useAlert();
    const send = useSendTransactions();
    const { close: closeAppDialog } = useAppDialog();
    const { dispatchResults } = useTransactionsState();

    const getAndSign = useCallback(
        async (
            id: string,
            auth: TransactionWalletAuth,
            sender: AbfMultiPartTransactionSenderParams
        ): Promise<Result<TransactionResult[]>> => {
            const {
                description,
                sendOptions,
                getTransactions,
                messages: messageOverrides,
                preventDispatch,
                alerter
            } = sender;

            const promptStep = (message: string, stepIndex: number, estimatedSeconds: number | undefined) =>
                alert(message, "spinner", {
                    description,
                    customSnackbarId: id,
                    progress:
                        !!alerter?.showProgress && estimatedSeconds !== undefined
                            ? { stepIndex, estimatedSeconds, totalSteps: alerter.totalSteps ?? 2 }
                            : undefined
                });

            const messages = getMessagesForTransactions(auth, messageOverrides);
            promptStep(messages.beforeGenerate, 0, alerter?.generationEstimateSeconds);

            const transactionsRes = await getTransactions();

            if (!transactionsRes.isOk()) {
                sendOptions.onFail?.();
                const parsedError =
                    parseErrorFromOptions(transactionsRes.unwrapErrMessage(), sender.messages?.errorMessageMap ?? {}) ??
                    getReadableErrorMessage("generate transaction");
                return Result.errWithDebug(parsedError, transactionsRes);
            }

            // pause stepper until signature is complete
            promptStep(messages.beforeSignature, 0, 0);

            const transactionsToSend = transactionsRes.unwrap();

            if (DEBUG_WALLET) {
                await simulateTransactionsForDebugging(transactionsToSend);
                return Result.errFromMessage("Transaction aborted due to debug wallet. Logs in console");
            }
            const result = await send(auth, transactionsToSend, {
                ...sendOptions,
                onSign: () => {
                    sendOptions.onSign?.();
                    promptStep(messages?.beforeSend, 1, estimateTransactionLength(transactionsToSend));
                }
            });

            // return raw err since wallet errors are parsed
            if (!result.isOk()) {
                return Result.err(result);
            }

            const transactionResults = result.unwrapOr([]);
            if (!transactionResults.length) {
                return Result.err(messages.onError);
            }

            if (!preventDispatch) {
                const timedOut = !!transactionResults.find((t) => t.error?.includes("Timeout"));

                const resultsWithMissing = addMissingResults(transactionsToSend, transactionResults);

                dispatchResults(id, resultsWithMissing, {
                    onSuccess: messages.onSuccess,
                    onError: timedOut ? "Transaction timed out. Please try again" : messages.onError
                });
            }

            if (allTransactionsSucceeded(result)) {
                closeAppDialog();
            }

            return result;
        },
        [alert, closeAppDialog, dispatchResults, send]
    );

    // catches runtime errors not associated with transactions confirmation fails
    return async function (auth: TransactionWalletAuth, sender: AbfMultiPartTransactionSenderParams) {
        const id = sender.alerter?.customSnackbarId ?? generateNonce();
        const res = await getAndSign(id, auth, sender);

        // early dismiss the transaction spinner snackbar
        if (!res.isOk()) {
            dismiss(id);
        }

        return res;
    };
}

type AllMessages = {
    beforeSignature: string;
    beforeSend: string;
    beforeGenerate: string;
    onSuccess: string;
    onError: string;
};

export function getMessagesForTransactions(
    auth: TransactionWalletAuth,
    messageOverrides: TransactionMessageOverrides | undefined
) {
    const isMpc = "mpcIdentifier" in auth;
    const beforeSignature = isMpc ? COPY.PASSKEY_SIGN_PROMPT : COPY.WALLET_SIGN_PROMPT;
    return parseMessageOverrides(messageOverrides, {
        beforeSend: "Sending transactions",
        onSuccess: "All changes confirmed",
        onError: "Transactions failed to confirm",
        beforeGenerate: "Generating transactions",
        beforeSignature
    });
}

function parseMessageOverrides(overrides: TransactionMessageOverrides | undefined, fallback: AllMessages): AllMessages {
    if (!overrides) return fallback;

    return {
        beforeGenerate: overrides.beforeGenerate ?? overrides.loadingMessage ?? fallback.beforeGenerate,
        beforeSend: overrides.beforeSend ?? overrides.loadingMessage ?? fallback.beforeSend,
        beforeSignature: overrides.beforeSignature ?? overrides.loadingMessage ?? fallback.beforeSignature,
        onError: overrides.onError ?? fallback.onError,
        onSuccess: overrides.onSuccess ?? fallback.onSuccess
    };
}

/**
 * Opens the transaction dialog to handle wallet errors
 * This check catches MPC device not found, device not registered, unconnected self custodial wallet, etc
 * Open dialog promise resolve/reject is passed through redux to dialog directly.
 * Passing callbacks to redux store is slightly bad practice but is necessary for compensability with other dialogs
 * Returns validated auth (MPC or wallet) which has been validated by the async connect dialog
 */
function useResolveAnyWalletErrors() {
    const { open } = useAppDialog();

    // Pass auth in a callback so that it is the updated, not cached from when hook was called
    const openConnect = (params: ResolveAnyWalletParams) =>
        new Promise<TransactionWalletAuth>((resolve, reject) => {
            open(
                AppDialog.TransactionSender,
                {
                    ...params,
                    resolve,
                    reject
                },
                true
            );
        });

    return async (params: ResolveAnyWalletParams): Promise<Result<TransactionWalletAuth>> => {
        try {
            const auth = await openConnect(params);
            return Result.ok(auth);
        } catch (error) {
            return Result.errFromMessage("Transaction cancelled");
        }
    };
}

export function combineOptionsAndSender<ParamsType extends object>(
    transactionDetails: AbfTransactionDetails<ParamsType>,
    options?: TransactionDialogSenderOptions
): BaseAbfMultiPartTransactionSender {
    return {
        description: options?.description ?? transactionDetails.description,
        sendOptions: { ...transactionDetails.sendOptions, ...options?.sendOptions },
        messages: { ...transactionDetails.messages, ...options?.messageOverrides },
        preventDispatch: options?.preventDispatch,
        alerter: options?.alerter
    };
}

export function allTransactionsSucceeded(results: Result<TransactionResult[]>) {
    if (!results.isOk()) return false;
    return !results.unwrap().find((r) => r.error || r.status !== TransactionStatus.Confirmed);
}

function usePreloadMpc() {
    const { isMpcActive } = useActiveWallet();
    // preload credentials for faster load times
    return useMpcUserCredentialsQuery(undefined, { skip: !isMpcActive });
}

function addMissingResults(transactionsToSend: OrderedTransactions, transactionResults: TransactionResult[]) {
    const resultsCopy = [...transactionResults];
    const foundIdentifierResults = new Set(transactionResults.map((r) => r.identifier));

    // first see if any unique identifiers were dropped
    const missingIdentifiers = transactionsToSend
        .map((txn) => txn.transactions)
        .flat()
        .filter((txn) => !foundIdentifierResults.has(txn.identifier));

    if (missingIdentifiers.length) {
        resultsCopy.push(
            ...missingIdentifiers.map(
                ({ identifier }): TransactionResult => ({
                    signature: "",
                    status: TransactionStatus.Error,
                    identifier
                })
            )
        );
    }

    // if there are still missing transactions, fill in as unknowns
    const missingResponses = transactionsToSend.length - resultsCopy.length;
    if (missingResponses > 0) {
        resultsCopy.push(
            ...Array<TransactionResult>(missingResponses).fill({
                signature: "",
                status: TransactionStatus.Error,
                identifier: "Unknown"
            })
        );
    }

    return resultsCopy;
}

export function useSetupAccountIfNeeded() {
    const updateRoles = useUpdateRolesTransaction("fixOwner");
    const generateAndSend = useGenerateAndSendTransactions();

    const { activeWallet } = useActiveWallet();

    const { verifyAsync } = useVerifyWalletSetup(activeWallet?.wallet);

    return async (auth: TransactionWalletAuth) => {
        const verifyRes = await verifyAsync();
        if (!verifyRes.isOk()) return Result.err(verifyRes);

        const walletVerification = verifyRes.unwrap();
        if (!walletVerification.missingRoles.length) {
            return Result.ok();
        }

        const res = await generateAndSend(auth, {
            sendOptions: { commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT },
            getTransactions: () => updateRoles.getTransactionsWithParams({}),
            description: walletVerification.onChainRoles.length
                ? "Updating your onchain roles"
                : "Creating your account"
        });

        if (!res.isOk()) return Result.err(res);
        if (!allTransactionsSucceeded(res)) {
            return Result.errFromMessage(getReadableErrorMessage("create your on-chain account"));
        }
        updateRoles.sendOptions.refetch?.();
        return Result.ok();
    };
}

export function estimateTransactionLength(transactionsToSend: OrderedTransactions) {
    let estimateSeconds = 0;
    for (const { transactions, commitmentLevel } of transactionsToSend) {
        if (commitmentLevel === "finalized") estimateSeconds += 40;
        if (commitmentLevel === "confirmed") estimateSeconds += 20;
        estimateSeconds += transactions.length;
    }
    return estimateSeconds;
}

type DbCallback<ResultType> = (signedTransaction: string | undefined) => Promise<Result<ResultType>>;
type DbAndTransactionSenderParams<ParamsType extends object, DbResultType> = {
    transactionDetails: AbfTransactionDetails<ParamsType>;
    transactionParams: ParamsType;
    dbCallback: {
        type: "before" | "after";
        callback: DbCallback<DbResultType>;
        timeEstimateTotal: number;
    };
    description: string;
};
/**
 * Handles wallet gated DB operations that need to be executed before or after a transaction
 * This is used for things like creating/deleting strategies
 * 1. Transactions + sign in txn are generated in parallel
 * 2. The transactions + sign in txn are signed
 * 3. (if type is before) DB operation is executed
 * 4. The transactions are sent
 * 5. (if type is after) DB operation is executed
 */
export function useDbAndTransactionSender() {
    const { alert, dismiss } = useAlert();
    const preflight = useTransactionPreflightChecks();
    const sendTransactions = useSendTransactions();
    const signTransactions = useSignTransactions();
    const { dispatchResults } = useTransactionsState();

    async function generateAndSend<ParamsType extends object, DbResultType>({
        dbCallback,
        customSnackbarId,
        transactionDetails,
        transactionParams,
        description
    }: DbAndTransactionSenderParams<ParamsType, DbResultType> & { customSnackbarId: string }): Promise<
        Result<TransactionResult[]>
    > {
        const preflightRes = await preflight({ description });
        if (!preflightRes.isOk()) return Result.err(preflightRes);
        const auth = preflightRes.unwrap();

        const messages = getMessagesForTransactions(auth, undefined);

        const promptStep = (message: string, stepIndex: number, estimatedSeconds: number) =>
            alert(message, "spinner", {
                description,
                customSnackbarId,
                progress: {
                    stepIndex,
                    totalSteps: 3,
                    estimatedSeconds
                }
            });

        promptStep(messages.beforeGenerate, 0, 3);
        const [generatedTransactionsRes, signInTransactionRes] = await Promise.all([
            transactionDetails.getTransactionsWithParams(transactionParams),
            getSignInTransactionFromAuth(auth)
        ]);

        if (!generatedTransactionsRes.isOk()) return Result.err(generatedTransactionsRes);
        if (!signInTransactionRes.isOk()) return Result.err(signInTransactionRes);

        const signInTransaction = signInTransactionRes.unwrap();
        const signInTransactionBatch: ParallelTransactionsBatch = {
            transactions: signInTransaction ? [{ transaction: signInTransaction, identifier: "Sign in" }] : []
        };

        const transactionsToSign: ParallelTransactionsBatch[] = [
            ...generatedTransactionsRes.unwrap(),
            signInTransactionBatch
        ];
        promptStep(messages.beforeSignature, 0, 0);

        const signedTransactionRes = await signTransactions(transactionsToSign, auth, {});
        if (!signedTransactionRes.isOk()) return Result.err(signedTransactionRes);

        const totalEstimate = (dbCallback.timeEstimateTotal ?? 0) + estimateTransactionLength(transactionsToSign);
        promptStep(messages.beforeSend, 1, totalEstimate);

        const transactionsToSend = signedTransactionRes.unwrap();
        const signedTransaction = transactionsToSend.pop()?.transactions?.[0]?.transaction;
        const signedSignInTransaction = signedTransaction ? serializeTransactionForAuth(signedTransaction) : undefined;

        if (dbCallback.type === "before") {
            const res = await dbCallback.callback(signedSignInTransaction);
            if (!res.isOk()) {
                transactionDetails.sendOptions.refetch?.();
                return Result.err(res);
            }
        }

        const results = await sendTransactions(
            auth,
            transactionsToSend,
            combineOptionsAndSender(transactionDetails, {
                sendOptions: {
                    skipSignature: true,
                    // skip refetch if DB still needs to happen
                    refetch: dbCallback.type === "after" ? doNothing : transactionDetails.sendOptions.refetch
                }
            }).sendOptions
        );

        if (dbCallback.type === "after") {
            const res = await dbCallback.callback(signedSignInTransaction);
            transactionDetails.sendOptions.refetch?.();
            if (!res.isOk()) return Result.err(res);
        }

        dispatchResults(customSnackbarId, results.unwrap(), messages);
        return results;
    }

    return async <ParamsType extends object, DbResultType>(
        params: DbAndTransactionSenderParams<ParamsType, DbResultType>
    ): Promise<Result<TransactionResult[]>> => {
        const customSnackbarId = generateNonce();

        const res = await generateAndSend({ ...params, customSnackbarId });
        if (!res.isOk()) {
            dismiss(customSnackbarId);
            return res;
        }
        return res;
    };
}

async function simulateTransactionsForDebugging(orderedTransactions: OrderedTransactions) {
    // only simulate for debug wallets
    if (!DEBUG_WALLET) return Result.ok();

    for (const { transactions } of orderedTransactions) {
        for (const { transaction } of transactions) {
            const res = await simulateTransaction(new Connection(RPC_URL), transaction, [DEBUG_WALLET]);
            // eslint-disable-next-line no-console
            console.log(res);
        }
    }

    return Result.ok();
}
