import { fetchAndDeserializeVersionedTransaction, NAPOLEON_API } from "@bridgesplit/utils";

import {
    AbfTransactionHeaders,
    GetClaimFeeTxnArgs,
    GetDecreaseLiquidityTxnArgs,
    GetIncreaseLiquidityTxnArgs,
    GetUpdateLiquidityTxnArgs,
    GetUpdatePositionTxnArgs,
    ROUTE_ORCA_CLAIM_FEE_TXN,
    ROUTE_ORCA_DECREASE_LIQUIDITY,
    ROUTE_ORCA_INCREASE_LIQUIDITY,
    ROUTE_ORCA_UPDATE_LIQUIDITY_TXN,
    ROUTE_ORCA_UPDATE_POSITION_TXN
} from "../types";
import { createAbfInstance } from "./auth";

export async function getWhirlpoolClaimFeeTransaction(
    authentication: AbfTransactionHeaders,
    args: GetClaimFeeTxnArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            ROUTE_ORCA_CLAIM_FEE_TXN,
            args
        )
    ];
}

export async function getWhirlpoolUpdateLiquidityTransaction(
    authentication: AbfTransactionHeaders,
    args: GetUpdateLiquidityTxnArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            ROUTE_ORCA_UPDATE_LIQUIDITY_TXN,
            args
        )
    ];
}

export async function getWhirlpoolUpdatePositionTransaction(
    authentication: AbfTransactionHeaders,
    args: GetUpdatePositionTxnArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            ROUTE_ORCA_UPDATE_POSITION_TXN,
            args
        )
    ];
}

export async function getWhirlpoolIncreaseLiquidityTransaction(
    authentication: AbfTransactionHeaders,
    args: GetIncreaseLiquidityTxnArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            ROUTE_ORCA_INCREASE_LIQUIDITY,
            args
        )
    ];
}

export async function getWhirlpoolDecreaseLiquidityTransaction(
    authentication: AbfTransactionHeaders,
    args: GetDecreaseLiquidityTxnArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            ROUTE_ORCA_DECREASE_LIQUIDITY,
            args
        )
    ];
}
