import { ErrorType, IS_LOCAL_NX_DEV, Result, formatAddress } from "@bridgesplit/utils";
import { AppCookie, getAvatarFromString, getBearerToken, getTransactionSettings } from "@bridgesplit/react";
import { AbfTransactionHeaders } from "@bridgesplit/abf-sdk";
import { UAParser } from "ua-parser-js";

import { AbfGroupType, AbfUser, AbfUserGroup, AbfUserWallet } from "../types";
import { AUTH0_COOKIES_PREFIX, ABF_GROUP_COOKIE_PREFIX, USER_WALLET_COOKIE } from "../constants";
import { isValidPublicKey } from "./pda";

export function getTransactionHeadersFromCookies(): Result<AbfTransactionHeaders> {
    const bearerToken = getBearerToken(AUTH0_COOKIES_PREFIX);
    const groupIdentifier = getBearerToken(ABF_GROUP_COOKIE_PREFIX);
    const userWallet = AppCookie.get(USER_WALLET_COOKIE);
    const txnSettings = getTransactionSettings();

    if (!bearerToken && !userWallet) {
        return Result.errFromMessage("No authentication provided", { errorType: ErrorType.AuthError });
    }

    return Result.ok({ groupIdentifier, bearerToken, userWallet, txnSettings });
}

export function getFirstName(abfUser: AbfUser | undefined | { name: string }) {
    if (!abfUser?.name) return abfUser?.name;
    let firstName = abfUser.name?.split(" ")?.[0];
    firstName = firstName.split("@")?.[0];

    return capitalizeFirstLetter(firstName || abfUser.name);
}

export function getDefaultName(userName: string | undefined) {
    if (!userName) return "";
    let name = userName.split("@")?.[0];
    name = name.split("+")?.[0];
    return name;
}

export function formatGroup(group: AbfUserGroup | undefined) {
    if (!group) return "Account";
    if (group.groupType === AbfGroupType.Individual) return "Personal Account";
    return group?.groupName;
}

export function formatGroupName(group: AbfUserGroup | undefined) {
    if (!group?.groupName) return formatAddress(group?.groupIdentifier);
    if (isValidPublicKey(group.groupName)) return formatAddress(group.groupName);
    return group.groupName;
}

export function validateNameHasBeenSet(abfUser: AbfUser | undefined) {
    if (!abfUser?.name) return false;
    return abfUser.email !== abfUser.name;
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getAvatarFromUser(abfUser: AbfUser | undefined) {
    if (!abfUser) return undefined;
    return getAvatarFromName(abfUser.name || abfUser.email);
}

export function getAvatarFromName(name: string | undefined) {
    if (!name) return undefined;
    const names = name?.split(" ");
    const initials = names.map((n) => n.replace(/[0-9]/g, "")?.[0]);
    return getAvatarFromString(initials.join(""));
}

export function getGroupNameForUser(group: AbfUserGroup | undefined) {
    if (!group) return "this account";
    if (group.groupType === AbfGroupType.Individual) {
        return "your account";
    }
    return group.groupName;
}

export function validateIsCustodian(group: AbfUserGroup | undefined) {
    if (!group) return false;
    return group.groupType === AbfGroupType.Custodian;
}

export function generateEmailLink(domainServer: string | undefined, sentFromEmail: string) {
    if (!domainServer) return null;

    const encodedEmail = encodeURIComponent(sentFromEmail);
    if (["google.com", "@gmail.com", "@googlemail.com"].includes(domainServer)) {
        return `https://mail.google.com/mail/u/{email}/#search/from%3A(${encodedEmail})+in%3Aanywhere`;
    }

    if (["outlook.com", "live.com"].includes(domainServer)) {
        return `https://outlook.live.com/mail/?login_hint=${encodedEmail}`;
    }

    switch (domainServer) {
        case "protonmail.ch":
            return `https://mail.proton.me/u/0/all-mail#from=${encodedEmail}`;
        case "yahoo.com":
            return `https://mail.yahoo.com/d/search?from=${encodedEmail}`;
        case "aol.com":
            return `https://mail.aol.com/webmail-std/en-us/list?query=${encodedEmail}`;
        case "icloud.com":
            return "https://www.icloud.com/mail";
        default:
            return null;
    }
}

export function isAuth0SameDomain(auth0Domain: string) {
    if (IS_LOCAL_NX_DEV) return false;
    const subDomain = window.location.host.split(".").slice(1).join(".");
    const auth0Subdomain = auth0Domain.split(".").slice(1).join(".");

    return subDomain === auth0Subdomain;
}

export function isCrossOriginAllowed() {
    const parsed = new UAParser(navigator.userAgent).getResult();

    return parsed.browser.name === "Chrome";
}

export function formatWallet(wallet: AbfUserWallet | undefined) {
    if (wallet?.mpcIdentifier) {
        return "Passkey Wallet";
    }

    return formatAddress(wallet?.wallet);
}
