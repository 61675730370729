import { Meteora } from "@bridgesplit/bs-protos";
import { SortDirection } from "@bridgesplit/utils";

import { StrategyCollateralInfoParams } from "./arguments";
import { JupiterSwapQuote } from "../types";
import { AbfOrderStatus } from "./order";

export interface LoopVaultInfo {
    collateralMint: string;
    principalMint: string;
    collateralDeposited: number;
    principalAmountAvailable: number;
    collateralApyPct: number;
    maxLeveragedApyPct: number;
    maxLeverage: number;
    name: string;
    metadata: MeteoraLoopMetadata | null;
    routeType: LoopRouteType | null;
    tags: LoopTag[];
}

export interface MeteoraLoopMetadata {
    aTokenVault: string;
    bTokenVault: string;
    lpMint: string;
    lutAddress: string;
    lutAddresses: string;
    poolAddress: string;
    tokenAMint: string;
    tokenBMint: string;
    vaultA: string;
    vaultALp: string;
    vaultALpMint: string;
    vaultB: string;
    vaultBLp: string;
    vaultBLpMint: string;
}

export enum LoopTag {
    LST = "LST",
    LRT = "LRT",
    LeveragedStaking = "Leveraged staking",
    Stables = "Stables",
    DexLp = "DEX LP",
    PerpsLp = "Perps LP",
    LongCrypto = "Long Crypto",
    ShortSol = "Short SOL",
    ShortBtc = "Short BTC",
    ShortEth = "Short ETH"
}

export enum LoopRoutePlatform {
    Jupiter = "jup",
    Meteora = "meteora"
}

export enum LoopRouteType {
    Orca,
    Meteora,
    JlpPerps
}

export interface LoopInfoParams {
    loopVaults?: string[];
}

export interface UserLoopFilter {
    principalMint?: string;
    collateralMint?: string;
    active: boolean;
    sortDirection?: SortDirection;
    sortType?: LoopPositionSortType;
    page: number;
    pageSize: number;
}

export enum LoopPositionSortType {
    StartTime,
    LeverageMultiplier,
    NetApy,
    NetPositionValue,
    HealthRatio
}

export interface UserLoopInfoParams extends UserLoopFilter {
    loopVaults?: string[];
    loanVaults?: string[];
}

export interface LoopYieldParams {
    collateral: string[];
}

export type WindRoute =
    | { [LoopRoutePlatform.Jupiter]: JupiterSwapQuote }
    | { [LoopRoutePlatform.Meteora]: Meteora.DynamicPoolDepositQuote };
export interface LoopWindTransactionInput {
    vaultIdentifier: string;
    leverageMultiplier: number;
    strategyIdentifier: string;
    principalMint: string;
    collateralInfo: StrategyCollateralInfoParams;
    principalRequested: number;
    apy: number;
    ltv: number;
    slippage: number;
    liquidationThreshold: number;
    transactionGenerationType: number;
    windRoute: WindRoute;
    useFillerEscrow: boolean;
}

export type UnwindRoute =
    | { [LoopRoutePlatform.Jupiter]: { amountIn: number | null } }
    | { [LoopRoutePlatform.Meteora]: Meteora.DynamicPoolWithdrawQuote };
export interface LoopUnwindTransactionInput {
    loanAddress: string;
    collateralSlippageCbps: number;
    principalMint: string;
    collateralMint: string;
    order: string;
    unwindRoute: UnwindRoute;
}

export interface UserLoopInfo {
    loanAddress: string;
    leverageMultiplier: number;
    initialCollateralAmount: number;
    initialCollateralPrice: number;
    totalCollateralDepositedAmount: number; // leveraged amount + additional deposits (in token terms)
    loopVaultIdentifier: string;
    orderStatus: AbfOrderStatus;
    netApy: number | null;
    additionalCollateralDepositedAmount: number;
    additionalCollateralDepositsUsd: number;
    totalPaidUsd: number;
    endPrincipalUsdPrice: number | null;
    endCollateralUsdPrice: number | null;
    positionStartTime: number;
    healthRatio: number | null;
    netPositionValue: number;
    index: number;
    totalCount: number;
}
