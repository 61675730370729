import {
    useActiveEscrow,
    useActiveGroup,
    useInstantUnstakeStakeTransaction,
    useSanctumUnstakeQuotesQuery
} from "@bridgesplit/abf-react";
import { BackCta, Column, ErrorMessage, StatColumn } from "@bridgesplit/ui";
import {
    LOADING_ERROR,
    MISSING_PARAM_ERROR,
    Result,
    SOL_SYMBOL,
    bsMath,
    formatTokenAmount,
    getReadableErrorMessage,
    isPDA
} from "@bridgesplit/utils";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { AppButton } from "../../common";
import { useTransactionSender } from "app/utils";
import { useStakeSelect } from "./common";

const FORMAT_DECIMALS = 4;
export default function InstantUnstake({ back, showWallet }: { back?: () => void; showWallet: boolean }) {
    const { groupIdentifier } = useActiveGroup();
    const { escrowNonce } = useActiveEscrow();

    const unstake = useInstantUnstakeStakeTransaction();
    const sender = useTransactionSender();

    const { selectedAccounts, Select } = useStakeSelect({ showWallet, requireCurrentEpoch: true });

    const {
        data: quotes,
        isFetching,
        isError
    } = useSanctumUnstakeQuotesQuery(
        selectedAccounts?.map((a) => ({
            amount: a.amount * LAMPORTS_PER_SOL,
            stakeAccount: a.stakeAccount,
            stakeAccountAuthority: a.authorizedWithdrawer,
            stakeAccountValidator: a.delegationVoterPubkey
        })) ?? skipToken,
        { skip: !selectedAccounts?.length }
    );

    async function submit() {
        if (!groupIdentifier || !escrowNonce) return Result.errFromMessage(LOADING_ERROR);
        if (!selectedAccounts) return Result.errFromMessage(MISSING_PARAM_ERROR);

        if (!quotes) return Result.errFromMessage(getReadableErrorMessage("find unstake route"));

        const escrowedAccounts = selectedAccounts.filter((a) => isPDA(a.owner));
        return await sender(unstake, {
            transfer: {
                groupIdentifier,
                escrowNonce,
                stakeAccounts: escrowedAccounts.map((s) => ({ address: s.stakeAccount, amountToTransfer: s.amount }))
            },
            unstake: quotes
        });
    }

    const inAmount = quotes?.reduce((prev, curr) => prev + parseFloat(curr.route.inAmount) / LAMPORTS_PER_SOL, 0) ?? 0;
    const outAmount =
        quotes?.reduce((prev, curr) => prev + parseFloat(curr.route.outAmount) / LAMPORTS_PER_SOL, 0) ?? 0;
    const feeAmount = bsMath.sub(inAmount, outAmount);

    return (
        <>
            <Select />

            <StatColumn
                loading={isFetching}
                stats={[
                    {
                        value: formatTokenAmount(inAmount, { decimals: FORMAT_DECIMALS }),
                        caption: "Withdraw",
                        symbol: SOL_SYMBOL
                    },
                    {
                        value: formatTokenAmount(outAmount, { decimals: FORMAT_DECIMALS }),
                        caption: "Receive",
                        symbol: SOL_SYMBOL
                    },
                    {
                        value: formatTokenAmount(feeAmount, { decimals: FORMAT_DECIMALS }),
                        tooltip: "A swap fee paid to market makers of staked SOL/SOL pools",
                        caption: "Unstake Fee",
                        symbol: SOL_SYMBOL
                    }
                ]}
            />

            {isError && <ErrorMessage errorMessage="Unable to find a conversion route" />}
            <Column spacing={1}>
                <AppButton isTransaction disabled={!outAmount} asyncCta={{ onClickWithResult: submit }}>
                    Unstake
                </AppButton>
                {back && <BackCta back={back} />}
            </Column>
        </>
    );
}
