import { useCallback, useMemo } from "react";

import { Column, FormLabel, Slider } from "@bridgesplit/ui";
import debounce from "lodash.debounce";
import { COPY } from "app/constants";
import { formatLeverage } from "@bridgesplit/abf-react";

import { useLoopContext } from "../LoopContext";
import { useWindContext } from "./WindContext";

export default function WindMultiplier() {
    const {
        form: { multiplierWithoutDebounce },
        setForm
    } = useWindContext();
    const { loopExpanded } = useLoopContext();

    const handleChange = useCallback(
        (multiplier: number) =>
            setForm((prev) => ({
                ...prev,
                multiplier
            })),
        [setForm]
    );

    const setValueDebounced = useMemo(() => debounce(handleChange, 300), [handleChange]);

    const minValue = 1;
    const maxLeverageValue = loopExpanded?.loopVault.maxLeverage ?? 1;

    const marks = useMemo(() => {
        if (minValue === maxLeverageValue) return [];
        const showDecimal = maxLeverageValue % 1 !== 0;
        return [
            {
                value: minValue,
                label: formatLeverage(minValue, 0)
            },

            {
                value: maxLeverageValue,
                label: formatLeverage(maxLeverageValue, showDecimal ? 1 : 0)
            }
        ];
    }, [maxLeverageValue]);

    const hideStartMark = multiplierWithoutDebounce ? multiplierWithoutDebounce <= minValue + 0.3 : true;

    const hideEndMark = multiplierWithoutDebounce ? multiplierWithoutDebounce >= maxLeverageValue - 0.3 : false;

    return (
        <Column>
            <FormLabel label={COPY.LEVERAGE_TERM} />
            <Slider
                marks={marks}
                step={0.1}
                min={minValue}
                max={maxLeverageValue}
                value={multiplierWithoutDebounce}
                setValue={(multiplier) => {
                    setForm((prev) => ({
                        ...prev,
                        multiplierWithoutDebounce: multiplier
                    }));
                    setValueDebounced(multiplier);
                }}
                valueLabelFormat={(value) => {
                    const showDecimal = value % 1 !== 0;
                    return formatLeverage(value, showDecimal ? 1 : 0);
                }}
                sx={{
                    "& .MuiSlider-valueLabel": {
                        background: "none",
                        color: "currentColor",
                        transform: "translateY(100%)",
                        bottom: "-5px"
                    }
                }}
                valueLabelDisplay={"auto"}
                hideStartMark={hideStartMark}
                hideEndMark={hideEndMark}
            />
        </Column>
    );
}
