import { memo, useMemo } from "react";

import { Column, TagTextAlert } from "@bridgesplit/ui";
import {
    BsMetaUtil,
    getLoanStrategyIdentifier,
    useWhirlpoolIncreaseLiquidityTransaction
} from "@bridgesplit/abf-react";
import { LOADING_ERROR, Result } from "@bridgesplit/utils";
import { GetIncreaseLiquidityTxnArgs } from "@bridgesplit/abf-sdk";

import { AppButton } from "../../../../common";
import { useDepositWhirlpoolBalances } from "./util";
import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useTransactionSender } from "app/utils";

export default memo(function DepositCta() {
    const { depositForm, poolData, addLiquidityQuote, whirlpoolPosition } = useWhirlpoolContext();
    const { A, B } = useDepositWhirlpoolBalances();
    const addLiquidity = useAddLiquidity();

    const alertError = useMemo(() => {
        // don't show alert with no user input
        if (depositForm.status === undefined) return undefined;
        if (A.insufficientBalance)
            return `Insufficient ${BsMetaUtil.getSymbol(whirlpoolPosition.tokenA.metadata)} balance`;
        if (B.insufficientBalance)
            return `Insufficient ${BsMetaUtil.getSymbol(whirlpoolPosition.tokenB.metadata)} balance`;

        return undefined;
    }, [
        depositForm.status,
        A.insufficientBalance,
        whirlpoolPosition.tokenA.metadata,
        whirlpoolPosition.tokenB.metadata,
        B.insufficientBalance
    ]);

    const ctaError = useMemo(() => {
        if (!depositForm.tokenAAmount && !depositForm.tokenBAmount) return "Enter an amount";
        if (depositForm.status === undefined) return "No changes made";
        if (depositForm.status === "error") return "No quote found";
        if (!poolData || !addLiquidityQuote) return "Loading";
        return undefined;
    }, [depositForm.tokenAAmount, depositForm.tokenBAmount, depositForm.status, poolData, addLiquidityQuote]);

    return (
        <Column spacing={1}>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            <AppButton
                asyncCta={{ onClickWithResult: addLiquidity }}
                isTransaction
                color="secondary"
                disabled={!!ctaError || !!alertError}
            >
                {ctaError ?? "Deposit"}
            </AppButton>
        </Column>
    );
});

function useAddLiquidity() {
    const { addLiquidityQuote, loanExpanded, reset } = useWhirlpoolContext();
    const send = useTransactionSender();
    const increaseLiquidity = useWhirlpoolIncreaseLiquidityTransaction();
    return async () => {
        if (!addLiquidityQuote) return Result.errFromMessage(LOADING_ERROR);

        const strategyIdentifier = getLoanStrategyIdentifier(loanExpanded);
        if (!strategyIdentifier) return Result.errFromMessage("Updates are not available for this loan");

        const params: GetIncreaseLiquidityTxnArgs = {
            loan: loanExpanded.loan.address,
            strategyIdentifier,
            liquidityAmount: addLiquidityQuote.liquidity.toNumber(),
            tokenMaxA: addLiquidityQuote.maxTokenA.toNumber(),
            tokenMaxB: addLiquidityQuote.maxTokenB.toNumber()
        };

        return await send(increaseLiquidity, params, { sendOptions: { onSuccess: reset } });
    };
}
