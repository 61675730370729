import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
    Column,
    EllipsesText,
    IconHeader,
    ProgressBar,
    Row,
    StepsWithLabels,
    Text,
    useTimer,
    useWarnOnPageExit
} from "@bridgesplit/ui";
import { ChainId } from "@bridgesplit/abf-sdk";
import {
    BsMetaUtil,
    EvmTransactionType,
    useActiveWallet,
    useBridgeTransactionsQuery,
    useBsMetadataByMints,
    useEstimateGas,
    useUserChainWallet,
    useUserEscrowsAndWalletSummarized
} from "@bridgesplit/abf-react";
import { DispatchType, useAlert, useAsyncResultHandler } from "@bridgesplit/react";
import { Transaction } from "@bridgesplit/bs-protos";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { filterTruthy } from "@bridgesplit/utils";
import { linearProgressClasses } from "@mui/material";
import { HourglassBottomOutlined } from "@mui/icons-material";
import { useAppDialog } from "app/utils";
import { WalletValidatorWrapper } from "app/components/transactions";

import { getChainMeta } from "../../common";
import { useUserEvmNfts } from "../util";
import { EvmBalance, useBridgeFromSolToEvm } from "../../wormhole";
import { WithdrawChainDeposits } from "../common";
import EvmWithdrawToWallet from "./EvmWithdrawToWallet";
import { ChainMetadata } from "../../../types";

enum Step {
    Bridging,
    Confirmations,
    WalletSelection
}
export default function BridgeToEvm({ chain, mints }: { chain: ChainId; mints: string[] }) {
    const [step, setStep] = useState<Step>(Step.Bridging);
    const [selected, setSelected] = useState<Set<string>>();

    return (
        <>
            <StepsWithLabels stepIndex={step} steps={["Bridge", "Confirm", "Transfer"]} />

            <WalletValidatorWrapper>
                {step === Step.Bridging && (
                    <StartBridge
                        setStep={setStep}
                        selected={selected}
                        setSelected={setSelected}
                        originalMints={mints}
                        chain={chain}
                    />
                )}
                {step === Step.Confirmations && (
                    <Confirmations
                        setStep={setStep}
                        mintsToTrack={selected ? Array.from(selected) : mints}
                        chain={chain}
                    />
                )}

                {step === Step.WalletSelection && (
                    <EvmWithdrawToWallet selected={selected ?? new Set(mints)} chain={chain} />
                )}
            </WalletValidatorWrapper>
        </>
    );
}

function StartBridge({
    chain,
    originalMints,
    selected,
    setSelected,
    setStep
}: {
    chain: ChainId;
    originalMints: string[]; // mints user selected when opening dialog
    selected: Set<string> | undefined;
    setSelected: DispatchType<Set<string> | undefined>;
    setStep: DispatchType<Step>;
}) {
    const meta = getChainMeta(chain);

    const { solanaMpcWallet } = useActiveWallet();
    const deposits = useUserEscrowsAndWalletSummarized({ customWallet: solanaMpcWallet?.wallet });

    const selectedDeposits = deposits?.filter((d) => selected?.has(d.mint));

    const originalMintsSet = useMemo(() => new Set(originalMints), [originalMints]);

    useEffect(() => {
        if (selected || !deposits) return;
        const available = deposits
            ?.filter(({ metadata, mint }) => originalMintsSet.has(mint) && BsMetaUtil.getChainId(metadata) === chain)
            ?.map((a) => a.mint);
        setSelected(new Set(available));
    }, [chain, deposits, originalMintsSet, selected, setSelected]);

    const gasTransactions = Array<EvmTransactionType[]>(selected?.size ?? 0)
        .fill([EvmTransactionType.Claim, EvmTransactionType.TransferNft])
        .flat();
    const estimatedGas = useEstimateGas(chain)(gasTransactions);

    return (
        <>
            <WithdrawChainDeposits
                chain={chain}
                emptyText="No assets selected"
                selected={selected ?? new Set()}
                setSelected={setSelected}
                assets={deposits}
            />
            <Text variant="body2" color="caption">
                You won’t receive assets until all steps are complete. Enter your {meta.name} address in the last step
            </Text>

            <EvmBalance.Cta
                isTransaction={false}
                requestedBalance={estimatedGas?.total}
                disabled={!selectedDeposits?.length}
                onClick={() => setStep(Step.Confirmations)}
            >
                Initiate Withdrawal
            </EvmBalance.Cta>
        </>
    );
}

enum Progress {
    WithdrawingFromEscrow,
    Bridging,
    EvmConfirming
}

const PROGRESS_STEPS: Progress[] = [0, 1, 2];

function Confirmations({
    chain,
    mintsToTrack,
    setStep
}: {
    chain: ChainId;
    mintsToTrack: string[];
    setStep: DispatchType<Step>;
}) {
    const meta = getChainMeta(chain);
    const queryInProgress = useRef(false);
    const [progress, setProgress] = useState(Progress.WithdrawingFromEscrow);
    const { getMetadata } = useBsMetadataByMints(mintsToTrack);
    const selectedMeta = mintsToTrack
        .filter((mint) => mintsToTrack?.includes(mint))
        .map((mint) => getMetadata(mint))
        .filter(filterTruthy);

    const { setPreventClose } = useAppDialog();

    useWarnOnPageExit();

    const { resultHandler } = useAsyncResultHandler();

    const { wallet } = useUserChainWallet(chain);

    const { data: nfts } = useUserEvmNfts(chain, {
        pollingInterval: 2_500,
        skip: progress !== Progress.EvmConfirming
    });
    const { data: transactions } = useBridgeTransactionsQuery(
        wallet ? { wallets: [wallet], solanaMints: mintsToTrack } : skipToken,
        { skip: !wallet || !mintsToTrack.length || progress !== Progress.EvmConfirming, pollingInterval: 2_500 }
    );

    const depositedNfts = new Set(nfts?.map((n) => n.solanaMint) ?? []);
    const allNftsDeposited =
        mintsToTrack.length && mintsToTrack.filter((mint) => depositedNfts.has(mint)).length === mintsToTrack.length;

    const bridgeFailed = transactions?.find((t) =>
        [Transaction.TransactionStatus.ERROR, Transaction.TransactionStatus.REJECTED].includes(
            t.transactionInfo.transactionStatus
        )
    );
    const { alert } = useAlert();
    const bridge = useBridgeFromSolToEvm();

    const prevStep = useCallback(() => {
        setStep(Step.Bridging);
        setPreventClose(false);
    }, [setPreventClose, setStep]);

    useEffect(() => {
        if (!selectedMeta?.length || !!queryInProgress.current) return;
        queryInProgress.current = true;
        setPreventClose(true);
        resultHandler(
            () =>
                bridge(
                    { assets: (selectedMeta ?? []).map((n) => n), chainId: chain },
                    { onEscrowSuccess: () => setProgress(Progress.Bridging) }
                ),
            { onSuccess: () => setProgress(Progress.EvmConfirming), onFail: prevStep, alertOnError: true }
        );
    }, [bridge, chain, prevStep, progress, resultHandler, selectedMeta, setPreventClose]);

    useEffect(() => {
        if (bridgeFailed) {
            alert(`Claim on ${meta.name} failed`, "error", { description: "Please try again" });
            prevStep();
            return;
        }
        if (allNftsDeposited) {
            setStep(Step.WalletSelection);
        }
    }, [alert, allNftsDeposited, bridgeFailed, meta.name, prevStep, setStep]);

    return (
        <Column spacing={2}>
            <IconHeader>
                <HourglassBottomOutlined />
            </IconHeader>

            <Column sx={{ alignItems: "center", textAlign: "center" }}>
                <EllipsesText sx={{ minWidth: "170px" }} loading variant="h4">
                    Confirming {depositedNfts.size === 1 ? "Transfer" : "Transfers"}
                </EllipsesText>
                <Text variant="body2" color="caption">
                    This will take a few minutes. Don't exit this page
                </Text>
            </Column>

            <ProgressDisplay progress={progress} meta={meta} />
        </Column>
    );
}

/**
 * This progress display splits 3 actual transactions into a linear progress bar based on ETAs for each step
 * Each step has a progress bar, with it's width adjusted to the ETA of that step, to create the illusion of
 * true linear progress. If the current step is stalled, progress will halt at the end of that progress bar
 */
const PROGRESS_ESTIMATES: Record<Progress, number> = {
    [Progress.WithdrawingFromEscrow]: 20,
    [Progress.Bridging]: 120,
    [Progress.EvmConfirming]: 8
};

function ProgressDisplay({ progress, meta }: { progress: Progress; meta: ChainMetadata }) {
    const totalEstimatedTime = Object.values(PROGRESS_ESTIMATES).reduce((prev, curr) => prev + curr, 0);

    return (
        <Column spacing={1}>
            <Row>
                {PROGRESS_STEPS.map((v) => (
                    <ProgressCounter
                        totalEstimatedTime={totalEstimatedTime}
                        estimateSeconds={PROGRESS_ESTIMATES[v]}
                        complete={progress > v}
                        active={progress === v}
                        key={v}
                    />
                ))}
            </Row>
            <Label
                totalEstimatedTime={totalEstimatedTime}
                steps={[
                    "Withdrawing from Loopscale",
                    "Authenticating asset",
                    "Sending to Wormhole",
                    "Confirming with Wormhole Guardians",
                    "Confirming multi-sig VAA",
                    `Confirming assets ${meta.name}`
                ]}
            />
        </Column>
    );
}

function Label({ steps, totalEstimatedTime }: { steps: string[]; totalEstimatedTime: number }) {
    const secondsElapsed = useTimer();

    const activeStep = Math.min(Math.floor(steps.length / (totalEstimatedTime / secondsElapsed)), steps.length - 1);

    return (
        <Text color="caption" sx={{ width: "100%", textAlign: "center", justifyContent: "center" }}>
            {steps[activeStep]}
        </Text>
    );
}

function ProgressCounter({
    active,
    totalEstimatedTime,
    complete,
    estimateSeconds
}: {
    active: boolean;
    totalEstimatedTime: number;
    estimateSeconds: number;
    complete: boolean;
}) {
    const tick = useTimer({ skip: !active || complete });

    const progress = complete || tick > estimateSeconds ? 1 : tick / estimateSeconds;

    const width = 100 * (estimateSeconds / totalEstimatedTime);
    return (
        <ProgressBar
            sx={{
                width: `${Math.floor(width)}%`,
                // otherwise the bar of a given LinearProgress overlaps with the others
                [`& .${linearProgressClasses.bar}`]: {
                    transform: active || complete ? undefined : "translateX(-101%) !important"
                }
            }}
            value={100 * progress}
        />
    );
}
