import {
    CloseStrategyTxnParams,
    CreateStrategyTxnParams,
    ExternalYieldSource,
    FillStrategyOrderParams,
    SellLoanParams,
    StrategyCollateralInfoParams,
    TransferDebtParams,
    UpdateOrderRefinanceTermsParams,
    getCloseStrategyTransaction,
    getCreateAndFillStrategyOrderTransactions,
    getCreateStrategyTransaction,
    getDebtTransferTransaction,
    getEditEscrowTransaction,
    getSellLoanTransaction,
    getUpdateStrategyOrderRefinanceTerms
} from "@bridgesplit/abf-sdk";
import { OrderedTransactions, combineTransactionPromises } from "@bridgesplit/react";
import { Result, STAKED_SOL_MINT, decimalsToBps, isJito, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, TransactionSenderOptions } from "../types";
import { useAbfGenerateTransaction } from "./common";
import { getTransactionHeadersFromCookies, isStakedSol, useDecimalsByMint } from "../utils";

export function useCreateStrategyTransaction(): AbfTransactionDetails<CreateStrategyTxnParams> {
    const generate = useAbfGenerateTransaction();
    const {
        resetNapoleonApi,
        resetEscrowedApi,
        resetLockboxApi,
        resetLendingStrategyApi,
        resetGroupEscrowsApi,
        resetNapoleonPublicApi
    } = useAbfFetches();

    async function getTransactionsWithParams(params: CreateStrategyTxnParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getCreateStrategyTransaction,
                identifier: "Create position",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetEscrowedApi();
            resetLockboxApi();
            resetLendingStrategyApi();
            resetGroupEscrowsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Creating position" };
}

export type FillStrategyOrderArgs = Omit<FillStrategyOrderParams, "mintToCollateralInfo"> & {
    principalDecimals: number;
    collateral: StrategyCollateralInfoParams[];
    loanTransactionIdentifier?: string; // optionally rename the transaction ID
};
export function useFillStrategyOfferTransaction(): AbfTransactionDetails<FillStrategyOrderArgs> {
    const {
        resetNapoleonApi,
        resetEscrowedApi,
        resetLockboxApi,
        resetOraclePricesApi,
        resetLoanApi,
        resetNapoleonPublicApi
    } = useAbfFetches();
    const { setupFetchMultipleDecimals } = useDecimalsByMint();

    async function getTransactionsWithParams({
        collateral,
        loanTransactionIdentifier,
        ...params
    }: FillStrategyOrderArgs): AbfGeneratorResult {
        try {
            const getDecimals = await setupFetchMultipleDecimals(collateral.map((m) => m.assetKey));

            const headers = getTransactionHeadersFromCookies();
            if (!headers.isOk()) return Result.err(headers);

            const transactionsRes = await getCreateAndFillStrategyOrderTransactions(headers.unwrap(), {
                ...params,
                principalRequested: uiAmountToLamports(params.principalRequested, params.principalDecimals),
                mintToCollateralInfo: Object.fromEntries(
                    collateral.map((c) => {
                        const assetMint = isStakedSol(c.assetTypeDiscriminator) ? STAKED_SOL_MINT : c.assetKey;
                        const decimals = getDecimals(assetMint);
                        return [
                            assetMint,
                            {
                                ...c,
                                amountFromWallet: uiAmountToLamports(c.amountFromWallet, decimals),
                                amountFromEscrow: uiAmountToLamports(c.amountFromEscrow, decimals)
                            }
                        ];
                    })
                )
            });

            if (!transactionsRes.isOk()) return Result.err(transactionsRes);

            const { orderFillTransactions, lockboxTransactions, orderLutTransactions } = transactionsRes.unwrap();

            let orderedTransactions: OrderedTransactions = [];

            const sendBundle = isJito(params.transactionGenerationType);

            // Common transaction mappings
            const lockboxTransactionDetails = lockboxTransactions.map((t) => ({
                transaction: t,
                identifier: "Deposit assets"
            }));

            const orderLutTransactionDetails = orderLutTransactions?.map((t) => ({
                transaction: t,
                identifier: sendBundle ? "Setup loan" : "Set up offer"
            }));

            const orderFillTransactionDetails = orderFillTransactions.map((t) => ({
                transaction: t,
                identifier: loanTransactionIdentifier ?? "Start loan"
            }));

            if (sendBundle) {
                const depositAndFill = orderFillTransactionDetails;
                if (orderLutTransactionDetails) {
                    orderedTransactions = [
                        {
                            commitmentLevel: "confirmed",
                            transactions: orderLutTransactionDetails
                        },
                        {
                            transactions: depositAndFill,
                            commitmentLevel: "confirmed",
                            bundle: true,
                            skipBundleSim: true
                        }
                    ];
                } else {
                    orderedTransactions = [
                        {
                            transactions: depositAndFill,
                            commitmentLevel: "confirmed",
                            bundle: true,
                            skipBundleSim: true
                        }
                    ];
                }
            } else {
                orderedTransactions = [
                    {
                        transactions: [...lockboxTransactionDetails, ...(orderLutTransactionDetails ?? [])],
                        commitmentLevel: "finalized" // always finalized since error case is bad UX
                    },
                    {
                        commitmentLevel: "confirmed",
                        transactions: orderFillTransactionDetails
                    }
                ];
            }

            return Result.ok(orderedTransactions.filter((batch) => !!batch.transactions.length));
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        minGeyserConfirmations: 10,
        refetch: () => {
            resetLoanApi();
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetEscrowedApi();
            resetLockboxApi();
            resetOraclePricesApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Filling borrow order" };
}

export type EditStrategySettingsParams = {
    strategyIdentifier: string;
    originationCap: number;
    externalYieldSource: ExternalYieldSource | undefined;
    principalMint: string;
};

export function useEditStrategySettings(): AbfTransactionDetails<EditStrategySettingsParams> {
    const generate = useAbfGenerateTransaction();
    const {
        resetNapoleonApi,
        resetLoanApi,
        resetLendingStrategyApi,
        resetOrderApi,
        resetGroupEscrowsApi,
        resetNapoleonPublicApi
    } = useAbfFetches();

    async function getTransactionsWithParams({
        strategyIdentifier,
        originationCap,
        externalYieldSource,
        principalMint
    }: EditStrategySettingsParams): AbfGeneratorResult {
        try {
            const escrowEdit = generate({
                generateFunction: getEditEscrowTransaction,
                identifier: "Update settings",
                params: [
                    {
                        nonce: strategyIdentifier,
                        origination_cap: originationCap,
                        external_yield_source: externalYieldSource ?? ExternalYieldSource.None,
                        principal_mint: principalMint
                    }
                ]
            });

            const transactions = await combineTransactionPromises([escrowEdit], { order: "parallel" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetLoanApi();
            resetLendingStrategyApi();
            resetOrderApi();
            resetGroupEscrowsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Updating loan liquidation preferences" };
}

export function useUpdateStrategyOrderRefinanceTerms(): AbfTransactionDetails<UpdateOrderRefinanceTermsParams> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetLoanApi, resetLendingStrategyApi, resetOrderApi, resetNapoleonPublicApi } =
        useAbfFetches();

    async function getTransactionsWithParams(params: UpdateOrderRefinanceTermsParams): AbfGeneratorResult {
        try {
            const edit = generate({
                generateFunction: getUpdateStrategyOrderRefinanceTerms,
                identifier: "Update refinance settings",
                params: { ...params, maxRefinanceApy: decimalsToBps(params.maxRefinanceApy) }
            });

            const transactions = await combineTransactionPromises([edit], { order: "parallel" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetLoanApi();
            resetLendingStrategyApi();
            resetOrderApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Updating refinance settings" };
}

export type SellLoanTransactionParams = SellLoanParams & { decimals: number };
export function useSellLoanTransaction(): AbfTransactionDetails<SellLoanTransactionParams> {
    const generate = useAbfGenerateTransaction();
    const {
        resetNapoleonApi,
        resetEscrowedApi,
        resetLendingStrategyApi,
        resetGroupEscrowsApi,
        resetLoanApi,
        resetNapoleonPublicApi
    } = useAbfFetches();

    async function getTransactionsWithParams({ decimals, ...params }: SellLoanTransactionParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getSellLoanTransaction,
                identifier: "Early withdraw",
                params: { ...params, expectedSalePrice: uiAmountToLamports(params.expectedSalePrice, decimals) }
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetEscrowedApi();
            resetLendingStrategyApi();
            resetGroupEscrowsApi();
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Selling loan" };
}

export function useTransferDebtTransaction(): AbfTransactionDetails<TransferDebtParams> {
    const generate = useAbfGenerateTransaction();
    const {
        resetNapoleonApi,
        resetEscrowedApi,
        resetLendingStrategyApi,
        resetGroupEscrowsApi,
        resetLoanApi,
        resetNapoleonPublicApi
    } = useAbfFetches();

    async function getTransactionsWithParams(params: TransferDebtParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getDebtTransferTransaction,
                identifier: "Transfer debt",
                params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetEscrowedApi();
            resetLendingStrategyApi();
            resetGroupEscrowsApi();
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Selling loan" };
}

export function useDeleteStrategyTransaction(): AbfTransactionDetails<CloseStrategyTxnParams> {
    const generate = useAbfGenerateTransaction();
    const {
        resetNapoleonApi,
        resetEscrowedApi,
        resetLockboxApi,
        resetLendingStrategyApi,
        resetGroupEscrowsApi,
        resetNapoleonPublicApi
    } = useAbfFetches();

    async function getTransactionsWithParams(params: CloseStrategyTxnParams): AbfGeneratorResult {
        try {
            const create = generate({
                generateFunction: getCloseStrategyTransaction,
                identifier: "Close account",
                params: params
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetEscrowedApi();
            resetLockboxApi();
            resetLendingStrategyApi();
            resetGroupEscrowsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing accounts" };
}
