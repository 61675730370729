import { useCallback, useMemo, useState } from "react";

import { AppButton, HealthChange, LtvChange } from "app/components/common";
import {
    AbfLoanExpanded,
    AbfPermissionForCustodian,
    BsMetaUtil,
    calculateApy,
    calculateHealthRatio,
    calculateLoanHealth,
    getContinuousCompoundedInterest,
    getLoanCollateralUsdValue,
    getLoanCustodianIdentifiers,
    getLoanStrategyIdentifier,
    IncreaseCreditTransactionParams,
    summarizeLedgerAccounts,
    TransferCreditQuote,
    useActiveEscrow,
    useActiveGroup,
    useActiveWallet,
    useIncreasePrincipalQuoteQuery,
    useIncreasePrincipalTransaction
} from "@bridgesplit/abf-react";
import { StatColumn, TokenInput, StatProps, TagTextAlert } from "@bridgesplit/ui";
import {
    Result,
    LOADING_ERROR,
    MISSING_PARAM_ERROR,
    lamportsToUiAmount,
    bpsToUiDecimals,
    formatPercent,
    getUnixTs,
    bsMath,
    roundDownToDecimals
} from "@bridgesplit/utils";
import { TrackTransactionEvent } from "app/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTransactionSender } from "app/utils";

import { ActionProps } from "./types";

export default function IncreasePrincipal({ loanExpanded }: ActionProps) {
    const [additionalPrincipal, setAdditionalPrincipal] = useState<number | undefined>(undefined);

    const transferCreditQuote = useIncreasePrincipalQuote(loanExpanded);

    const additionalPrincipalLimits = useMemo(
        () => getMaxAdditionalPrincipal({ loanExpanded, transferCreditQuote, additionalPrincipal }),
        [loanExpanded, transferCreditQuote, additionalPrincipal]
    );

    const send = useTransactionSender();
    const { groupIdentifier } = useActiveGroup();
    const { escrowNonce, activeEscrow } = useActiveEscrow();
    const { activeWallet } = useActiveWallet();

    const increasePrincipal = useIncreasePrincipalTransaction();

    const submit = useCallback(async () => {
        if (!groupIdentifier || !escrowNonce || !loanExpanded || !activeEscrow || !activeWallet)
            return Result.errFromMessage(LOADING_ERROR);

        if (!additionalPrincipal || !additionalPrincipalLimits) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const strategyIdentifier = getLoanStrategyIdentifier(loanExpanded);
        if (!strategyIdentifier) return Result.errFromMessage("Can't increase principal on a Prime loan");

        const params: IncreaseCreditTransactionParams = {
            decimals: loanExpanded.principalMetadata.decimals,
            loan: loanExpanded.loan.address,
            strategyIdentifier,
            increaseAmount: additionalPrincipal
        };

        return await send(increasePrincipal, params, {
            description: "Increasing borrow",
            mixpanelEvent: { key: TrackTransactionEvent.IncreasePrincipal, params }
        });
    }, [
        activeEscrow,
        activeWallet,
        escrowNonce,
        groupIdentifier,
        increasePrincipal,
        loanExpanded,
        additionalPrincipal,
        additionalPrincipalLimits,
        send
    ]);

    const isLtvConstrained =
        additionalPrincipalLimits !== undefined
            ? additionalPrincipalLimits.maxAdditionalPrincipal ===
              additionalPrincipalLimits.maxAdditionalPrincipalBasedOnLtv
            : false;

    const exceedsMaxBorrow =
        !!additionalPrincipalLimits && additionalPrincipal !== undefined
            ? additionalPrincipalLimits.maxAdditionalPrincipal < additionalPrincipal
            : false;

    return (
        <>
            <TokenInput
                maxLoading={!transferCreditQuote}
                label="Amount"
                maxText="Max: "
                symbol={BsMetaUtil.getSymbol(loanExpanded?.principalMetadata)}
                decimals={loanExpanded?.principalMetadata.decimals}
                maxAmount={additionalPrincipalLimits?.maxAdditionalPrincipal}
                value={additionalPrincipal}
                setValue={setAdditionalPrincipal}
            />
            {additionalPrincipalLimits?.maxAdditionalPrincipal === 0 ? (
                <TagTextAlert color="warning" icon="warning">
                    The lender you borrowed from doesn't have enough funds to lend you more
                </TagTextAlert>
            ) : (
                (isLtvConstrained || exceedsMaxBorrow) &&
                additionalPrincipal !== undefined &&
                additionalPrincipal > (additionalPrincipalLimits?.maxAdditionalPrincipal ?? 0) && (
                    <TagTextAlert color="warning" icon="warning">
                        {isLtvConstrained
                            ? "You do not have enough collateral to borrow this much more. Deposit more collateral first."
                            : "The lender you borrowed from doesn't have enough funds to lend this much more."}
                    </TagTextAlert>
                )
            )}
            {!!additionalPrincipal && (
                <Stats
                    loanExpanded={loanExpanded}
                    transferCreditQuote={transferCreditQuote}
                    additionalPrincipal={additionalPrincipal}
                />
            )}
            <AppButton
                isTransaction
                disabled={
                    !transferCreditQuote ||
                    !additionalPrincipal ||
                    !additionalPrincipalLimits?.maxAdditionalPrincipal ||
                    additionalPrincipal > additionalPrincipalLimits.maxAdditionalPrincipal
                }
                asyncCta={{ onClickWithResult: submit }}
                authentication={{
                    permission: AbfPermissionForCustodian.WithdrawDebtNote,
                    custodians: getLoanCustodianIdentifiers(loanExpanded)
                }}
                fullWidth
            >
                Borrow more
            </AppButton>
        </>
    );
}

type TransferStatsProps = {
    loanExpanded: AbfLoanExpanded | undefined;
    transferCreditQuote: TransferCreditQuote | undefined;
    additionalPrincipal: number | undefined;
};

function Stats(props: TransferStatsProps) {
    const { loanExpanded } = props;
    const newInterest = useMemo(() => getNewInterest(props), [props]);
    const { health: originalHealth } = calculateLoanHealth(loanExpanded);
    const { ltv, liquidationThreshold } = calculateLoanHealth(loanExpanded);

    const stats: StatProps[] = [
        {
            caption: "APY",
            value: [formatPercent(loanExpanded?.apy), formatPercent(newInterest?.apy)],
            hide: loanExpanded?.apy === newInterest?.apy
        },
        {
            caption: "Health",
            value: <HealthChange currentHealth={originalHealth} previousHealth={newInterest?.newHealth} />,
            hide: !originalHealth
        },
        {
            caption: "LTV",
            value: (
                <LtvChange
                    currentLtv={ltv}
                    previousLtv={newInterest?.newLtv}
                    liquidationThreshold={liquidationThreshold}
                />
            ),
            hide: !ltv || !newInterest?.newLtv || !liquidationThreshold
        },
        {
            caption: "Borrowed",
            value: [loanExpanded?.principalAmount, newInterest?.totalPrincipal],
            hide: !loanExpanded?.debt.total,
            symbol: BsMetaUtil.getSymbol(loanExpanded?.principalMetadata)
        }
    ];

    return <StatColumn loading={!newInterest} stats={stats} />;
}

function getMaxAdditionalPrincipal({ loanExpanded, transferCreditQuote }: TransferStatsProps) {
    if (!loanExpanded?.principalMetadata || !transferCreditQuote) {
        return undefined;
    }

    const collateralUsd = getLoanCollateralUsdValue(loanExpanded);

    const maxBorrowUsd = collateralUsd * transferCreditQuote.ltv;
    const maxTotalPrincipal = roundDownToDecimals(
        maxBorrowUsd / loanExpanded.principalUsdPrice,
        loanExpanded.principalMetadata.decimals
    );
    const maxAdditionalPrincipalBasedOnLtv = Math.max(maxTotalPrincipal - loanExpanded.debt.total, 0);
    const maxAdditionalPrincipal = Math.min(maxAdditionalPrincipalBasedOnLtv, transferCreditQuote.amountLeft);

    return { maxAdditionalPrincipal, maxAdditionalPrincipalBasedOnLtv };
}

function getNewInterest({ loanExpanded, additionalPrincipal, transferCreditQuote }: TransferStatsProps) {
    if (!loanExpanded || !transferCreditQuote || additionalPrincipal === undefined) return undefined;

    const originalLedgers = summarizeLedgerAccounts(loanExpanded);
    const loanDuration = loanExpanded.order.finalPaymentTimeOffset;
    const previousInterest = originalLedgers.interestDue;

    const now = getUnixTs();

    const accruedInterest = getContinuousCompoundedInterest({
        subtractPrincipal: true,
        principal: loanExpanded.principalAmount,
        apy: loanExpanded.apy,
        loanDuration: now - loanExpanded.loan.loanStartTime
    });

    const timeRemaining = loanExpanded.order.finalPaymentTimeOffset + loanExpanded.loan.loanStartTime - now;
    const remainingInterest = getContinuousCompoundedInterest({
        principal: loanExpanded.principalAmount,
        apy: loanExpanded.apy,
        subtractPrincipal: true,
        loanDuration: timeRemaining
    });

    const loanEndTime = loanExpanded.loan.loanStartTime + loanDuration;
    const newInterest = getContinuousCompoundedInterest({
        principal: additionalPrincipal,
        subtractPrincipal: true,
        apy: transferCreditQuote.apy,
        loanDuration: loanEndTime - now
    });

    const totalInterest = previousInterest + newInterest;
    const totalPrincipal = loanExpanded.principalAmount + additionalPrincipal;
    const totalLoanValue = totalPrincipal + accruedInterest;
    const totalRemainingInterest = newInterest + remainingInterest;
    const totalLedgerOutstanding = totalPrincipal + totalInterest - originalLedgers.totalPaid;
    const apy =
        transferCreditQuote.apy === loanExpanded.apy
            ? loanExpanded.apy
            : calculateApy({
                  principalAmount: totalLoanValue,
                  repaymentAmount: totalRemainingInterest + totalLoanValue,
                  durationInSeconds: timeRemaining
              });

    const principalUsd = bsMath.mul(totalLedgerOutstanding, loanExpanded?.principalUsdPrice) ?? 0;
    const loanCollateralValue = getLoanCollateralUsdValue(loanExpanded);
    const newHealth = calculateHealthRatio(principalUsd, loanCollateralValue, transferCreditQuote.liquidationThreshold);

    const newLtv = principalUsd / loanCollateralValue;

    return { apy, newHealth, totalPrincipal, newLtv };
}

function useIncreasePrincipalQuote(loanExpanded: AbfLoanExpanded | undefined) {
    const { data } = useIncreasePrincipalQuoteQuery(loanExpanded?.loan.address ?? skipToken, {
        skip: !loanExpanded
    });

    return useMemo((): TransferCreditQuote | undefined => {
        if (!data || !loanExpanded) return undefined;
        const decimals = loanExpanded.principalMetadata.decimals;
        return {
            ...data,
            apy: bpsToUiDecimals(data.apy),
            amountLeft: lamportsToUiAmount(data.amountLeft, decimals),
            ltv: bpsToUiDecimals(data.ltv),
            liquidationThreshold: bpsToUiDecimals(data.liquidationThreshold)
        };
    }, [data, loanExpanded]);
}
