import {
    PointMultiplier,
    CollateralInfo,
    LoopscalePointSource,
    PointsInfo,
    ExternalPointSource,
    PointsReward
} from "../types";

export function convertPointsRewardsResponseToPointMap(points?: PointsReward[]): Record<LoopscalePointSource, number> {
    const pointMap: Record<LoopscalePointSource, number> = {
        [LoopscalePointSource.Borrow]: 0,
        [LoopscalePointSource.Lend]: 0,
        [LoopscalePointSource.IdleCap]: 0
    };

    if (!points?.length) {
        return pointMap;
    }

    points.forEach((point) => {
        if (Object.values(LoopscalePointSource).includes(point.reward as LoopscalePointSource)) {
            pointMap[point.reward as LoopscalePointSource] = point.pointsPerSecond || 0;
        }
    });

    return pointMap;
}

export function convertPointsRewardsToBasePointMap(points?: PointsReward[]): Record<LoopscalePointSource, number> {
    const pointMap: Record<LoopscalePointSource, number> = {
        [LoopscalePointSource.Borrow]: 0,
        [LoopscalePointSource.Lend]: 0,
        [LoopscalePointSource.IdleCap]: 0
    };

    if (!points?.length) {
        return pointMap;
    }

    // Find the idle cap point
    const idleCapPoint = points.find((point) => point.reward === LoopscalePointSource.IdleCap);
    const idleCapBase = idleCapPoint?.pointsPerSecond || 0.05; // Default to 0.05 if not found

    points.forEach((point) => {
        if (Object.values(LoopscalePointSource).includes(point.reward as LoopscalePointSource)) {
            const basePointsPerSecond = point.pointsPerSecond || 0;
            const relativeMultiplier = idleCapBase ? basePointsPerSecond / idleCapBase : 0;
            pointMap[point.reward as LoopscalePointSource] = relativeMultiplier;
        }
    });

    return pointMap;
}

export function buildCollateralPointsInfo(
    collateralInfo?: PointMultiplier[],
    pointsRewards?: PointsReward[]
): Record<string, CollateralInfo> {
    const result: Record<string, CollateralInfo> = {};

    if (!collateralInfo?.length || !pointsRewards?.length) {
        return result;
    }

    // Convert pointsReward to LoopscalePointSource -> PointsPerSecond Map
    const loopscalePointsPerSecond = convertPointsRewardsResponseToPointMap(pointsRewards);

    // Find idle cap base rate
    const idleCapPoint = pointsRewards.find((point) => point.reward === LoopscalePointSource.IdleCap);
    const idleCapBase = idleCapPoint?.pointsPerSecond || 0.05; // Default to 0.05 if not found

    collateralInfo.forEach((info) => {
        if (!info?.mint) return;

        if (!result[info.mint]) {
            result[info.mint] = {
                pointSourceToNormalizedPoints: {
                    [LoopscalePointSource.Borrow]: 0,
                    [LoopscalePointSource.Lend]: 0,
                    [LoopscalePointSource.IdleCap]: 0
                },
                name: info.name || ""
            };
        }

        // Only process if the reward is a valid LoopscalePointSource
        if (Object.values(LoopscalePointSource).includes(info.reward as LoopscalePointSource)) {
            const basePointsPerSecond = loopscalePointsPerSecond[info.reward as LoopscalePointSource] || idleCapBase;
            const relativeMultiplier = (basePointsPerSecond / idleCapBase) * (info.multiplier || 0);
            result[info.mint].pointSourceToNormalizedPoints[info.reward as LoopscalePointSource] = relativeMultiplier;
        }
    });

    return result;
}
export function calculateLoopscaleMultiplier(
    reward: PointsInfo,
    loopscaleActions: PointsInfo[] | undefined,
    baseSources: PointsInfo[] | undefined
): number | undefined {
    if (
        reward.externalPointSource !== ExternalPointSource.Loopscale ||
        (!loopscaleActions?.length && !baseSources?.length)
    ) {
        return reward.multiplier;
    }

    const allActions = [...(loopscaleActions || []), ...(baseSources || [])];

    return allActions.reduce((total, action) => total * (action.multiplier ?? 1), 1);
}
