import {
    NAPOLEON_API,
    Result,
    VersionedTransactionResponse,
    deserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransaction,
    fetchAndDeserializeVersionedTransactions
} from "@bridgesplit/utils";
import { VersionedTransaction } from "@solana/web3.js";

import {
    AbfTransactionHeaders,
    CLOSE_STRATEGY_TXN_ROUTE,
    CloseStrategyTxnParams,
    CREATE_STRATEGY_TXN_ROUTE,
    CreateStrategyTxnParams,
    FILL_STRATEGY_ORDER_TXN_ROUTE,
    FillStrategyOrderParams,
    FillStrategyTransactions,
    GET_DEBT_TRANSFER_TRANSACTION_ROUTE,
    GET_LOAN_SALE_TRANSACTION_ROUTE,
    IncreaseCreditTransactionArgs,
    LOAN_INCREASE_PRINCIPAL,
    SellLoanParams,
    TransferDebtParams,
    UPDATE_LIQUIDATION_MANAGER_ROUTE,
    UPDATE_ORDER_REFINANCE_TERMS_TXN_ROUTE,
    UpdateLiquidationManagerParams,
    UpdateOrderRefinanceTermsParams
} from "../types";
import { createAbfInstance } from "./auth";

export async function getCreateStrategyTransaction(
    authentication: AbfTransactionHeaders,
    args: CreateStrategyTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            CREATE_STRATEGY_TXN_ROUTE,
            args
        )
    ];
}

// custom fetch needed due to custom return type
export async function getCreateAndFillStrategyOrderTransactions(
    authentication: AbfTransactionHeaders,
    args: FillStrategyOrderParams
): Promise<Result<FillStrategyTransactions<VersionedTransaction>>> {
    try {
        const api = createAbfInstance(authentication, NAPOLEON_API);

        const res = await api.post<FillStrategyTransactions<VersionedTransactionResponse>>(
            FILL_STRATEGY_ORDER_TXN_ROUTE,
            args
        );

        const { lockboxTransactions, orderFillTransactions, orderLutTransactions } = res.data;

        return Result.ok({
            lockboxTransactions: lockboxTransactions.map((txn) => deserializeVersionedTransaction(txn)),
            orderFillTransactions: orderFillTransactions.map((txn) => deserializeVersionedTransaction(txn)),
            orderLutTransactions: orderLutTransactions
                ? orderLutTransactions.map((txn) => deserializeVersionedTransaction(txn))
                : orderLutTransactions
        });
    } catch (error) {
        return Result.err(error);
    }
}

export async function getUpdateLiquidationManagerTransactions(
    authentication: AbfTransactionHeaders,
    args: UpdateLiquidationManagerParams
) {
    return await fetchAndDeserializeVersionedTransactions(
        createAbfInstance(authentication, NAPOLEON_API),
        UPDATE_LIQUIDATION_MANAGER_ROUTE,
        args
    );
}

export async function getUpdateStrategyOrderRefinanceTerms(
    authentication: AbfTransactionHeaders,
    args: UpdateOrderRefinanceTermsParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            UPDATE_ORDER_REFINANCE_TERMS_TXN_ROUTE,
            args
        )
    ];
}

export async function getSellLoanTransaction(authentication: AbfTransactionHeaders, args: SellLoanParams) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            GET_LOAN_SALE_TRANSACTION_ROUTE,
            args
        )
    ];
}

export async function getDebtTransferTransaction(authentication: AbfTransactionHeaders, args: TransferDebtParams) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            GET_DEBT_TRANSFER_TRANSACTION_ROUTE,
            args
        )
    ];
}

export async function getIncreasePrincipalTransaction(
    authentication: AbfTransactionHeaders,
    args: IncreaseCreditTransactionArgs
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            LOAN_INCREASE_PRINCIPAL,
            args
        )
    ];
}

export async function getCloseStrategyTransaction(
    authentication: AbfTransactionHeaders,
    args: CloseStrategyTxnParams
) {
    return [
        await fetchAndDeserializeVersionedTransaction(
            createAbfInstance(authentication, NAPOLEON_API),
            `${CLOSE_STRATEGY_TXN_ROUTE}/${args.strategyIdentifier}`
        )
    ];
}
