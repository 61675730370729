import { useState } from "react";

import {
    DialogWrapper,
    FormInput,
    LabelWrapper,
    Select,
    TextSkeleton,
    ToggleButtons,
    useOnInitialRender
} from "@bridgesplit/ui";
import { LOADING_ERROR, PERCENT_DECIMALS, Result, percentDecimalsToUi, percentUiToDecimals } from "@bridgesplit/utils";
import {
    getLoanStrategyIdentifier,
    PresetStrategyTerms,
    usePresets,
    useUpdateStrategyOrderRefinanceTerms
} from "@bridgesplit/abf-react";
import { formatDurationWithType } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";

import { AppButton, DialogHeader } from "../../common";
import { useTransactionSender } from "app/utils";

enum Setting {
    Never,
    Allowed
}
type Form = { allowed: Setting; maxApy: number | undefined; preset: PresetStrategyTerms | undefined };
export default function RefinanceSettings() {
    const [form, setForm] = useState<Form>({ maxApy: undefined, allowed: Setting.Never, preset: undefined });
    const { getData } = useAppDialog();
    const data = getData(AppDialog.RefinanceSettings)?.loan;
    const order = data?.order;

    const { presets } = usePresets(data?.collateral.map((c) => c.metadata.assetMint));

    const initialForm: Form = {
        allowed: order?.refinanceEnabled ? Setting.Allowed : Setting.Never,
        maxApy: percentDecimalsToUi(order?.maxRefinanceApy ?? undefined),
        preset:
            presets?.find(
                (p) => p.duration === order?.refinanceDuration && p.durationType === order.refinanceDurationType
            ) ?? presets?.[0]
    };

    useOnInitialRender(() => setForm(initialForm), !order || !presets);
    const updateTerms = useUpdateStrategyOrderRefinanceTerms();
    const send = useTransactionSender();

    async function submit() {
        if (!data || !form.preset) return Result.errFromMessage(LOADING_ERROR);

        const strategyIdentifier = getLoanStrategyIdentifier(data);
        if (!strategyIdentifier) return Result.errFromMessage("Can't refinance a Prime loan");
        return send(updateTerms, {
            strategyIdentifier,
            order: data.order.address,
            refinanceEnabled: !!form.allowed,
            maxRefinanceApy: percentUiToDecimals(form.maxApy ?? 0),
            refinanceDurationType: form.preset.durationType,
            refinanceDuration: form.preset.duration
        });
    }

    const changesMade =
        form.allowed !== initialForm.allowed ||
        form.maxApy !== initialForm.maxApy ||
        form.preset?.duration !== order?.refinanceDuration ||
        form.preset?.durationType !== order?.refinanceDurationType;
    return (
        <DialogWrapper>
            <DialogHeader
                header="Auto-refinance loans"
                description={`When enabled, your loan will auto-refinance daily instead of defaulting. Your loan can still default if there are no available offers`}
            />
            <ToggleButtons
                value={form.allowed}
                setValue={(allowed) => setForm((prev) => ({ ...prev, allowed }))}
                options={[
                    { value: Setting.Allowed, label: "Auto-refinance" },
                    { value: Setting.Never, label: "Never" }
                ]}
            />
            <LabelWrapper label="Refinance duration" tooltip="Preferred duration for the new refinanced loan">
                <Select
                    disabled={!form.allowed}
                    loading={!presets}
                    options={
                        presets?.map(({ duration, durationType, id }) => ({
                            value: id,
                            label: formatDurationWithType(duration, durationType)
                        })) ?? Array(4).fill({ value: "", label: <TextSkeleton variant="body2" width="30px" /> })
                    }
                    value={form.preset?.id}
                    setValue={(id) => {
                        const preset = presets?.find((p) => p.id === id);
                        if (!preset) return;
                        setForm((prev) => ({ ...prev, preset }));
                    }}
                />
            </LabelWrapper>

            <FormInput
                disabled={!form.allowed}
                label="Max APY"
                tooltip="The max APY allowed to auto-refinance"
                variant="number"
                decimals={PERCENT_DECIMALS}
                value={form.maxApy}
                setValue={(maxApy) => setForm((prev) => ({ ...prev, maxApy }))}
            />
            <AppButton
                isTransaction
                asyncCta={{ onClickWithResult: submit }}
                disabled={!changesMade || (!!form.allowed && !form.maxApy)}
            >
                Save changes
            </AppButton>
        </DialogWrapper>
    );
}
