import { ReactNode, createContext, useContext } from "react";

import { LoopscaleWallet, Result, WALLET_ERROR, Web3User } from "@bridgesplit/utils";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey, Transaction, VersionedTransaction } from "@solana/web3.js";

import { DEBUG_WALLET } from "./user";

export const DEFAULT_CONNECTION = "https://delicious-melita-fast-mainnet.helius-rpc.com/";
export const DEFAULT_WALLET: LoopscaleWallet = {
    publicKey: DEBUG_WALLET ? new PublicKey(DEBUG_WALLET) : PublicKey.default,
    signTransaction: function <T extends Transaction | VersionedTransaction>(transaction: T): Promise<T> {
        throw new Error("Function not implemented.");
    },
    signAllTransactions: function <T extends Transaction | VersionedTransaction>(transactions: T[]): Promise<T[]> {
        throw new Error("Function not implemented.");
    },
    signMessage: undefined
};

export const LoopscaleWeb3Context = createContext<Partial<Web3User>>({
    connection: new Connection(DEFAULT_CONNECTION),
    wallet: undefined
});

export const LoopscaleWeb3Provider = ({ children, connectionUrl }: { children: ReactNode; connectionUrl: string }) => {
    const { signMessage } = useWallet();
    const wallet = useAnchorWallet();
    if (DEBUG_WALLET) {
        return (
            <LoopscaleWeb3Context.Provider
                value={{ connection: new Connection(connectionUrl), wallet: DEFAULT_WALLET }}
            >
                {children}
            </LoopscaleWeb3Context.Provider>
        );
    }
    return (
        <LoopscaleWeb3Context.Provider
            value={{ connection: new Connection(connectionUrl), wallet: wallet ? { ...wallet, signMessage } : wallet }}
        >
            {children}
        </LoopscaleWeb3Context.Provider>
    );
};

export const useWeb3User = () => {
    const { connection, wallet } = useContext(LoopscaleWeb3Context);

    function getWeb3UserResult(): Result<Web3User> {
        if (!connection || !wallet) {
            return Result.err(WALLET_ERROR);
        }
        return Result.ok({ connection, wallet });
    }

    return { getWeb3UserResult, wallet, connection: connection || new Connection(DEFAULT_CONNECTION) };
};
